import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";


import InputLabels from "./InputLabels";
import {SButton} from "./Buttons";

const useStyles = makeStyles((theme) => ({
    fileContainer:{
        display: 'flex',
        flexDirection:'column',
        flex: 1,
        marginBottom:23,
    },
    fileBox:{
        display: 'flex',
        '& > div':{
            flex:1
        },
        '& > *':{
            marginLeft:5,
            marginRight:5,
        },
        '& > :first-child':{
            marginLeft:0,
        },
        '& > :last-child':{
            marginRight:0,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > *':{
                marginLeft:0,
                marginRight:0,
            },
            '& > span, & > button': {
                marginTop:10,
            }
        }
    },
    label:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    },
}));

export default function FileFields(props) {
    const classes = useStyles();
    const {field, orgFileName, onDeleteFile, onDownLoad, uploadEnable} = props;
    const [fileName, setFileName] = useState( '' );

    console.log("orgFileName ",orgFileName);

    const onChangeFile = ({target}) => {
        if( field ) {
            field.value = target.files[0];
        }
        setFileName(target.files[0].name);
    }



    return useObserver( () => (
        <div className={classes.fileContainer}>
            <InputLabels id={field?.name} className={classes.label} label={field?.label} />
            <InputLabel htmlFor={field?.id} className={classes.fileBox} encType="multipart/form-data">
                <TextField label={''}
                           value={ (fileName ? fileName : orgFileName)}
                           variant={'outlined'}
                           InputProps={{readOnly: true}}
                />
                <input style={{ display: 'none' }} id={field?.id} type="file" onChange={onChangeFile}/>
                {
                    uploadEnable !== false &&
                    <SButton color={'primary'} component="span" text={'파일 업로드'} />
                }

                {
                    orgFileName !== '-' && onDownLoad &&
                        <SButton text={'다운로드'} color={'primary'} onClick={onDownLoad}/>
                }

                {
                    orgFileName !== '-' && onDeleteFile &&
                    <SButton text={'삭제'} color={'secondary'} onClick={onDeleteFile}/>
                }
            </InputLabel>
        </div>
    ));

}
