import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import Fade from "@material-ui/core/Fade";
import Spinner from "react-spinkit";
import {Box, Button} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const buttonStyles = makeStyles((theme) => ({
    common:{
        margin:0,
        '& > span': {
            ...theme.typography.button,
        },
        [theme.breakpoints.down('xs')]: {
            '& > span':{
                fontSize:'0.786rem'
            }
        }
    },
    radiusButtons:{
        borderRadius:60,
    },
    rectangleButtons:{
        borderRadius:0,
    }
}));

const buttonColorStyles = makeStyles((theme) => ({
    primaryButton:{
        backgroundColor:'#D3275F',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#b52554',
        }
    },
    secondaryButton:{
        backgroundColor:'#555',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#3e3e3e',
        }
    },
    tertiaryButton:{
        backgroundColor:'#CACACA',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#b3b3b3',
        }
    },
    outlinedPrimaryButton:{
        backgroundColor:'#fff',
        color:'#D3275F',
        border:'1px solid #D3275F',
        boxSizing:'border-box',
        boxShadow:'none',
        '&:hover':{
            backgroundColor:'#f3f3f3',
        }
    },
    outlinedSecondaryButton:{
        backgroundColor:'#fff',
        color:'#555',
        border:'1px solid #555',
        boxSizing:'border-box',
        boxShadow:'none',
        '&:hover':{
            backgroundColor:'#f3f3f3',
        }
    },
    outlinedTertiaryButton:{
        backgroundColor:'#fff',
        color:'#CACACA',
        border:'1px solid #CACACA',
        boxSizing:'border-box',
        boxShadow:'none',
        '&:hover':{
            backgroundColor:'#f3f3f3',
        }
    }
}));

const _buttonColor = (color, type) => {
    const classes = buttonColorStyles();
    let colorClass = null;
    if( type === 'outlined') {
        if (color === 'tertiary') {
            colorClass = classes.outlinedTertiaryButton;
        } else if (color === 'secondary') {
            colorClass = classes.outlinedSecondaryButton;
        } else {
            colorClass = classes.outlinedPrimaryButton;
        }
    } else {
        if (color === 'tertiary') {
            colorClass = classes.tertiaryButton;
        } else if (color === 'secondary') {
            colorClass = classes.secondaryButton;
        } else {
            colorClass = classes.primaryButton;
        }
    }

    return colorClass;
}

const buttonSizeStyles = makeStyles((theme) => ({
    large: {
        padding:'20px 80px',
        '& > span': {
            ...theme.typography.largeButton
        },
    },
    middle:{
        padding:'15px 50px',
    },
    small:{
        padding:'8px 30px',
        [theme.breakpoints.down('md')]: {
            padding:'13px 28px',
        }
    },
    outlinedLarge: {
        padding:'19px 78px',
        '& > span': {
            ...theme.typography.largeButton
        },
    },
    outlinedMiddle:{
        padding:'14px 48px',
    },
    outlinedSmall:{
        padding:'7px 28px',
        [theme.breakpoints.down('md')]: {
            padding:'13px 28px',
        }
    },
}));

const _buttonSize = (size, type) => {
    const classes = buttonSizeStyles();
    let sizeClass = null;
    if( type === 'outlined'){
        if( size === 'large') {
            sizeClass = classes.outlinedLarge;
        } else if( size === 'middle') {
            sizeClass = classes.outlinedMiddle;
        } else {
            sizeClass = classes.outlinedSmall;
        }
    } else {
        if( size === 'large') {
            sizeClass = classes.large;
        } else if( size === 'middle') {
            sizeClass = classes.middle;
        } else {
            sizeClass = classes.small;
        }
    }
    return sizeClass;
}

const RadiusButton = (props) => {
    const classes = buttonStyles();
    const {onClick, text, color, type, size, clazz} = props;

    const colorClass = _buttonColor(color, type);

    const sizeClass = _buttonSize(size, type);

    return (
        <Button {...props}
                className={`${colorClass} ${sizeClass} ${clazz} ${classes.radiusButtons} ${classes.common}`}
        >
            {text}
        </Button>
    )
}

const RectangleButton = (props) => {
    const classes = buttonStyles();
    const {onClick, text, color, type, size, clazz} = props;

    const colorClass = _buttonColor(color, type);

    const sizeClass = _buttonSize(size, type);

    return (
        <Button {...props}
                className={`${colorClass} ${sizeClass} ${clazz} ${classes.rectangleButtons} ${classes.common}`}
                variant={'contained'}
        >
            {text}
        </Button>
    )
}

const SButton = (props) => {
    const classes = buttonStyles();
    const {onClick, text, color, type, size, clazz} = props;

    const colorClass = _buttonColor(color, type);

    const sizeClass = _buttonSize(size, type);

    return (
        <Button {...props}
                className={`${colorClass} ${sizeClass} ${clazz} ${classes.common}`}
                variant={'contained'}
        >
            {text}
        </Button>
    )
}

export {RadiusButton, RectangleButton, SButton}

