import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import InputLabels from "./InputLabels";

const useStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,

        }
    },
    label:{
        fontSize:'1.143rem',
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    }
}));

export default function TextFields(props) {
    const classes = useStyles();
    const {field, disableErrors} = props;
    return useObserver( () => (
        <React.Fragment>

            {field?.label && <InputLabels id={field?.name} className={classes.label} label={field?.label}/> }

            <TextField {...field?.bind()}
                       {...props}
                       label={''}
                       {...(disableErrors?'':field?.error ? {error: true, helperText: field.error} : {helperText: " "})}
            />
        </React.Fragment>
    ));

}
