import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    searchBox: {
        display:'flex',
        minWidth: 240,
        borderColor: theme.palette.type === 'dark' ? '#545a61' : '#c4c4c4',
        "&:hover": {
            borderColor: theme.palette.type === 'dark' ? '#ffffff' : '#000000',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flex:1,
            minWidth: 'initial'
        }
    },
    inputStyle:{
        flex:1,
        padding:'0 12px'
    }
}));

export default function SearchBox({value, onChange, doQuery, defaultValue, placeholder, iconAlign}) {
    if(value === null){
        value = '' ;
    }

    const classes = useStyles();
    const {t} = useTranslation();
    const [text, setText] = useState(value);

    const onKeyPress = (evt) => {
        if (evt.key === 'Enter') {
            doQuery();
            evt.preventDefault();
        }
    }
    const onChangeEvent = (event) => {
        onChange(event);
        setText(event.target.value);
    }

    useEffect(() => {
            setText(value);
    }, [value])

    return (
        <Paper component="form" variant="outlined" className={classes.searchBox}>
            { iconAlign !=='right' &&
                <IconButton aria-label="search button" onClick={doQuery}>
                    <SearchIcon color={'primary'}/>
                </IconButton>
            }
            <InputBase
                className={classes.inputStyle}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={text}
                onChange={onChangeEvent}
                onKeyPress={onKeyPress}
                type='search'
            />
            { iconAlign ==='right' &&
            <IconButton aria-label="search button" onClick={doQuery}>
                <SearchIcon color={'primary'}/>
            </IconButton>
            }
        </Paper>
    )
}
