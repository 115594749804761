import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../common/store";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container:{
        marginBottom:'28px',
    },
    headContainer: {
        padding:50,
        textAlign:'center',
        marginBottom:10,
        '& > *':{
            fontFamily:'Nanum Myeongjo',
            paddingBottom:18,
        },
        '& > h3':{ color:'#555', fontSize:'2.3rem', letterSpacing: '-0.05em', },
        '& > h4':{ color:'#d3275f', fontWeight: '600', }
    },
    imageContainer:{
        paddingLeft:60,
        paddingRight:60,
    },
    contentContainer:{
        padding:60,

        textAlign:'center',
        '& *' :{
            fontSize:'1.786rem',
            lineHeight:'50px',
            fontFamily:'Nanum Myeongjo',
            letterSpacing: '-0.06em',
        }
    }
}));

export default function IntroPage(props) {
    const classes = useStyles();
    const {ds} = useStores();


    return (
        <Grid container>
            <Grid item xl={12} xs={12} className={classes.container}>
                <Box className={classes.headContainer}>
                    <Typography variant={'h4'}> 미래편지 </Typography>
                    <Typography variant={'h3'}>그날의... 미래로</Typography>
                    <Typography variant={'h3'}>소중한 마음을 편지에 미리 적어 보내세요</Typography>
                </Box>

                <Box className={classes.imageContainer}>
                    <img src={require('../../images/letter/intro_bg.png').default} width={'100%'} />
                </Box>

                <Box className={classes.contentContainer}>
                    <Typography variant={'body1'}>최근에 누군가에게 편지를 받아본 적은 언제였나요?</Typography>
                    <Typography variant={'body1'}>바쁜 일상에 편지를 적어 보낼 일도 받을 일도 점점 줄어들고 있습니다.</Typography>
                    <Typography variant={'body1'}>주변을 항상 챙기고 마음을 표현하고 싶지만 마음처럼 쉽지 않습니다.</Typography>
                    <Typography variant={'body1'}>사랑하는 가족에게 못 전한 이야기와 고마움, 사랑하는 연인에게 기념일 마다 보내는 사랑 표현,</Typography>
                    <Typography variant={'body1'}>다음 해를 시작하는 나에게 응원과 다짐까지 편지에 적어 보내세요</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}
