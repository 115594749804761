import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useObserver} from "mobx-react";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    iconBox: {
        display:'flex',
        minWidth: 240,
        padding: '5px 0',
        borderColor: theme.palette.type === 'dark' ? '#545a61' : '#c4c4c4',
        "&:hover": {
            borderColor: theme.palette.type === 'dark' ? '#ffffff' : '#000000',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flex:1
        }
    },
    icon:{
            flex: '0 0 auto',
            color: 'rgba(0, 0, 0, 0.54)',
            padding: '12px',
            overflow: 'visible',
            fontSize: '1.5rem',
            textAlign: 'center',
            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '50%',
        '& > span':{
                width:'100%',
            display:'flex',
            alignItems:'inherit',
            justifyContent:'inherit'
        }
    },
    iconInput:{
        flex:1,
        padding:'0 12px'
    }
}));

export default function IconTextFields({icon, iconAlign, type, field, onKeyPress}) {

    const classes = useStyles();
    const {t} = useTranslation();
    const [text, setText] = useState(field?.value);


    const onChangeEvent = (event) => {
        setText(event.target.value);
    }

    return useObserver( () => (
        <FormControl variant="outlined" {...(field?.error ? {error: true} : null)}>
            <Paper component="form" variant="outlined" className={classes.iconBox}>
                {
                    iconAlign !== 'right' &&
                    <Box className={classes.icon}>
                        <Icon aria-label="icon button">
                            {icon}
                        </Icon>
                    </Box>
                }
                    <InputBase {...field?.bind()}
                               className={classes.iconInput}
                               type={type?type:'text'}
                               onKeyPress={onKeyPress}
                    />
                    {
                        iconAlign === 'right' &&
                        <Box className={classes.icon}>
                            <Icon aria-label="icon button">
                                {icon}
                            </Icon>
                        </Box>
                    }
            </Paper>
            {field ? <FormHelperText>{field.error ? field.error : null}</FormHelperText> : null}
        </FormControl>
    ));
}
