import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../common/store";
import api from "../../common/api";
import {useTranslation} from "react-i18next";
import {AuthResult, BoardCategory, BoardType, CommonCodeValue, LetterType} from "../../common/types";
import _ from "lodash";
import queryString from 'query-string';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../../components/SearchBox";
import DataTable from "../../components/DataTable";
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Redirect} from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import TextFields from "../../components/TextFields";
import TextEditor from "../../components/TextEditor";
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import {useHistory} from 'react-router';
import {SButton} from "../../components/Buttons"

const useStyles = makeStyles((theme) => ({
    container: {
        display:'flex',
        justifyContent:'center'
    },
    itemContainer:{

    },
    regContainer:{
        display:'flex',
        flexDirection:'row',
        //justifyContent:'flex-end',
        alignItems:'center',
        marginTop:theme.spacing(2),
        '& > *':{
            marginRight:theme.spacing(2),
        },
        '& > h6':{
            fontSize:'1.143rem',
            lineHeight:'1.714rem',
            fontWeight:350,
            color:'#666',
        },
    },
    boardTypeClass:{
        fontSize:'1.643rem',
        lineHeight:'1.714rem',
        fontWeight:350,
        color:'#D82B64',
    },
    titleContainer:{
        display:'flex',
        flexDirection:'column',
        paddingBottom:20,
        marginBottom:40,
        borderBottom:'1px solid #dedede',
        '& > div':{
            marginBottom:10,
        }
    },
    rowContainer:{
        display:'flex',
        flexDirection:'row',
        '& > :nth-child(1)':{
            margin:0,
        },
        '& > *':{
            marginLeft:theme.spacing(1),
            marginRight:theme.spacing(1),
        }
    },
    title:{
        fontSize:'1.857rem',
    },
    answerTitle:{
        fontSize:'1.5rem',
    },
    contentContainer:{
    },
    buttonContainer:{
        textAlign:'right',
        flex:1,
        '& > button:nth-child(even)':{
            marginLeft:theme.spacing(1),
        },
        '& > button:nth-child(odd)':{
            marginRight:theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            display:'flex',
            flex:1,
            marginBottom:theme.spacing(1),
            '& > button':{
                flex:1,
            },
        }
    },
    buttonGroupContainer:{
        display:'flex',
        flex:1,
        marginTop:theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            justifyContent:'space-between',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection:'column-reverse',
            '& > button:nth-child(1)':{
                marginTop:theme.spacing(2),
                padding:'16px 28px'
            }
        },
        '& > div':{
            textAlign:'right',
            flex:1,
            '& > button:nth-child(even)':{
                marginLeft:theme.spacing(1),
            },
            '& > button:nth-child(odd)':{
                marginRight:theme.spacing(1),
            },
            [theme.breakpoints.between('xs', 'md')]: {
                display:'flex',
                flex:1,
                marginBottom:theme.spacing(1),
                '& > button':{
                    flex:1,
                },
            }
        }
    },
    answerTitleContainer:{
        display:'flex',
        flexDirection:'row',
        '& > div':{
            marginLeft:theme.spacing(1),
            marginRight:theme.spacing(1),
        },
        '& > h5':{
            paddingLeft:theme.spacing(1),
            flexGrow:1
        }
    },
    answerContainer:{
        marginTop:40,
        paddingTop:theme.spacing(4),
        borderTop:'1px solid #dedede'
    },
    answerContentContainer:{
        height:'auto',
        '& > div':{
            display:'flex',
            flex:1,
            flexDirection:'column',
        },
        '& label':{
            paddingBottom:theme.spacing(1)
        }
    },

}));

const answerStyles = makeStyles((theme) => ({
    answerTitleContainer:{
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
        '& > h5':{
            flexGrow:1
        }
    },
    answerContainer:{
        marginTop:theme.spacing(4),
        paddingTop:theme.spacing(2),
        borderTop:'1px solid #dedede'
    },
    answerContentContainer:{
        '& > div':{
            display:'flex',
            flex:1,
            flexDirection:'column',
        },
        '& label':{
            paddingBottom:theme.spacing(1)
        }
    },
    answerButtonContainer:{
        textAlign:'right',
        flex:1,
        '& > button:nth-child(even)':{
            marginLeft:theme.spacing(1),
        },
        '& > button:nth-child(odd)':{
            marginRight:theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            display:'flex',
            flex:1,
            marginBottom:theme.spacing(1),
            '& > button':{
                flex:1,
            },
        }
    },
}));


const answerIconStyles = makeStyles((theme) => ({

    box: {
        display:'flex',
        width:40,
        height:40,
        alignItems:'center',
        justifyContent:'center'
    },
    on : {
        padding:'11px 15px',
        borderRadius:60,
        background:'#D3275F',
        fontWeight:'bold',
        color:'#fff',
    },
    off: {
        padding:'10px 14px',
        borderRadius:60,
        background:'#ffffff',
        border:'1px solid #777777',
        fontWeight:'bold',
        color:'#777777',
    },
    answer: {
        padding:'11px 15px',
        borderRadius:60,
        background:'#D3275F',
        fontWeight:'bold',
        color:'#fff',
    }
}));
function _answerIcon({value, category}) {
    const classes = answerIconStyles();

    let text = '';
    let clazz = null;
    if( value === 'Q') {
        text = 'Q';
        clazz = classes.on;
    } else if( value === 'A') {
        text = 'A';
        clazz = classes.answer;
    } else {
        text = 'Q';
        clazz = classes.off;
    }

    return (
        <React.Fragment>
            { category === BoardCategory.Qna &&
                <span className={`${classes.box} ${clazz}`}>{text}</span>
            }
        </React.Fragment>
    )
}

export default function BoardDetailPage(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();
    const {location} = props;
    const history = useHistory();
    const querySearch = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const boardSeq = querySearch.boardSeq;
    const boardCategory = queryState.boardCategory;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAnswer, setOpenAnswer] = useState(false);

    const [response, setResponse] = useState(null);
    const [answer, setAnswer] = useState({event:null,visible:false});
    const boardType = useRef(null);
    const answerValues = useRef({
        boardSeq: null,
        answerSeq: null,
        title: null,
        content: null
    });


    useEffect( () => {
        api.getBoardDetail(boardSeq)
            .then(res => {
                console.log(res);
                boardType.current = res.data.type;
                answerValues.current.boardSeq = res.data.boardSeq;

                Object.keys(BoardType).map( (key,index) => {
                    if( boardType.current === BoardType[key] ){
                        boardType.current = t(CommonCodeValue.translateKey + '.' + BoardType[key]);
                    }
                });

                console.log("boardType.current => ",boardType.current);

                setResponse(res.data);
                if( res.data.answerYn === 'Y') {
                    answerValues.current.answerSeq = res.data.answer.answerSeq;
                    answerValues.current.title = res.data.answer.title;
                    answerValues.current.content = res.data.answer.content;
                    setAnswer( {event:'put',visible:false});
                } else {
                    setAnswer( {event:'post',visible:true});
                }
            })
            .catch(err => {
                return err;
            });

    }, []);




    const answerForm = new MobxReactForm({
        fields: [
            {name: 'boardSeq',      value: answerValues.current.boardSeq, rules: 'required', label: '게시글 SEQ'},
            {name: 'answerSeq',     value: answerValues.current.answerSeq,label: '답변 SEQ'},
            {name: 'title',         value: answerValues.current.title,    rules: 'required', label: '제목'},
            {name: 'content',       value: answerValues.current.content,  rules: 'required', label: '내용'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                answerValues.current = form.values();

                if (!form.isDirty) {
                    ds.showWarningAlert('수정된 항목이 없습니다.');
                    return;
                }

                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });


    const onConfirmCloseBtn = () =>{
        setOpenConfirm(false);
    }

    const onConfirmOkBtn = () => {
        onConfirmCloseBtn();

        if( answer.event === 'post'){
            answerValues.current.answerSeq = null;
            api.postBoardAnswer(answerValues.current)
                .then(res => {
                    console.log(res);
                    if (res.rtCode === 0) {
                        ds.showSuccessAlert('저장되었습니다.');
                        window.location.reload();

                    } else {
                        ds.showErrorAlert("저장에 실패하였습니다.");
                    }
                })
                .catch(err => {
                    ds.showErrorAlert("저장에 실패하였습니다.");
                });
        } else {
            api.putBoardAnswer(answerValues.current)
                .then(res => {
                    console.log(res);
                    if (res.rtCode === 0) {
                        ds.showSuccessAlert('수정되었습니다.');
                        window.location.reload();

                    } else {
                        ds.showErrorAlert("수정에 실패하였습니다.");
                    }
                })
                .catch(err => {
                    ds.showErrorAlert("수정에 실패하였습니다.");
                });

        }

    }

    const onModifyBoard = () => {
        console.log("onModifyBoard+ > onModifyBoard");
        let url = '';
        if( boardCategory === BoardCategory.Notice ){
            url = '/board/notices/write';
        } else if( boardCategory === BoardCategory.Faq ){
            url = '/board/faqs/write';
        } else if( boardCategory === BoardCategory.Qna ){
            url = '/board/qnas/write';
        } else if( boardCategory === BoardCategory.News ){
            url = '/board/news/write';
        }

        history.push(url, api.encodeParams({boardCategory: boardCategory, boardSeq: boardSeq}));
    }

    const onDeleteBoard = () => {
        api.deleteBoard(boardSeq)
            .then(res => {
                console.log(res);
                if (res.rtCode === 0) {
                    ds.showSuccessAlert('삭제되었습니다.');
                    let url = '';
                    if( boardCategory === BoardCategory.Notice ){
                        url = '/board/notices';
                    } else if( boardCategory === BoardCategory.Faq ){
                        url = '/board/faqs';
                    } else if( boardCategory === BoardCategory.Qna ){
                        url = '/board/qnas';
                    } else if( boardCategory === BoardCategory.News ){
                        url = '/board/news';
                    }
                    history.push(url);
                } else {
                    ds.showErrorAlert("삭제에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert("삭제에 실패하였습니다.");
            });
    }

    const onModifyAnswer = () => {
        setAnswer( {event:'put',visible:true});
    }

    const onCancelAnswer = () => {
        setAnswer( {event:'put',visible:false});
    }

    const onDeleteAnswer = () => {
        setOpenAnswer(true);
    }

    const onDeleteAnswerOk = () => {
        api.deleteBoardAnswer(answerValues.current)
            .then(res => {
                console.log(res);
                if (res.rtCode === 0) {
                    ds.showSuccessAlert('삭제되었습니다.');
                    window.location.reload();
                } else {
                    ds.showErrorAlert("삭제에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert("삭제에 실패하였습니다.");
            });
    }
    const onDeleteAnswerClose = () => {
        setOpenAnswer(false);
    }

    return (

        <Grid container className={classes.container}>
            <Grid item xl={12} xs={12} className={classes.itemContainer}>
                <Box className={classes.titleContainer}>

                    <Box className={classes.rowContainer}>
                        <_answerIcon value={response?.answerYn} category={boardCategory}/>
                        <Box>
                            { boardCategory === BoardCategory.Qna &&
                            <Box>
                                <Typography variant="subtitle1" className={classes.boardTypeClass}>{boardType.current}</Typography>
                            </Box>
                            }
                            <Typography variant="h3" className={classes.title}>{response?.title}</Typography>

                            <Box className={classes.regContainer}>
                                <Typography variant="subtitle2">{_dataFormat(response?.regDt, 'date', 'YYYY-MM-DD HH:mm:ss')}</Typography>
                                <Typography variant="subtitle2">{response?.regUserName}</Typography>
                            </Box>
                        </Box>
                    </Box>


                </Box>

                <Box className={`${classes.contentContainer} ${"ql-editor"}`}
                     dangerouslySetInnerHTML={{__html: response?.content}}
                />

            </Grid>


            {boardCategory === BoardCategory.Qna &&
                <Grid item xl={12} xs={12} className={classes.answerContainer}>
                    {
                        (response?.answerYn === 'Y' && !answer.visible) &&
                        <React.Fragment>
                            <Box className={classes.answerTitleContainer}>
                                <_answerIcon value={'A'} category={boardCategory}/>
                                <Box>
                                    { boardCategory === BoardCategory.Qna &&
                                    <Box>
                                        <Typography variant="subtitle1" className={classes.boardTypeClass}>{boardType.current}</Typography>
                                    </Box>
                                    }
                                    <Typography variant="h3" className={classes.title}>{response?.answer?.title}</Typography>

                                    <Box className={classes.regContainer}>
                                        <Typography
                                            variant="subtitle2">{_dataFormat(response?.answer?.regDt, 'date', 'YYYY-MM-DD HH:mm:ss')}</Typography>
                                        <Typography variant="subtitle2">{response?.answer?.regUserName}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={`${classes.answerContentContainer} ${"ql-editor"}`}
                                 dangerouslySetInnerHTML={{__html: response?.answer?.content}}
                            />
                            { ds.user?.isManager() &&
                            <Box className={classes.buttonContainer}>
                                <SButton text={'수정'} color={'primary'} onClick={onModifyAnswer}/>
                                <SButton text={'삭제'} color={'secondary'} onClick={onDeleteAnswer}/>
                            </Box>
                            }

                        </React.Fragment>
                    }

                    { ds.user?.isManager() && answer.visible &&
                        <AnswerForm form={answerForm}
                                    open={openConfirm}
                                    onOk={onConfirmOkBtn}
                                    onClose={onConfirmCloseBtn}
                                    event={answer.event}
                                    onCancelAnswer={onCancelAnswer}
                        />
                    }
                </Grid>
            }

            <Grid item xl={12} xs={12} className={classes.itemContainer}>
                <Box className={classes.buttonGroupContainer}>
                    <SButton text={'목록'} color={'secondary'} type={'outlined'} onClick={() => history.goBack()}/>

                    { ds.user?.userKey === response?.regUserKey &&
                    <Box>
                        <SButton text={'수정'} color={'primary'} onClick={onModifyBoard}/>
                        <SButton text={'삭제'} color={'secondary'} onClick={onDeleteBoard}/>
                    </Box>
                    }
                </Box>
            </Grid>

            <ConfirmDialog
                open={openAnswer}
                onOk={onDeleteAnswerOk}
                onClose={onDeleteAnswerClose}
                title={'Confirm'}
                content={'삭제 하시겠습니까?'}/>

        </Grid>
    );
}
function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}

function AnswerForm({form, open, onOk, onClose, event, onCancelAnswer}) {

    const answerStyle = answerStyles();
    let confirmContent = "";
    let submitText = "";

    if(event === "post"){
        confirmContent = "저장하시겠습니까?";
        submitText = '저장';
    } else if(event === "put"){
        confirmContent = "수정하시겠습니까?";
        submitText = '수정';
    }

    return (
        <React.Fragment>
            <Box className={answerStyle.answerContentContainer}>
                <div>
                    <TextFields field={form?.$('title')} variant={'outlined'}/>
                </div>
            </Box>
            <Box className={answerStyle.answerContentContainer}>
                <div>
                    <InputLabel id={form?.$('content').name}>{form?.$('content').label}</InputLabel>
                    <TextEditor placeholder={'내용'}
                                field={form?.$('content')}
                                height={200}
                    />
                </div>
            </Box>
            <Box className={answerStyle.answerButtonContainer}>
                <SButton text={submitText} color={'primary'} onClick={form?.onSubmit}/>

                { event === 'put' &&
                <SButton text={'취소'} color={'secondary'} onClick={onCancelAnswer}/>
                }
            </Box>

            <ConfirmDialog
                open={open}
                onOk={onOk}
                onClose={onClose}
                title={'Confirm'}
                content={confirmContent}/>
        </React.Fragment>
    );
}

