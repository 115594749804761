import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useStores} from '../common/store';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footerContainer : {
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.palette.background.footer,
        [theme.breakpoints.between('xs', 'md')]: {
            padding:'0 30px',
        }
    },
    linkContainer:{
        height:80,
        display:'flex',
        flex:1,
        justifyContent:'space-between',
        alignItems:'center',
    },
    linkBox:{
        display:'flex',
        alignItems:'center',
        flexWrap:'wrap',
        '& > h6':{
            color:'#888',
            fontFamily: 'Noto Sans CJK KR',
            fontWeight:'normal',
            fontSize:'1.286rem',
            lineHeight:'1.929rem',
            letterSpacing:'-0.05rem',
            cursor:'pointer',
            marginRight:theme.spacing(2),
        },
        '& > h6:hover':{ color: '#333'},
        [theme.breakpoints.between('xs', 'md')]: {
            '& > h6': {
                fontSize: '1rem'
            }
        },

    },
    familySiteBox:{
    },
    familySiteSelect:{
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0,
        },
        '& .MuiOutlinedInput-input':{
            padding:'11px 20px',
        },
        '& .MuiInputLabel-outlined':{
            transform:'translate(14px, 13px) scale(1)',
        },'& .MuiInputLabel-shrink.MuiInputLabel-outlined':{
            transform:'translate(14px, -6px) scale(1)',
        },
        '& input':{
            fontSize:12
        }
    },
    companyContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid #dedede',
        padding: `28px 0`,
    },
    companyBox:{
        '& > h5':{
            marginBottom:3,
            color:'#333',
            fontFamily: 'Noto Sans CJK KR',
            fontSize:'1.357rem',
            lineHeight:'2.000rem',
        },
        '& h6':{
            margin:0,
            color:'#8d8d8d',
            fontFamily: 'Noto Sans CJK KR',
            fontSize:'1.071rem',
            lineHeight:'1.571rem',
        },


    },
    logoBox:{},
    downLoad: {
        color:'#888',
        textDecoration:'none',
    },
}));


export default function Footer() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const classes = useStyles();
    const {ds} = useStores();
    const history = useHistory();

    const onRouteUrl = (url) => {
        if( url === undefined ){
            ds.showWarningAlert('준비중 입니다.');
            return;
        }
        history.push(url);
    }

    return (

        <Grid container className={classes.footerContainer}>
            <Grid aitem xl={9} xs={12}>
                <div className={classes.linkContainer}>
                    <div className={classes.linkBox}>
                        <Typography variant='subtitle1' onClick={() => onRouteUrl('/intro')}>회사 소개</Typography>
                        <Typography variant='subtitle1' onClick={() => onRouteUrl()}>개인정보처리방침</Typography>
                        <Typography variant='subtitle1' onClick={() => onRouteUrl()}>이메일무단수집</Typography>
                        <Typography variant='subtitle1' onClick={() => onRouteUrl()}>이용안내</Typography>
                        <Typography variant='subtitle1' onClick={() => onRouteUrl()}>찾아오시는길</Typography>
                        <a href={'/app/futuremail.apk'} target={'_blank'} className={classes.downLoad} download>
                            <Typography variant='subtitle1'>미래편지 앱 다운로드</Typography>
                        </a>
                    </div>

                    {!isMobile &&
                    <div className={classes.familySiteBox}>
                        <FormControl variant="outlined" className={classes.familySiteSelect}>
                            <InputLabel id='familySite'>Family Site</InputLabel>
                            <Select
                                labelId={'familySite'}
                                label={'Family Site'}
                                //onChange={onChange}
                                labelWidth={100}
                            >
                                <MenuItem value={'1'}>준비중입니다.</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    }
                </div>
                <div className={classes.companyContainer}>
                    <div className={classes.companyBox}>
                        <Typography variant='h5'>대표전화 : 02-6249-0014</Typography>
                        <Typography variant='subtitle1'>서울특별시 마포구 월드컵북로 396 누리꿈스퀘어 연구개발타워 7층</Typography>
                        {/* 2021-05-11 #64 [미래편지] 오탈자 수정 : 'LETTER' → 'MAIL' by. 전재민 */}
                        <Typography variant='subtitle1'>Copyrightⓒ2020FUTUREMAIL.All Rights Reserved.</Typography>
                    </div>
                    { !isMobile &&
                    <div className={classes.logoBox}>
                        <img src={require('../images/letter/bottom_logo.png').default}/>
                    </div>
                    }
                </div>
            </Grid>
        </Grid>


);
}
