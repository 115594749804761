import React, {useEffect, useState} from 'react';
import {makeStyles, responsiveFontSizes, useTheme} from '@material-ui/core/styles';
import {useStores} from '../common/store';
import {useHistory} from 'react-router';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import storage, {StorageKey as SK} from '../common/storage';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useMediaQuery} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
    header:{
        position:'relative',
        width:'100%',
        zIndex:1301,
        [theme.breakpoints.between('xs', 'md')]: {
            zIndex:1,
        }
    },
    root: {
        display: 'flex',
        position:'relative',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft:50,
        paddingRight:50,
        //height: '3.5rem',
        [theme.breakpoints.between('xs', 'md')]: {
            height:'5rem',
            paddingLeft:20,
            paddingRight:10,
            justifyContent:'space-between'
        }
    },
    logoContainer: {
        position:'absolute',
        left:50,
        cursor:'pointer',
        zIndex:1301,
        [theme.breakpoints.between('xs', 'md')]: {
            position:'initial'
        },
        '& > img':{
            [theme.breakpoints.down('xs')]: {
                width:'135px',
            }
        }
    },
    buttonContainer: {
        position:'absolute',
        right:50,
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        '& > div':{
            paddingLeft:6,
            '& > * ':{
                cursor:'pointer',
                fontSize:'1rem',
            }
        }
    },
    menuContainer:{
        display:'flex',
        flexGrow:1,
        alignItems:'center',
        '& .MuiTabs-flexContainer':{
            justifyContent:'center',
            height:'7.143rem',
            '& > button ':{
                minWidth:160,
                marginLeft:10,
                marginRight:10,
                '& > span ':{
                    fontFamily: 'Noto Sans CJK KR',
                    fontSize:'1.500rem',
                    cursor:'pointer',
                    lineHeight:'2.214rem',
                }
            }
        },
        '& .MuiTabs-indicator':{
            backgroundColor:'#D3275F',
            height: '3px'
        }
    },
    root3: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
    },
    menuBtn: {
        width: '4rem',
        height: '4rem',
        color: theme.palette.text.gray,
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'none'
        }
    },
    userInfo: {
        minWidth: 200,
        display: 'flex',
        alignItems: 'center',
        // color: (theme.palette.type === "dark") ? theme.palette.text.secondary : theme.palette.primary.contrastText,
        color: theme.palette.text.gray,
        [theme.breakpoints.between('xs', 'md')]: {
            cursor:'pointer',
            padding:12,
            minWidth:'auto',
            '& > p':{
                display:'none'
            }
        }
    },
    logoutBox:{
        display:'flex',
        alignItems:'center',
        color:'gray',
        cursor:'pointer',
        [theme.breakpoints.between('xs', 'md')]: {
            display:'none',
        }
    },
    langSelector: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4rem',
        marginRight: '4rem',
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:'gray',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':{
            borderColor:'hsl(0 0% 100% / 0.5)',
        },
        '& .MuiSelect-icon':{
            color:'gray',
        }
    },
    langSelect: {
        minWidth: 100,
        color: theme.palette.text.gray,
        '& > div': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    },
    langMobSelector:{
        marginLeft:theme.spacing(2),
        '& > .MuiInput-underline:before, & > .MuiInput-underline:after, & > .MuiInput-underline:hover:not(.Mui-disabled):before':{
            border:'none',
        },
        '& .MuiSelect-root.MuiSelect-select':{
            borderRadius:theme.spacing(6)
        }
    },
    langMobSelect:{
        '& .MuiSelect-select.MuiSelect-select': {
            padding:'12px',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon':{
            display:'none',
        }
    },
    themeSelect: {
        color: theme.palette.text.gray,
    },
    userPop: {
        '& .MuiPopover-paper': {
            backgroundColor: theme.palette.background.sideMenu,
        }
    },
}));


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function TopBar({openItem}) {

    const classes = useStyles();
    const {ds} = useStores();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const {handleClick, select, handlerSelect} = openItem;

    const onMenu = (e) => {
        if (handleClick) {
            handleClick(e, {open: true});
        }
    }

    const onClickLogin = () => {
        history.push('/login');
    }

    const onRouteUrl = (url) => {
        history.push(url);
    }

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    const tabIndexChangeHandler = (event, newValue) => {
        handlerSelect(event, newValue);
        onMenu(event);
    }

    return (
        <div className={classes.header}>
            <div className={classes.root}>
                <div className={classes.logoContainer} onClick={ () => onRouteUrl("/")}>
                    <img src={require('../images/letter/top_logo.png').default}/>
                </div>

                { isMobile
                    ?
                        <React.Fragment>
                            <IconButton aria-label="search button" onClick={onMenu}  >
                                <MenuIcon />
                            </IconButton>
                        </React.Fragment>
                    :
                    (
                        <React.Fragment>
                            <Tabs value={select}
                                className={classes.menuContainer}
                                variant="scrollable"
                                scrollButtons="auto"
                                >
                                <Tab key={0} data-key={0} label={'셀프편지'}     {...a11yProps(0)} onMouseOver={(event) => tabIndexChangeHandler(event, 0)}/>
                                <Tab key={1} data-key={1} label={'사랑하는 사람'} {...a11yProps(1)} onMouseOver={(event) => tabIndexChangeHandler(event, 1)}/>
                                <Tab key={2} data-key={2} label={'특별한 날'}    {...a11yProps(2)} onMouseOver={(event) => tabIndexChangeHandler(event, 2)}/>
                                <Tab key={3} data-key={3} label={'홍보센터'}     {...a11yProps(3)} onMouseOver={(event) => tabIndexChangeHandler(event, 3)}/>
                                <Tab key={4} data-key={4} label={'고객센터'}     {...a11yProps(4)} onMouseOver={(event) => tabIndexChangeHandler(event, 4)}/>
                            </Tabs>

                            <div className={classes.buttonContainer}>
                            {
                                ds.isLogin ? (
                                        <React.Fragment>
                                            <div onClick={() => onRouteUrl('/mypage')}><Typography variant='h6' color='textPrimary'>마이페이지</Typography></div>
                                            <div onClick={logoutHandler}> <Typography variant='h6' color='textPrimary'>로그아웃</Typography> </div>
                                        </React.Fragment>
                                    )
                                    : (
                                        <React.Fragment>
                                            <Box><img src={require('../images/letter/main_login_icon.png').default}/></Box>
                                            <Box><Typography variant={'subtitle1'} color='textPrimary' onClick={onClickLogin}>로그인</Typography></Box>
                                        </React.Fragment>
                                    )
                            }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
            <Divider/>
        </div>

);
}
