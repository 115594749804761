import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextFields from "../../components/TextFields";
import AddressText from "../../components/AddressText";
import TextEditor from "../../components/TextEditor";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";

import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import FileFields from "../../components/FileFields";
import api from "../../common/api";
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {BoardCategory} from "../../common/types";
import * as Validator from "validatorjs";
import {PersonalInformation} from "../../components/Agreement";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {RadiusButton, SButton} from "../../components/Buttons";


const useStyles = makeStyles((theme) => ({
    container: {

    },
    itemContainer: {
        margin:'0 auto',
    },
    columnContainer:{
        display:'flex',
        flex:1,

        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1,
        },
        '& > div:nth-child(1)':{ marginRight:theme.spacing(1),},
        '& > div:nth-child(2)':{ marginLeft:theme.spacing(1), }
    },
    agreementContainer:{
        display:'flex',
        flexDirection:'column',
        flex:1,
    },
    agreementBox:{
        height:400,
        border: '1px solid #dedede',
        borderRadius: '8px',
        padding: '16px',
        overflowX: 'hidden',
        margin: '8px 0',
    },
    agreeButton:{
        textAlign:'right',
    },
    buttonContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        margin:`${theme.spacing(4)}px 0`,
        [theme.breakpoints.between('xs', 'md')]: {
            '& > button':{
                flex:1,
            }
        },
    },
    duplicateContainer:{
        height:52,
        alignSelf:'center'
    }
}));



export default function SignupPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const history = useHistory();
    const [response, setResponse] = useState(null);
    const [open, setOpen] = useState(false);
    const [agreement, setAgreement] = useState({
        personal:false
    });

    const registerState = useRef({
        agreement:{
            personal:false,
        },
        userKeyCheck:false,
        emailCheck:false
    })


    const values = useRef({
        userKey : null,             // '사용자 KEY',
        password : null,            // '비밀번호',
        passwordConfirm : null,     // '비밀번호 확인',
        name : null,                // '이름',
        email : null,               // '이메일',
        phoneNumber : null,         // '전화번호',
        cellPhoneNumber : null,     // '휴대전화번호',
        zipCode : null,             // '우편 번호',
        address : null,             // '주소',
        addressDetail : null,       // '주소 상세',
    });



    const valueForm = new MobxReactForm({
        fields: [
            {name: 'userKey',           value: values.current.userKey,          rules: 'required', label: '아이디'},
            {name: 'password',          value: values.current.password,
                rules: 'required|string|between:4,15', label: '패스워드', type:'password'},
            {name: 'passwordConfirm',   value: values.current.passwordConfirm,
                rules: 'required|string|same:password', label: '패스워드 확인', type:'password'},
            {name: 'name',              value: values.current.name,             rules: 'required', label: '이름'},
            {name: 'email',             value: values.current.email,            rules: 'required|email', label: '이메일'},
            {name: 'phoneNumber',       value: values.current.phoneNumber,      label: '일반전화'},
            {name: 'cellPhoneNumber',   value: values.current.cellPhoneNumber,  rules: 'required', label: '휴대전화'},
            {name: 'zipCode',           value: values.current.zipCode,          rules: 'required', label: '우편번호'},
            {name: 'address',           value: values.current.address,          rules: 'required', label: '주소'},
            {name: 'addressDetail',     value: values.current.addressDetail,    rules: 'required', label: '상세 주소'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                if (!form.isDirty) {
                    ds.showWarningAlert('변경된 내용이 없습니다.');
                    return;
                }

                if( !registerState.current.agreement.personal ){
                    ds.showWarningAlert('개인정보 수집 및 이용 약관 동의를 해주기시 바랍니다.');
                    return ;
                }
                if( !registerState.current.userKeyCheck ){
                    ds.showWarningAlert('아이디 중복확인을 해주시기 바랍니다.');
                    return ;
                }
                if( !registerState.current.emailCheck ){
                    ds.showWarningAlert('이메일 중복확인을 해주시기 바랍니다.');
                    return ;
                }


                setOpen(true);

            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const onDuplicateHandler = (checkItem) => {
        console.log("onDuplicateHandler => ",checkItem);
        let checkObject = null;

        if( "userKey" === checkItem ){
            checkObject = valueForm.$("userKey");
        } else if( "email" === checkItem ){
            checkObject = valueForm.$("email");
        }


        if( checkObject?.hasError ){
            checkObject.showError = true;
            return ;
        }

        const checkName = checkObject?.label;
        const checkValue = checkObject?.value;

        if( !checkValue ){
            checkObject.showError = true;
            return ;
        }

        api.duplicateCheck(checkItem,checkValue)
            .then(data => {
                if(data.rtCode === 0) {
                    ds.showSuccessAlert(`${checkName} ${checkValue}는 사용하실 수 있습니다.`);

                    if( "userKey" === checkItem ){
                        registerState.current.userKeyCheck = true;
                    } else if( "email" === checkItem ){
                        registerState.current.emailCheck = true;
                    }
                } else {
                    ds.showErrorAlert(`${checkName} ${checkValue}는 사용하실 수 없습니다.`);
                    checkObject.value = "";
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert(`${checkName} ${checkValue}는 사용하실 수 없습니다.`);
                checkObject.value = "";
            });
    }

    const onConfirmOkBtn = () => {
        console.log("values.current => ",values.current);
        api.requestSignUp(values.current)
            .then(data => {
                if(data.rtCode === 0) {
                    ds.showSuccessAlert('등록되었습니다.');

                    setTimeout( () => {
                        history.push('/login');
                    },1000);
                } else {
                    ds.showErrorAlert('등록에 실패하였습니다.');
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert('등록에 실패하였습니다.');
            });
    }

    const onChangeHandler = (event) => {
        if( event === 'userKey') {
            registerState.current.userKeyCheck = false;
        } else if( event === 'email'){
            registerState.current.emailCheck = false;
        }
    }

    const onChangeAgreement = ({target}) => {
        registerState.current.agreement.personal = target.checked;
        setAgreement({personal: target.checked});
    }


    return (
        <Grid container className={classes.container}>
            <Grid item xl={6} xs={12} className={classes.itemContainer}>
                <div className={classes.agreementContainer}>
                    <Typography variant={'subtitle1'}>{'개인정보 수집 및 이용'}</Typography>
                    <div className={classes.agreementBox}>
                        <PersonalInformation />
                    </div>
                    <div className={classes.agreeButton}>
                        <FormControlLabel
                            control={<Checkbox color='primary'
                                               checked={agreement.personal}
                                               onChange={onChangeAgreement}/>}
                            className={classes.label}
                            label={'개인정보 수집 및 이용 약관에 동의합니다.'}
                        />
                    </div>
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('userKey')} variant={'outlined'}
                                    onKeyPress={() => onChangeHandler('userKey')}
                        />
                    </div>
                    <SButton color={'primary'}
                             onClick={() => onDuplicateHandler('userKey')}
                             clazz={classes.duplicateContainer}
                             text={'중복 확인'}
                    />
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('email')} variant={'outlined'} onKeyPress={() => onChangeHandler('email')}/>
                    </div>
                    <SButton color={'primary'}
                             onClick={() => onDuplicateHandler('email')}
                             clazz={classes.duplicateContainer}
                             text={'중복 확인'}
                    />

                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('password')} variant={'outlined'}/>
                    </div>
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('passwordConfirm')} variant={'outlined'}/>
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('name')} variant={'outlined'}/>
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <AddressText label={'주소'}
                                     variant={'outlined'}
                                     f_zipCode={valueForm.$('zipCode')}
                                     f_address={valueForm.$('address')}
                                     f_addressDetail={valueForm.$('addressDetail')}

                        />
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('phoneNumber')} variant={'outlined'}/>
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('cellPhoneNumber')} variant={'outlined'}/>
                    </div>
                </div>


                <Box className={classes.buttonContainer}>
                    <RadiusButton color={'primary'} size={'large'} onClick={valueForm.onSubmit} text={'회원가입'}/>
                </Box>

            </Grid>

            <ConfirmDialog
                open={open}
                onOk={onConfirmOkBtn}
                onClose={() => setOpen(false)}
                title={'확인'}
                content={'등록하시겠습니까?'}/>

        </Grid>
    )
}
