import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ConfirmDialog from "../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";
import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import FileFields from "../../components/FileFields";
import api from "../../common/api";
import queryString from 'query-string';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {SButton} from "../../components/Buttons";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    detailContainer:{

        border:'1px solid #C4C4C4',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.07)',
        margin:'15px 0',

    },
    contentContainer:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        padding:30,
        '& > div':{
            padding:'9px 0',
            '& > label':{
                fontWeight: 350,
                fontSize: '1.786rem',
                lineHeight: '2.643rem'
            }
        },
        [theme.breakpoints.down('md')]: {
            padding:0,
            '& > :first-child, & > :last-child':{
                padding:10,
            }
        }
    },
    columnContainer:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        padding:30,
        '& > div':{
            display:'flex',
            alignItems:'center',
            flex:1,
            paddingTop:10,
            paddingBottom:10,
            '& > *':{
                fontWeight: 350,
                fontSize: '1.143rem',
                lineHeight: '1.714rem'
            },
            '& > :nth-child(1)':{
                minWidth:160,
            },
            [theme.breakpoints.down('md')]: {
                flexDirection:'column',
                alignItems:'initial'
            }
        },
        [theme.breakpoints.down('md')]: {
            padding:10,
        }

    },
    alignTop:{
        alignItems:'baseline !important',
    },

    buttonContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        margin:`${theme.spacing(4)}px 0`,

        [theme.breakpoints.up('lg')]: {
            '& > button:nth-child(even)': {
                marginLeft: theme.spacing(1),
            },
            '& > button:nth-child(odd)': {
                marginRight: theme.spacing(1),
            },
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection:'column',
            '& > button':{
                marginLeft:0,
                marginRight:0,
                marginBottom:theme.spacing(1),
                flex:1,
            },
        },
    },
    primaryButton:{
        padding:'20px 55px',
        backgroundColor:'#D82B64',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#b52554',
        }
    },
    secondaryButton:{
        padding:'20px 55px',
        backgroundColor:'#5F6265',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#4d4d4d',
        }
    },

    letterContainer:{
        position:'relative',
        overflow:'hidden',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        minHeight:850,
        '& > img':{
            position:'absolute',
            width:'100%',
            minHeight:'100%',
        },
        '& > div':{
            borderRadius:10
        },
        '& > .ql-editor':{
            position:'absolute',
            width:600,
            height:500,
            background:'#fff',
            padding:'20px 30px 0 30px',
            opacity: 0.7,   //#63 [미래편지] 편지 상세 수정
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            borderRadius:10,
            '& > *':{width:'100%'},
            [theme.breakpoints.down('xs')]: {
                width:'calc(100vw - 50px)',
                height:300,
            }
        },
        [theme.breakpoints.down('xs')]: {
            minHeight:650
        }
    },


}));

export default function LetterDetailPage(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {location} = props;
    const history = useHistory();
    const querySearch = queryString.parse(location.search);
    const letterSeq = querySearch.letterSeq;
    console.log("letterSeq => ",letterSeq);

    const [response, setResponse] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);

    const values = useRef({
        letterSeq: '',
        type: '',
        title: '',
        content: '',
        sendYn: '',
        repeatYn: '',
        reservationDate:'',
        reservationStr:'',
        templateSeq:null,
        templateOrgName:null,
        templateEncoding:null,
        stampSeq:null,
        stampOrgName:null,
        stampEncoding:null,
        regDt: '',
        recipientSeq: '',
        recipientName: '',
        recipientEmail: '',
        recipientZipCode: '',
        recipientAddress: '',
        recipientAddressDetail: '',
        recipientPhoneNumber: '',
        recipientCellPhoneNumber: '',
    });

    const userValues = useRef({
        userSeq: '',
        userKey: '',
        userName: '',
        userEmail: '',
        userZipCode:'',
        userAddress: '',
        userAddressDetail: '',
        userPhoneNumber:'',
        userCellPhoneNumber: '',
    });

    const fileValues = useRef( {
        letterSeq: null,
        inputFile: null,
        attachName:null,
        attachOrgName:null,
        attachPath: null,
        attachSeq: null
    });

    const testValue = useRef( {
        encoding: null,

    });

    const onConfirmCloseBtn = () =>{
        setOpenConfirm(false);
    }


    const onConfirmOkBtn = () => {
        onConfirmCloseBtn();

        api.deleteLetter(letterSeq)
            .then(data => {
                if(data.rtCode === 0) {
                    ds.showSuccessAlert('삭제되었습니다.');
                    history.goBack();
                } else {
                    ds.showErrorAlert("삭제에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert(err.rtMsg);
                console.log(err);
                //return err;
            });
    };


    useEffect( () => {
        if( letterSeq === undefined || letterSeq === ''){
            ds.showErrorAlert('잘못된 접근 입니다.');
            history.push('/');
        }

        api.getLetterDetail( letterSeq )
            .then(res => {
                console.log("res => ",res);
                if( res.rtCode === 0){
                    console.log("res.data => ",res.data);
                    values.current = res.data;

                    userValues.current.userSeq = (res.data.userSeq ? res.data.userSeq : null);
                    userValues.current.userKey = (res.data.userKey ? res.data.userKey : '-');
                    userValues.current.userName = (res.data.userName ? res.data.userName : '-');
                    userValues.current.userEmail = (res.data.userEmail ? res.data.userEmail : '-');
                    userValues.current.userZipCode = (res.data.userZipCode ? res.data.userZipCode : '-');
                    userValues.current.userAddress = (res.data.userAddress ? res.data.userAddress : '-');
                    userValues.current.userAddressDetail = (res.data.userAddressDetail ? res.data.userAddressDetail : '-');
                    userValues.current.userPhoneNumber = (res.data.userPhoneNumber ? res.data.userPhoneNumber : '-');
                    userValues.current.userCellPhoneNumber = (res.data.userCellPhoneNumber ? res.data.userCellPhoneNumber : '-');

                    fileValues.current.letterSeq = (res.data.letterSeq ? res.data.letterSeq : null);
                    fileValues.current.attachSeq = (res.data.attachSeq ? res.data.attachSeq : null);
                    fileValues.current.attachName = (res.data.attachName ? res.data.attachName : '-');
                    fileValues.current.attachOrgName = (res.data.attachOrgName ? res.data.attachOrgName : '-');
                    fileValues.current.attachPath = (res.data.attachPath ? res.data.attachPath : '-');
                    fileValues.current.attachSize = (res.data.attachSize ? res.data.attachSize : '-');

                    setResponse(res.data);
                } else {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });


    }, []);


    const onModifyUrl = () => {
        values.current = null;
        history.push({
            pathname: '/mypage/send/modify',
            search: "?" + api.encodeParams({letterSeq: letterSeq}),
        })
    }

    const onDeleteLetter = () => {
        setOpenConfirm(true);
    }

    const onDownLoadFile = () => {
        api.getLetterAttachDownLoad( values.current.letterSeq, fileValues.current.attachSeq, fileValues.current.attachOrgName)
            .then(res => {})
            .catch(err => {});
    }

    const letterTemplateClass = {
        position:'absolute',
        width:'100%',
        minHeight:500,
        backgroundImage:`url('${values.current.templateEncoding}')`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        height:'100%'
    }

    const letterStampClass = {
        position:'absolute',
        width:215,
        height:130,
        backgroundImage:`url('${values.current.stampEncoding}')`,
        backgroundRepeat:'no-repeat',
        top:20, 
        right:20,
        backgroundSize:'contain'
    }

    return (
        <Grid container>
            <Grid item xl={12} xs={12} className={classes.detailContainer}>
                <Box className={classes.columnContainer}>
                    <Box>
                        <InputLabel>{'보낸사람 이름'}</InputLabel>
                        <Typography variant={'subtitle1'}>{userValues.current.userName}</Typography>
                    </Box>
                    <Box>
                        <InputLabel>{'보낸사람 이메일'}</InputLabel>
                        <Typography variant={'subtitle1'}>{userValues.current.userEmail}</Typography>
                    </Box>
                    <Box className={classes.alignTop}>
                        <InputLabel>{'보낸사람 주소'}</InputLabel>
                        <Box>
                        <Typography variant={'subtitle1'}>{userValues.current.userZipCode}</Typography>
                        <Typography variant={'subtitle1'}>{userValues.current.userAddress + " " +userValues.current.userAddressDetail}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <InputLabel>{'일반전화'}</InputLabel>
                        <Typography variant={'subtitle1'}>{userValues.current.userPhoneNumber}</Typography>
                    </Box>

                    <Box>
                        <InputLabel>{'휴대전화'}</InputLabel>
                        <Typography variant={'subtitle1'}>{userValues.current.userCellPhoneNumber}</Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid item xl={12} xs={12} className={classes.detailContainer}>
                <Box className={classes.columnContainer}>
                    <Box>
                        <InputLabel>{'받는사람 이름'}</InputLabel>
                        <Typography variant={'subtitle1'}>{values.current.recipientName}</Typography>
                    </Box>
                    <Box>
                        <InputLabel>{'받는사람 이메일'}</InputLabel>
                        <Typography variant={'subtitle1'}>{values.current.recipientEmail}</Typography>
                    </Box>
                    <Box className={classes.alignTop}>
                        <InputLabel>{'받는사람 주소'}</InputLabel>
                        <Box>
                            <Typography variant={'subtitle1'}>{values.current.recipientZipCode}</Typography>
                            <Typography variant={'subtitle1'}>{values.current.recipientAddress + " " +values.current.recipientAddressDetail}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <InputLabel>{'일반전화'}</InputLabel>
                        <Typography variant={'subtitle1'}>{values.current.recipientPhoneNumber}</Typography>
                    </Box>

                    <Box>
                        <InputLabel>{'휴대전화'}</InputLabel>
                        <Typography variant={'subtitle1'}>{values.current.recipientCellPhoneNumber}</Typography>
                    </Box>
                    <Box>
                        <InputLabel>{'예약일'}</InputLabel>
                        <Typography variant={'subtitle1'}>{moment.utc(values.current.reservationDate).format('yyyy-MM-DD HH:mm:ss')}</Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid item xl={12} xs={12} className={classes.detailContainer}>
                <Box className={classes.contentContainer}>
                    <Box>
                        <Typography variant={'h5'}>{values.current.title}</Typography>
                    </Box>

                    <Box className={classes.letterContainer}>
                        {/*<img width={'100%'} height={'100%'} src={values.current.templateEncoding} alt=""/>*/}
                        <Box style={letterTemplateClass} ></Box>
                        <Box style={letterStampClass} ></Box>
                        <Box className={"ql-editor"} dangerouslySetInnerHTML={{__html: values.current.content}} />

                    </Box>

                    <Box>
                        <FileFields orgFileName={fileValues.current.attachOrgName}
                                    onDownLoad={onDownLoadFile}
                                    uploadEnable={false}
                        />
                    </Box>


                </Box>
            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.buttonContainer}>
                    <SButton text={'수정하기'} color={'primary'} size={'middle'} onClick={onModifyUrl} />
                    <SButton text={'삭제하기'} color={'secondary'} size={'middle'} onClick={onDeleteLetter} />
                </div>
            </Grid>


            <ConfirmDialog
                open={openConfirm}
                onOk={onConfirmOkBtn}
                onClose={onConfirmCloseBtn}
                title={'확인'}
                content={'삭제하시겠습니까?'}/>
        </Grid>
    );
}



















