import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue} from "../common/types";
import FormHelperText from "@material-ui/core/FormHelperText";

const selectStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
        }
    },
    label:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    }
}));

export default function SelectFields(props) {
    const selectStyle = selectStyles();

    const {className, title, contents, addAll, field, onChange} = props;

    const [labelId] = useState(() => _.uniqueId('label-'));

    return useObserver( () => (
        <React.Fragment>
            <InputLabel id={labelId} className={selectStyle.label} {...(field?.error ? {error: true} : null)}>{field?.label}</InputLabel>
            <FormControl variant="outlined" className={selectStyle.inputText}  {...(field?.error ? {error: true} : null)}>
                <Select labelId={labelId}
                        {...field?.bind()}
                        displayEmpty={true}
                        label={undefined}
                >
                    {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                    {
                        contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                    }
                </Select>
                {field ? <FormHelperText>{field.error ? field.error : " "}</FormHelperText> : null}
            </FormControl>
        </React.Fragment>
    ));
}
