import {createMuiTheme} from '@material-ui/core/styles';

const theme_norm = createMuiTheme({
    palette: {
        primary: {
            light: '#6698ff',
            main: '#0f6be7',
            dark: '#0041b4',
            contrastText: '#fff',
            red:'#cd235a'
        },
        secondary: {
            light: '#6bffff',
            main: '#00ccff',
            dark: '#009bcc',
            contrastText: '#fff'
        },
        background: {
            panel:'#e7e7e7',
            paper: '#fff',
            default: '#fff',
            sideMenu:'#fff',
            scroll:'#b8bbbeb3',
            footer: '#f7f7f7',
            blackBox:'#21262e',
            tab:'#dedede',
        },
        text: {
            primary: 'rgb(90,90,90)',
            secondary: 'rgb(127,127,127)',
            disabled: 'rgb(170,170,170)',
            hint: 'rgb(170, 170, 170)',
            divider: 'rgb(215, 215, 215)',
            title:'#0f6be7',
            gray:'#7f7f7f',
            white:'#fff',
        },
        border: {
            primary:'#545a61'
        },
        // type: 'dark'
    },
    typography: {
        fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '2.5rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em'
        },
        h2: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 300,
            fontSize: '2.125rem',
            lineHeight: 1.167,
            letterSpacing: '-0.00833em'
        },
        h3: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.75rem',
            lineHeight: 1.167,
            letterSpacing: '0em'
        },
        h4: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em'
        },
        h5: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.25rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        h6: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.125rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        subtitle1: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em'
        },
        subtitle2: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            letterSpacing: '0.00714em'
        },
        body1: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
        },
        body2: {
            fontFamily: ['Noto Sans CJK KR', 'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        },
        button: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em',
            textTransform: 'uppercase'
        },
        largeButton: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: 2.214,
            letterSpacing: '0.00938em',
            textTransform: 'uppercase'
        },
    },
    buttons:{
        btn1 : {
            background : {
                hover:'#bfbfbf',
                default: '#999999'
            },
            color:'#fff'
        }
    },
    props: {
        MuiTypography: {}
    }
});


export default {norm:theme_norm};
