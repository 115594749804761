import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue} from "../common/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ConfirmDialog from "./ConfirmDialog";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import api from "../common/api";
import InputLabels from "./InputLabels";
import {SButton} from "./Buttons";

const selectStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
        },
        '& > .MuiInputBase-root': {
            '& > .MuiSelect-root.MuiSelect-select':{
                display:'flex',
                alignItems:'center',
            }
        },
        [theme.breakpoints.down('md')]: {
            marginBottom:23,
        },
    },
    label:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    }
}));

const dialogStyles = makeStyles((theme) => ({
    dialogContainer:{
        zIndex:'1400 !important',
        '& > .MuiDialog-container > .MuiPaper-root':{
            flex:1,
        }
    },
    dialog_title: {
        marginBottom: theme.spacing(1),
        minWidth: 400,
        maxWidth: 720,
        "& > *": {
            ...theme.typography.h5
        }
    },
    dialogContentContainer:{
        alignSelf:'center',
    }
}));

function TemplateDialog({open, item, onClose, onOk}) {
    const dialogStyle = dialogStyles();
    const [confirm, setConfirm] = useState(false);

    const image = item?.key?.templateEncoding;

    const onHandlerOk = () => {
        setConfirm(true);
    }
    const onHandlerConfirmOk = () => {
        setConfirm(false);
        onClose();
        onOk(item);
    }
    const onHandlerConfirmClose = () => {

        setConfirm(false);
    }

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={dialogStyle.dialogContainer}
            maxWidth={"lg"}
        >
            <DialogTitle id="alert-dialog-title">{'편지지 미리보기'}</DialogTitle>
            <DialogContent className={dialogStyle.dialogContentContainer}>
                { image && <img src={image} width={'100%'} /> }
            </DialogContent>
            <DialogActions>
                <SButton onClick={onHandlerOk} color="primary" autoFocus text={'적용'} />
                <SButton text={'취소'} onClick={() => onClose(false)} />
            </DialogActions>

            <ConfirmDialog
                open={confirm}
                onOk={onHandlerConfirmOk}
                onClose={onHandlerConfirmClose}
                title={'확인'}
                content={'적용하시겠습니까?'}/>
        </Dialog>
    ));
}



export default function IconSelectFields(props) {
    const selectStyle = selectStyles();

    const {contents, addAll, field, onChange} = props;

    const [preview, setPreview] = useState(false);
    const previewContent = useRef( null );


    const onOkPreview = (confirmItem) => {

        console.log("confirmItem=> ",confirmItem)

        onChange(confirmItem.value);
    }
    const onClosePreview = () => {
        setPreview(false);
        previewContent.current = null;
    }

    const onHandlerSelect = (event) => {
        setPreview(true);
        previewContent.current = contents[event.target.value];
    }

    const [labelId] = useState(() => _.uniqueId('label-'));

    return useObserver( () => (
        <React.Fragment>

            <InputLabels id={labelId} className={selectStyle.label} label={field?.label} errors={{...(field?.error ? {error: true} : null)}} />

            <FormControl variant="outlined" className={selectStyle.inputText}  {...(field?.error ? {error: true} : null)}>
                <Select labelId={labelId}
                        {...field?.bind()}
                        displayEmpty={true}
                        label={undefined}
                        onChange={onHandlerSelect}
                >
                    {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                    {
                        contents.map((content, idx) => (
                            <MenuItem key={idx} value={content.value}>
                                { (content?.key?.templateEncoding) &&
                                    <ListItemIcon style={{paddingRight:16}}>
                                        <img src={content?.key?.templateEncoding} height={40} />
                                    </ListItemIcon>
                                }
                                <ListItemText> {content.text}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                {field ? <FormHelperText>{field.error ? field.error : ""}</FormHelperText> : null}
            </FormControl>

            <TemplateDialog
                open={preview}
                item={previewContent.current}
                onClose={onClosePreview}
                onOk={onOkPreview}
            />
        </React.Fragment>
    ));
}
