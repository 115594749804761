import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import api from "../common/api";
import TextFields from "./TextFields";
import Box from "@material-ui/core/Box";
import {useStores} from "../common/store";
import {Button, Typography} from "@material-ui/core";


const dialogStyles = makeStyles((theme) => ({
    dialogContainer:{
        '& > .MuiDialog-container > .MuiPaper-root':{
            flex:1,
        }
    },
    dialog_title: {
        marginBottom: theme.spacing(1),
        minWidth: 400,
        maxWidth: 720,
        "& > *": {
            ...theme.typography.h5
        }
    },
    contentContainer:{
        display:'flex',
        flexDirection:'column',
    }
}));

export default function PasswordDialog({open, onClose, onComplete}) {
    const dialogStyle = dialogStyles();
    const {ds} = useStores();

    const values = useRef({
        userKey:'',
        password:''
    });

    const valueForm = new MobxReactForm({
        fields: [
            {name: 'userKey', value: values.current.userKey, label: 'userKey'},
            {name: 'password', value: values.current.password, label: '비밀번호', type:'password'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                values.current.userKey = ds.user.userKey;
                api.requestSignIn(values.current)
                    .then(data => {
                        if (data.rtCode === 0) {
                            onComplete();
                            //ds.showSuccessAlert('로그인 성공!');
                        } else {
                            ds.showErrorAlert("비밀번호가 틀렸습니다.");
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        ds.showErrorAlert("처리에 실패하였습니다.");
                        //return err;
                    });
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='xs'
            className={dialogStyle.dialogContainer}
        >
            <DialogTitle id="alert-dialog-title">{'회원 탈퇴'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Box className={dialogStyle.contentContainer}>
                        <Typography variant={"subtitle1"}>회원 탈퇴를 위해 계정 인증을 하여주시기 바랍니다.</Typography>
                        <TextFields field={valueForm?.$('password')} variant={'outlined'}/>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={valueForm?.onSubmit}>{'확인'}</Button>
                <Button style={{backgroundColor:'#989898', color:'#fff'}}variant={"contained"} onClick={() => onClose(false)}>{'취소'}</Button>
            </DialogActions>
        </Dialog>
    )
}
