import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import {Container, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";

/**
 * DataTable Component
 * @param style : 테이블에 적용 할 스타일
 * @param component : HTML element 종류
 * @param size : Cell 크기
 * @param padding : Cell 페딩
 * @param headContent : 테이블 컬럼명
 * @param bodyContent : 테이블 내용
 * @param oncClickRowEvent : 테이블 Row 클릭 시 호출 이벤트
 * @param currentPage : 현재 페이지
 * @param totalPage : 전체 페이지 개수
 * @param enablePaging : 페이징에 표시 여부
 * @param isFirstButton : 페이징에 처음으로 가기 버튼표기 여부
 * @param isLastButton : 페이징에 처음으로 가기 버튼표기 여부
 * @param onChangePageEvent : 페이지 변경 이벤트 발생 시 호출할 함수
 * @returns {JSX.Element} : <DataTable ../>
 * @constructor
 */
export default function DataTable({
                                      style,
                                      component,
                                      size,
                                      padding,
                                      headContent,
                                      bodyContent,
                                      oncClickRowEvent,
                                      currentPage,
                                      totalPage,
                                      enablePaging,
                                      isFirstButton,
                                      isLastButton,
                                      onChangePageEvent,
                                      isSort,
                                      isAlwaysVisibleSort,
                                      changeSortEvent,
                                      emptyText,
                                      minHeight,
                                      disableHead,
                                  }) {

    const headCount = headContent !== undefined ? headContent[headContent.length - 1].length : 0;

    const [sort, setSort] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    let sortTarget = null;

    const onClickSortLabel = (id) => (event) => {
        if (sortTarget === null || sortTarget !== id) {
            sortTarget = id;
            setSort('asc');
        } else {
            setSort(sort ? (sort === 'asc' ? 'desc' : false) : 'asc');
        }

        const changeContent = changeSortEvent(bodyContent, sortTarget, sort);
        bodyContent = changeContent;
    }

    const pageChanged = (event, pageIndex) => {
        const searchContent = onChangePageEvent(event, pageIndex, bodyContent);
        if (searchContent) {
            bodyContent = searchContent;
        }
    };

    if (bodyContent === undefined || bodyContent.length < 1) {
        let lastHeadCount = 5;
        if (headContent !== undefined && headContent.length > 0) {
            lastHeadCount = headContent[headContent.length - 1].length;
        }

        if (isMobile) {
            bodyContent = [
                [{colspan: lastHeadCount, content: ""}, {colspan: lastHeadCount, content: emptyText}]
            ];
        } else {
            bodyContent = [
                [{colspan: lastHeadCount, content: emptyText}]
            ];
        }
    }

    let tableBoxStyle = "";
    if (isMobile) {
        tableBoxStyle = {minHeight: minHeight + 'px', overflowX: 'scroll'};
    } else {
        tableBoxStyle = {minHeight: minHeight + 'px'};
    }

    return (
        <React.Fragment>
            <div style={tableBoxStyle}>
                <Table component={component}
                       className={style.table}
                       size={size}
                       padding={padding}
                >
                    {disableHead !== true &&
                        <TableHead>
                            <DataTableRows style={style ? style : null}
                                           rowDataList={headContent}
                                           variant={'head'}
                                           isSort={isSort}
                                           sort={sort}
                                           sortTarget={sortTarget}
                                           isAlwaysVisibleSort={isAlwaysVisibleSort}
                                           onClickSortLabel={onClickSortLabel}

                            />
                        </TableHead>
                    }
                    <TableBody>
                        <DataTableRows style={style ? style : null}
                                       rowDataList={bodyContent} variant={'body'}
                                       limitCol={headCount}
                                       oncClickRowEvent={oncClickRowEvent}
                        />
                    </TableBody>
                </Table>
            </div>
            {
                enablePaging ?
                    (
                        <Container>
                            <DataTablePagination currentPage={currentPage}
                                                 totalPage={totalPage}
                                                 isFirstButton={isFirstButton}
                                                 isLastButton={isLastButton}
                                                 onChangeEvent={pageChanged}
                            />
                        </Container>
                    ) : null
            }
        </React.Fragment>
    );
}


DataTable.defaultProps = {
    size: 'medium',
    padding: 'default',
    enablePaging: true,
    isSort: false,
    isAlwaysVisibleSort: false,
    emptyText: 'No Data',
    minHeight: 373
}

DataTable.propTypes = {
    //headContent: PropTypes.arrayOf(PropTypes.array),
    bodyContent: PropTypes.arrayOf(PropTypes.array),
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium']),
    padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
    enablePaging: PropTypes.bool,
    isSort: PropTypes.bool,
    isAlwaysVisibleSort: PropTypes.bool,
    emptyText: PropTypes.string,
    minHeight: PropTypes.number,
    disableHead: PropTypes.bool,
}

/**
 * DataTable Rows
 * @param style : Row 들에 일괄 적용할 스타일
 * @param rowDataList : 출력할 데이터
 * @param variant : Cell 유형
 * @param limitCol : 화면에 표기 할 Cell 수, 넘어갈 경우 Cell을 화면에 출력하지 않음
 * @param oncClickRowEvent : Row 클릭 이벤트 발생 시 호출하는 함수
 * @param sort : 데이터 순서 정렬 방식
 * @param sortTarget : 데이터 순서 정렬 대상
 * @returns {*|null} : ...<DataTableRow ../>
 * @constructor
 */
function DataTableRows({
                           style,
                           rowDataList,
                           variant,
                           limitCol,
                           oncClickRowEvent,
                           isSort,
                           sort,
                           sortTarget,
                           isAlwaysVisibleSort,
                           onClickSortLabel
                       }) {
    let lastIndex = rowDataList.length - 1;
    return (
        rowDataList ? rowDataList.map((row, index) =>
                <DataTableRow key={index.toString()}
                              id={variant + '_' + index}
                              style={style}
                              rowContent={row}
                              variant={variant}
                              limitCol={limitCol}
                              oncClickRowEvent={oncClickRowEvent}
                              isSort={index === lastIndex ? isSort : false}
                              sort={sort}
                              sortTarget={sortTarget}
                              isAlwaysVisibleSort={isAlwaysVisibleSort}
                              onClickSortLabel={onClickSortLabel}
                />
            ) :
            null
    )
        ;
}

DataTableRows.defaultProps = {
    limitCol: -1,
}

DataTableRows.propTypes = {
    limitCol: PropTypes.number,
    rowDataList: PropTypes.array
}

/**
 * DataTable Row
 * @param style : 스타일
 * @param rowContent : 출력할 데이터
 * @param variant : Cell 유형
 * @param colspan : Cell 의 열 확장 개수
 * @param limitCol : 화면에 표기 할 Cell 수, 넘어갈 경우 Cell을 화면에 출력하지 않음
 * @param oncClickRowEvent : Row 클릭 이벤트 발생 시 호출하는 함수
 * @returns {JSX.Element|null} <DataTableRow ../>
 * @constructor
 */
function DataTableRow({
                          id,
                          style,
                          rowContent,
                          variant,
                          limitCol,
                          oncClickRowEvent,
                          isSort,
                          sort,
                          sortTarget,
                          isAlwaysVisibleSort,
                          onClickSortLabel
                      }) {
    let rowStyle = null;
    let cellStyle = null;

    if (style !== undefined) {
        if (variant === 'head') {
            rowStyle = style.headRow;
            cellStyle = style.headCell;
        } else {
            rowStyle = style.bodyRow;
            cellStyle = style.bodyCell;
        }
    }

    return (
        rowContent !== undefined ?
            <TableRow key={id}
                      className={rowStyle}
                      onClick={oncClickRowEvent !== undefined ? oncClickRowEvent(rowContent) : null}
            >
                {
                    rowContent.map((cell, idx) => {
                            return (
                                (limitCol < 0 || limitCol > idx) ?
                                    (<DataTableCell key={id + '_' + idx}
                                                    id={id + '_' + idx}
                                                    style={cellStyle}
                                                    cellData={cell.content}
                                                    align={cell.align}
                                                    colspan={cell.colspan}
                                                    variant={variant}
                                                    isSort={isSort}
                                                    sort={sort}
                                                    sortTarget={sortTarget}
                                                    sortLabelVisible={isAlwaysVisibleSort}
                                                    onClickSortLabel={onClickSortLabel}
                                        />
                                    ) : null
                            );
                        }
                    )}
            </TableRow>
            : null
    );
}

DataTableRow.propTypes = {
    rowContent: PropTypes.array
}


/**
 * DataTable Cell
 * @param style : 스타일
 * @param cellData : 출력 할 데이터
 * @param padding : padding
 * @param align : Cell 정렬
 * @param size : 크기
 * @param variant : 유형
 * @param colspan : 열 확장 개수
 * @param sortDirection : 데이터 순서 정렬 방식
 * @returns {JSX.Element} : <DataTableCell ../>
 * @constructor
 */
function DataTableCell({
                           id,
                           style,
                           cellData,
                           padding,
                           align,
                           size,
                           variant,
                           colspan,
                           isSort,
                           sort,
                           sortTarget,
                           sortLabelVisible,
                           onClickSortLabel
                       }) {

    return (
        isSort ?
            <DataTableSortCell id={id}
                               style={style}
                               padding={padding}
                               align={align}
                               size={size}
                               variant={variant}
                               cellData={cellData}
                               colSpan={colspan}
                               sort={sort}
                               sortTarget={sortTarget}
                               sortLabelVisible={sortLabelVisible}
                               onClickSortLabel={onClickSortLabel}
            />
            : <TableCell key={id}
                         className={style}
                         padding={padding}
                         align={align}
                         size={size}
                         variant={variant}
                         colSpan={colspan}
            >
                {cellData}
            </TableCell>
    );
}

DataTableCell.defaultProps = {
    padding: 'default',
    align: 'center',
    size: 'medium',
    colspan: 1,
    sort: false,
    sortLabelVisible: false,
}

DataTableCell.prototype = {
    colspan: PropTypes.number,
    padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
    align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
    size: PropTypes.oneOf(['medium', 'small']),
    variant: PropTypes.oneOf(['body', 'footer', 'head']),
    sortLabelVisible: PropTypes.bool,
}


function DataTableSortCell({id, style, padding, align, size, variant, colspan, cellData, sortLabelVisible, sort, sortTarget, onClickSortLabel}) {
    const key = id;
    let isTarget = key === sortTarget;
    return (
        <TableCell key={key}
                   className={style}
                   padding={padding}
                   align={align}
                   size={size}
                   variant={variant}
                   colSpan={colspan}
                   sortDirection={isTarget ? sort : false}
        >
            <TableSortLabel active={sortLabelVisible ? true : isTarget}
                            direction={isTarget ? (sort ? sort : 'asc') : 'asc'}
                            onClick={onClickSortLabel(id)}
            >
                {cellData}
            </TableSortLabel>
        </TableCell>
    );
}


/**
 * DataTable Pagination
 * @param style : 스타일
 * @param currentPage : 현재 페이지
 * @param totalPage : 전체 페이지 개수
 * @param shape : 페이지 번호 테두리
 * @param size : 페이징 사이즈
 * @param isFirstButton : 페이징에 처음으로 가기 버튼표기 여부
 * @param isLastButton : 페이징에 끝으로 가기 버튼표기 여부
 * @param onChangeEvent : 페이지 변경 이벤트 발생 시 호출 함수
 * @returns {JSX.Element} : <DataTablePagination ../>
 * @constructor
 */
function DataTablePagination({
                                 style,
                                 currentPage,
                                 totalPage,
                                 shape,
                                 size,
                                 isFirstButton,
                                 isLastButton,
                                 onChangeEvent
                             }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const paginationStyle = style();


    return (
        <Pagination className={paginationStyle.root}
                    count={totalPage}
                    page={currentPage}
                    shape={shape}
                    size={isMobile?'small':size}
                    defaultPage={1}
                    siblingCount={1}
                    onChange={onChangeEvent}
                    showFirstButton={isFirstButton}
                    showLastButton={isLastButton}
        />
    );
}


DataTablePagination.defaultProps = {
    totalPage: 0,
    currentPage: 1,
    shape: 'rounded',
    size: 'large',
    style: makeStyles((theme) => ({
            root: {
                display: "flex",
                justifyContent: "center",
            },
        })
    ),
    isFirstButton: false,
    isLastButton: false
}

DataTablePagination.prototype = {
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
    shape: PropTypes.oneOf(['round', 'rounded']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onChangeEvent: PropTypes.func,
    isFirstButton: PropTypes.bool,
    isLastButton: PropTypes.bool
}
