import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextFields from "../../components/TextFields";
import AddressText from "../../components/AddressText";
import TextEditor from "../../components/TextEditor";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";

import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import FileFields from "../../components/FileFields";
import api from "../../common/api";
import moment from "moment";
import queryString from 'query-string';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import DateTimeFields from "../../components/DateTimeFields";
import IconSelectFields from "../../components/IconSelectFields";
import StampSelectFields from "../../components/StampSelectFields";
import {RadiusButton, SButton} from "../../components/Buttons";
import LetterEditor from "../../components/LetterEditor";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    columnContainer:{
        display:'flex',
        flex:1,
        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1,
        },
        '& > div:nth-child(1)':{
            marginRight:theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginRight:0
            },
        },
        '& > div:nth-child(2)':{
            marginLeft:theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginLeft:0
            },
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    itemContainer:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        '& > label': {
            padding:`${theme.spacing(1)}px 0`
        },
        '& .MuiOutlinedInput-root':{
            backgroundColor:'#f9f9f9'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline' :{
            borderColor:'rgba(0, 0, 0, 0.23)',
        },
        '& .MuiOutlinedInput-root.Mui-focused':{
            '& .MuiOutlinedInput-notchedOutline':{
                    //borderColor:'#0f6be7',
                    borderColor:'rgba(0, 0, 0, 0.23)',
                    borderWidth:'1px'
            }
        }
    },
    editorContainer:{
        '& label':{
            paddingBottom:theme.spacing(1)
        }
    },
    buttonContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        [theme.breakpoints.between('xs', 'md')]: {
            '& > button':{
                flex:1,
            }
        },
    },
    rowContainer:{
        display:'flex',
        flex:1,
        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1
        },
        '& > div:nth-child(1)':{
            [theme.breakpoints.down('md')]: {
                marginRight:0
            },
        },
        '& > div:nth-child(2)':{
            [theme.breakpoints.down('md')]: {
                marginLeft:0
            },
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
        },

    }
}));

export default function LetterDetailPage(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {location} = props;
    const history = useHistory();
    const querySearch = queryString.parse(location.search);
    const letterSeq = querySearch.letterSeq;
    console.log("letterSeq => ",letterSeq);

    const [response, setResponse] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [template, setTemplate] = useState(null);
    const [stamp, setStamp] = useState(null);     // 2021-05-14 #65 [미래편지] 우표 선택 기능 개발

    const templates = useRef({
        list : []
    });
    const stamps = useRef({
        list : []
    });

    const values = useRef({
        letterSeq: '',
        type: '',
        title: '',
        content: '',
        sendYn: '',
        repeatYn: '',
        reservationDate:'',
        reservationTime:'',
        reservationStr:'',
        templateSeq:null,
        templateImage:null,
        regDt: '',
        recipientSeq: '',
        recipientName: '',
        recipientEmail: '',
        recipientZipCode: '',
        recipientAddress: '',
        recipientAddressDetail: '',
        recipientPhoneNumber: '',
        recipientCellPhoneNumber: '',
        stampSeq:null,
        stampImage:null
    });

    const userValues = useRef({
        userSeq: '',
        userKey: '',
        userName: '',
        userEmail: '',
        userZipCode:'',
        userAddress: '',
        userAddressDetail: '',
        userPhoneNumber:'',
        userCellPhoneNumber: '',
    });

    const fileValues = useRef( {
        letterSeq: null,
        inputFile: null,
        attachName:null,
        attachOrgName:null,
        attachPath: null,
        attachSeq: null
    });


    const valueForm = new MobxReactForm({
        fields: [
            {name: 'letterSeq',      value: values.current.letterSeq,      rules: 'required'},
            {name: 'type',           value: values.current.type,           rules: 'required'},
            {name: 'title',          value: values.current.title,          rules: 'required', label: '제목'},
            {name: 'content',        value: values.current.content,        rules: 'required', label: '내용'},
            {name: 'reservationStr', value: values.current.reservationStr, label: '예약일'},
            {name: 'reservationDate',value: values.current.reservationDate,label: '예약일'},
            {name: 'reservationTime',value: values.current.reservationTime,label: '예약시간'},
            {name: 'repeatYn',       value: values.current.repeatYn,       label: '반복여부'},
            {name: 'sendYn',         value: values.current.sendYn,         label: '발송여부'},
            {name: 'templateSeq',    value: values.current.templateSeq,    label: '편지지'},
            {name: 'templateImage',  value: values.current.templateImage,  label: '편지지Image'},
            {name: 'stampSeq',    value: values.current.stampSeq,    label: '우표'},
            {name: 'stampImage',  value: values.current.stampImage,  label: '우표Image'},

            {name: 'recipientSeq',            value: values.current.recipientSeq,            rules: 'required', label: '수신인 SEQ' },
            {name: 'recipientName',           value: values.current.recipientName,           rules: 'required', label: '받는사람 이름' },
            {name: 'recipientEmail',          value: values.current.recipientEmail,          rules: 'required', label: '받는사람 이메일' },
            {name: 'recipientZipCode',        value: values.current.recipientZipCode,        rules: 'required', label: '받는사람 우편번호' },
            {name: 'recipientAddress',        value: values.current.recipientAddress,        rules: 'required', label: '받는사람 주소' },
            {name: 'recipientAddressDetail',  value: values.current.recipientAddressDetail,  rules: 'required', label: '받는사람 상세주소' },
            {name: 'recipientPhoneNumber',    value: values.current.recipientPhoneNumber,    label: '받는사람 일반전화' },
            {name: 'recipientCellPhoneNumber',value: values.current.recipientCellPhoneNumber,rules: 'required', label: '받는사람 휴대전화' },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                fileValues.current.inputFile = fileForm.$('inputFile').value;
                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const fileForm = new MobxReactForm({
        fields: [
            {name: 'letterSeq', value: fileValues.current.letterSeq, label: '편지 SEQ'},
            {name: 'inputFile', value: fileValues.current.inputFile, label: '첨부파일'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                if( fileValues.current.inputFile !== null && fileValues.current.inputFile !== ''  ) {
                    api.setAttach(fileValues.current)
                        .then(data => {
                            if (data.rtCode === 0) {
                                ds.showSuccessAlert('저장되었습니다.');
                                history.push('/mypage/send');
                            } else {
                                ds.showErrorAlert("저장에 실패하였습니다.");
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            ds.showErrorAlert("저장에 실패하였습니다.");
                            //return err;
                        });
                }
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const onConfirmCloseBtn = () =>{
        setOpenConfirm(false);
    }
    const onDeleteConfirmCloseBtn = () =>{
        setDeleteConfirm(false);
    }

    const uploadFile = () => {
        fileForm.submit();
    }

    const onDeleteConfirm = () => {
        setDeleteConfirm(true);
    }

    const onDeleteFile = () => {
        console.log("onDeleteFile => ",fileValues.current);
        console.log("onDeleteFile => ",fileValues.current.attachSeq);
        setDeleteConfirm(false);
        api.deleteAttach(fileValues.current.attachSeq)
            .then(data => {
                if(data.rtCode === 0) {
                    ds.showSuccessAlert('삭제 되었습니다.');
                } else {
                    ds.showErrorAlert("삭제에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert(err.rtMsg);
                console.log(err);
                //return err;
            });

    }

    const onConfirmOkBtn = () => {
        onConfirmCloseBtn();


        if( (values.current.reservationDate === undefined || values.current.reservationDate === '') ||
            (values.current.reservationTime === undefined || values.current.reservationTime === '')
        ){
            values.current.reservationStr = null;
        } else if( (values.current.reservationDate !== undefined && values.current.reservationDate !== '') ||
            (values.current.reservationTime !== undefined && values.current.reservationTime !== '')
        ){
            values.current.reservationStr = values.current.reservationDate + " " + values.current.reservationTime;
            values.current.reservationStr = moment(values.current.reservationStr).format('yyyy-MM-DD HH:mm:ss')
        }



        if( values.current.templateSeq === '' ){
            values.current.templateSeq = null;
        }
        if( values.current.stampSeq === '' ){
            values.current.stampSeq = null;
        }

        api.putLetter(values.current)
            .then(data => {
                if(data.rtCode === 0) {
                    fileValues.current.letterSeq = data.data.letterSeq;
                    if( fileValues.current.inputFile !== null && fileValues.current.inputFile !== "") {
                        uploadFile();
                    } else {
                        if (data.rtCode === 0) {
                            ds.showSuccessAlert('저장되었습니다.');
                            history.goBack();
                        } else {
                            ds.showErrorAlert("저장에 실패하였습니다.");
                        }
                    }
                }
            })
            .catch(err => {
                ds.showErrorAlert(err.rtMsg);
                console.log(err);
                //return err;
            });
    };

    useEffect( () => {
        if( letterSeq === undefined || letterSeq === ''){
            ds.showErrorAlert('잘못된 접근 입니다.');
            history.push('/');
        }


        api.getLetterDetail( letterSeq )
            .then(res => {
                console.log("res => ",res);
                if( res.rtCode === 0){
                    console.log("getLetterDetail res.data => ",res.data);
                    console.log("template => ",template);
                    values.current = res.data;

                    values.current.reservationStr = res.data.reservationDate;

                    userValues.current.userSeq = (res.data.userSeq ? res.data.userSeq : null);
                    userValues.current.userKey = (res.data.userKey ? res.data.userKey : '-');
                    userValues.current.userName = (res.data.userName ? res.data.userName : '-');
                    userValues.current.userEmail = (res.data.userEmail ? res.data.userEmail : '-');
                    userValues.current.userZipCode = (res.data.userZipCode ? res.data.userZipCode : '-');
                    userValues.current.userAddress = (res.data.userAddress ? res.data.userAddress : '-');
                    userValues.current.userAddressDetail = (res.data.userAddressDetail ? res.data.userAddressDetail : '-');
                    userValues.current.userPhoneNumber = (res.data.userPhoneNumber ? res.data.userPhoneNumber : '-');
                    userValues.current.userCellPhoneNumber = (res.data.userCellPhoneNumber ? res.data.userCellPhoneNumber : '-');
                    userValues.current.stampSeq = (res.data.stampSeq ? res.data.stampSeq : 1);      // 우표 초기화

                    fileValues.current.letterSeq = (res.data.letterSeq ? res.data.letterSeq : null);
                    fileValues.current.attachSeq = (res.data.attachSeq ? res.data.attachSeq : null);
                    fileValues.current.attachName = (res.data.attachName ? res.data.attachName : '-');
                    fileValues.current.attachOrgName = (res.data.attachOrgName ? res.data.attachOrgName : '-');
                    fileValues.current.attachPath = (res.data.attachPath ? res.data.attachPath : '-');
                    fileValues.current.attachSize = (res.data.attachSize ? res.data.attachSize : '-');

                    setResponse(res.data);
                } else {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });


        api.getLetterTemplate()
            .then(res => {
                console.log("res => ",res);
                if(res.rtCode === 0) {

                    res.data?.forEach( (item, index) => {
                        templates.current.list[item.templateSeq] = {
                                    key : item,
                                    value: item.templateSeq,
                                    text:item.title,
                                }

                        if( item.templateSeq === values.current.templateSeq ) {
                            console.log("!!!!");
                            values.current.templateSeq = item.templateSeq;
                            values.current.templateImage = item.templateEncoding;
                        }
                    });

                    setTemplate(res.data);

                    console.log("templates.current = > ",templates.current);

                    api.getLetterStamp()
                    .then(res => {
                        if(res.rtCode === 0) {
                            res.data?.forEach( (item, index) => {
                                stamps.current.list[item.stampSeq] = {
                                            key : item,
                                            value: item.stampSeq,
                                            text:item.title,
                                        }
        
                                if( item.stampSeq === values.current.stampSeq ) {
                                    values.current.stampSeq = item.stampSeq;
                                    values.current.stampImage = item.stampEncoding;
                                }
                            });
                            setStamp(res.data);
                            onStampChange(item.stampSeq);
                        }
                        console.log("stamps.current 2 = > ",stamps.current);
                    })
                    .catch(err => {
                        console.log(err);
                        //return err;
                    });
                }
            })
            .catch(err => {
                console.log(err);
                //return err;
            });

    }, []);




    const handlerDateTime = (type, data) => {
        if( type === 'Date') {
            values.current.reservationDate = moment(data).format('yyyy-MM-DD');
        } else if( type === 'Time') {
            values.current.reservationTime = moment(data).format('HH:mm:ss');
        }
    }

    const onTemplateChange = (data) => {
        valueForm.$('templateSeq').value = templates.current.list[data].key.templateSeq;
        const _content = valueForm.$('content').value;
        valueForm.$('content').value = _content + " ";
        valueForm.$('templateImage').value = templates.current.list[data].key.templateEncoding;
    }

    const onStampChange = (data) => {
        valueForm.$('stampSeq').value = stamps.current.list[data].key.stampSeq;
        const _content = valueForm.$('content').value;
        valueForm.$('content').value = _content + " ";
        valueForm.$('stampImage').value = stamps.current.list[data].key.stampEncoding;
    }

    return (
        <Grid container>
            <Grid item xl={12} xs={12}>

                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('recipientName')} variant={'outlined'}/>
                        <TextFields field={valueForm.$('recipientEmail')} variant={'outlined'}/>
                    </div>
                    <div>
                        <AddressText label={'주소'}
                                     variant={'outlined'}
                                     f_zipCode={valueForm.$('recipientZipCode')}
                                     f_address={valueForm.$('recipientAddress')}
                                     f_addressDetail={valueForm.$('recipientAddressDetail')}
                        />
                    </div>
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('recipientPhoneNumber')} variant={'outlined'}/>
                    </div>
                    <div>
                        <TextFields field={valueForm.$('recipientCellPhoneNumber')} variant={'outlined'}/>
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <DateTimeFields onChangeComplete={handlerDateTime} value={values.current.reservationStr} />
                    </div>
                    <div>
                        <div className={classes.rowContainer}>
                            <div className={classes.columnContainer}>
                                <div>
                                    <IconSelectFields field={valueForm.$('templateSeq')} contents={templates.current.list} onChange={onTemplateChange} />
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div>
                                    <StampSelectFields field={valueForm.$('stampSeq')} contents={stamps.current.list} onChange={onStampChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('title')} variant={'outlined'}/>
                    </div>
                </div>
                <div className={classes.editorContainer}>
                    <div>
                        {/*<InputLabel id={valueForm.$('content').name}>{valueForm.$('content').label}</InputLabel>*/}
                        <LetterEditor placeholder={'내용'}
                                    field={valueForm.$('content')}
                                    height={500}
                                    template={valueForm.$('templateImage')}
                                    stamp={valueForm.$('stampImage')}
                        />
                    </div>
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <FileFields field={fileForm.$('inputFile')}
                                    orgFileName={fileValues.current.attachOrgName}
                                    onDeleteFile={onDeleteConfirm}
                        />
                    </div>
                </div>

            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.buttonContainer}>
                    <RadiusButton color={'primary'} text={'수정하기'} onClick={valueForm.onSubmit} />
                </div>

            </Grid>

            <ConfirmDialog
                open={openConfirm}
                onOk={onConfirmOkBtn}
                onClose={onConfirmCloseBtn}
                title={'Confirm'}
                content={'수정하시겠습니까?'}/>


            <ConfirmDialog
                open={deleteConfirm}
                onOk={onDeleteFile}
                onClose={onDeleteConfirmCloseBtn}
                title={'Confirm'}
                content={'첨부파일을 삭제하시겠습니까?'}/>
        </Grid>
    );
}



















