import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import SideMenu from '../components/SideMenu';
import {Route} from 'react-router-dom';
import Page from '../components/Page';
import {Popover, Typography, useMediaQuery} from "@material-ui/core";
import {useSnackbar} from 'notistack';
import {useStores} from "../common/store";
import Slide from "@material-ui/core/Slide";
import {useTranslation} from "react-i18next";
import MainPage from "./MainPage";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import {BoardCategory, LetterType} from "../common/types";
import LetterCreatePage from "./letters/LetterCreatePage";
import LoginPage from "./LoginPage";
import MyPage from "./mypages/MyPage";
import SendLetterPage from "./mypages/SendLetterPage";
import LetterDetailPage from "./letters/LetterDetailPage";
import ReceiveLetterPage from "./mypages/ReceiveLetterPage";
import BoardListPage from "./board/BoardListPage";
import BoardDetailPage from "./board/BoardDetailPage";
import BoardWritePage from "./board/BoardWritePage";
import SignupPage from "./common/SignupPage";
import * as Validator from "validatorjs";
import IntroPage from "./common/IntroPage";
import LetterModifyPage from "./letters/LetterModifyPage";
import MyInfo from "./mypages/MyInfo";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import storage, {StorageKey as SK} from "../common/storage";
import GccsLoginPage from "./GccsLoginPage";
import {SButton} from "../components/Buttons";

function adminMenuFunc(user) {
    //const ADMIN_MENU_ITEMS = [
    return [
        {
            name: 'common',
            label: 'common',
            menuAuth: false,
            hidden:true,
            items: [
                {
                    name: 'main',
                    label: 'main',
                    url: ['/'],
                    router: '/',
                    page: MainPage,
                    parentName: 'common',
                    navs: ['Home'],
                    menuAuth: false,
                    hidden:true,
                },
                {
                    name: 'login',
                    label: '로그인',
                    url: '/login',
                    router: '/login',
                    page: LoginPage,
                    parentName: 'common',
                    navs: ['회원 서비스', '로그인'],
                    menuAuth: false,
                    hidden:true,
                },
                {
                    name: 'login',
                    label: 'Guardian-CCS 로그인',
                    url: '/gccs/login',
                    router: '/gccs/login',
                    page: GccsLoginPage,
                    parentName: 'common',
                    navs: ['회원 서비스', '로그인'],
                    menuAuth: false,
                    hidden:true,
                },
                {
                    name: '/signup',
                    label: '회원가입',
                    labelAlign:'center',
                    url: '/signup',
                    router: '/signup',
                    page: SignupPage,
                    parentName: 'common',
                    navs: ['회원 서비스', '회원 가입'],
                    menuAuth: false,
                    hidden:true,
                },
            ]
        },
        {
            name: 'self',
            label: '셀프편지',
            url: '/letter/me',
            menuAuth: true,
            items: [
                {
                    name: 'me',
                    label: '내가 나에게',
                    url: '/letter/me',
                    router: '/letter/me',
                    page: LetterCreatePage,
                    letterType: LetterType.Me,
                    parentName: 'main',
                    navs: ['셀프편지','내가 나에게'],
                    menuAuth: true,
                },
                {
                    name: 'will',
                    label: '유언장',
                    url: '/letter/will',
                    router: '/letter/will',
                    page: LetterCreatePage,
                    letterType: LetterType.Will,
                    parentName: 'main',
                    navs: ['셀프편지','유언장'],
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'lovePerson',
            label: '사랑하는 사람',
            menuAuth: true,
            items: [
                {
                    name: 'person',
                    label: '만나서 할 수 없는 말',
                    url: '/letter/person',
                    router: '/letter/person',
                    page: LetterCreatePage,
                    letterType: LetterType.Person,
                    parentName: 'main',
                    navs: ['사랑하는 사람','만나서 할 수 없는 말'],
                    menuAuth: true,
                },
                {
                    name: 'family',
                    label: '가족에게',
                    url: '/letter/family',
                    router: '/letter/family',
                    page: LetterCreatePage,
                    letterType: LetterType.Family,
                    parentName: 'main',
                    navs: ['사랑하는 사람','가족에게'],
                    menuAuth: true,
                },
                {
                    name: 'lover',
                    label: '애인에게',
                    url: '/letter/lover',
                    router: '/letter/lover',
                    page: LetterCreatePage,
                    letterType: LetterType.Lover,
                    parentName: 'main',
                    navs: ['사랑하는 사람','애인에게'],
                    menuAuth: true,
                },
                {
                    name: 'friend',
                    label: '친구에게',
                    url: '/letter/friend',
                    router: '/letter/friend',
                    page: LetterCreatePage,
                    letterType: LetterType.Friend,
                    parentName: 'main',
                    navs: ['사랑하는 사람','친구에게'],
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'special',
            label: '특별한 날',
            menuAuth: true,
            items: [
                {
                    name: 'patient',
                    label: '시한부 환자',
                    url: '/letter/patient',
                    router: '/letter/patient',
                    page: LetterCreatePage,
                    letterType: LetterType.Patient,
                    parentName: 'main',
                    navs: ['특별한 날','시한부 환자'],
                    menuAuth: true,
                },{
                    name: 'birthday',
                    label: '생일 및 기념일',
                    url: '/letter/birthday',
                    router: '/letter/birthday',
                    page: LetterCreatePage,
                    letterType: LetterType.Birthday,
                    parentName: 'main',
                    navs: ['특별한 날','생일 및 기념일'],
                    menuAuth: true,
                },{
                    name: 'everyYear',
                    label: '매년 편지',
                    url: '/letter/everyYear',
                    router: '/letter/everyYear',
                    page: LetterCreatePage,
                    letterType: LetterType.Letter,
                    parentName: 'main',
                    navs: ['특별한 날','매년 편지'],
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'intro',
            label: '홍보센터',
            menuAuth: true,
            items: [
                {
                    name: 'info',
                    label: '미래편지 소개',
                    url: '/intro',
                    router: '/intro',
                    page: IntroPage,
                    parentName: 'main',
                    navs: ['홍보센터','미래편지 소개'],
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'board',
            label: '고객센터',
            menuAuth: true,
            items: [
                {
                    name: 'notices',
                    label: '공지사항',
                    url: '/board/notices',
                    router: '/board/notices',
                    page: BoardListPage,
                    boardCategory: BoardCategory.Notice,
                    parentName: 'main',
                    navs: ['고객센터','공지사항'],
                    menuAuth: true,
                },
                {
                    name: 'noticeDetail',
                    label: '',
                    labelAlign:'center',
                    url: '/board/notices/detail',
                    router: '/board/notices/detail',
                    page: BoardDetailPage,
                    parentName: 'notices',
                    navs: ['고객센터','공지사항', '공지사항 상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'noticeWrite',
                    label: '공지사항 작성',
                    url: '/board/notices/write',
                    router: '/board/notices/write',
                    page: BoardWritePage,
                    parentName: 'notices',
                    navs: ['고객센터','공지사항', '공지사항 작성'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'faq',
                    label: '자주하는 질문',
                    url: '/board/faqs',
                    router: '/board/faqs',
                    page: BoardListPage,
                    boardCategory: BoardCategory.Faq,
                    parentName: 'main',
                    navs: ['고객센터','자주하는 질문'],
                    menuAuth: true,
                },
                {
                    name: 'faqDetail',
                    label: '',
                    labelAlign:'center',
                    url: '/board/faqs/detail',
                    router: '/board/faqs/detail',
                    page: BoardDetailPage,
                    parentName: 'faq',
                    navs: ['고객센터','자주하는 질문', '자주하는 질문상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'faqWrite',
                    label: '자주하는 질문 작성',
                    url: '/board/faqs/write',
                    router: '/board/faqs/write',
                    page: BoardWritePage,
                    parentName: 'faq',
                    navs: ['고객센터','자주하는 질문', '자주하는 작성'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'qna',
                    label: '문의사항',
                    url: '/board/qnas',
                    router: '/board/qnas',
                    page: BoardListPage,
                    boardCategory: BoardCategory.Qna,
                    parentName: 'main',
                    navs: ['고객센터','문의사항'],
                    menuAuth: true,
                },
                {
                    name: 'qnaDetail',
                    label: '',
                    labelAlign:'center',
                    url: '/board/qnas/detail',
                    router: '/board/qnas/detail',
                    page: BoardDetailPage,
                    parentName: 'qna',
                    navs: ['고객센터','문의사항', '문의사항 상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'qnaWrite',
                    label: '문의사항 작성',
                    url: '/board/qnas/write',
                    router: '/board/qnas/write',
                    page: BoardWritePage,
                    parentName: 'qnas',
                    navs: ['고객센터','문의사항', '문의사항 작성'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'news',
                    label: '뉴스',
                    url: '/board/news',
                    router: '/board/news',
                    page: BoardListPage,
                    boardCategory: BoardCategory.News,
                    parentName: 'main',
                    navs: ['고객센터','뉴스'],
                    menuAuth: true,
                },
                {
                    name: 'newsDetails',
                    label: '',
                    labelAlign:'center',
                    url: '/board/news/detail',
                    router: '/board/news/detail',
                    page: BoardDetailPage,
                    parentName: 'news',
                    navs: ['고객센터','뉴스', '뉴스 상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'newsWrite',
                    label: '뉴스 작성',
                    url: '/board/news/write',
                    router: '/board/news/write',
                    page: BoardWritePage,
                    parentName: 'news',
                    navs: ['고객센터','뉴스', '뉴스 작성'],
                    hidden: true,
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'mypage',
            label: 'mypage',
            menuAuth: false,
            hidden:true,
            items: [
                {
                    name: 'me',
                    label: '마이페이지',
                    url: '/mypage',
                    router: '/mypage',
                    page: MyPage,
                    parentName: 'main',
                    navs: ['마이페이지'],
                    hidden: true,
                    menuAuth: true,
                },{
                    name: 'me',
                    label: '회원정보',
                    url: '/mypage/me',
                    router: '/mypage/me',
                    page: MyInfo,
                    parentName: 'main',
                    navs: ['마이페이지','회원 정보'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'receive',
                    label: '받은 편지',
                    url: '/mypage/receive',
                    router: '/mypage/receive',
                    page: ReceiveLetterPage,
                    parentName: 'receive',
                    navs: ['마이페이지','받은 편지'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'receiveLetterDetail',
                    label: '편지 상세',
                    url: '/mypage/receive/detail',
                    router: '/mypage/receive/detail',
                    page: LetterDetailPage,
                    parentName: 'main',
                    navs: ['마이페이지','받은 편지','편지 상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'send',
                    label: '보낸 편지',
                    url: '/mypage/send',
                    router: '/mypage/send',
                    page: SendLetterPage,
                    parentName: 'main',
                    navs: ['마이페이지','보낸 편지'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'sendLetterDetail',
                    label: '편지 상세',
                    url: '/mypage/send/detail',
                    router: '/mypage/send/detail',
                    page: LetterDetailPage,
                    parentName: 'main',
                    navs: ['마이페이지','보낸 편지','편지 상세'],
                    hidden: true,
                    menuAuth: true,
                },
                {
                    name: 'sendLetterModify',
                    label: '편지 수정',
                    url: '/mypage/send/modify',
                    router: '/mypage/send/modify',
                    page: LetterModifyPage,
                    parentName: 'main',
                    navs: ['마이페이지','보낸 편지','편지 수정'],
                    hidden: true,
                    menuAuth: true,
                },
            ]
        },
    ];
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.paper,

    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    topBar: {
        flexGrow: 0,
        height: '5rem',
    },
    sideMenu: {
        position: 'fixed',
        width: 260,
        height: '100vh',
        backgroundColor: theme.palette.background.sideMenu,
        boxShadow:(theme.palette.type === 'dark' ? '' : '0px 10px 26px 0px rgba(19,82,146,0.2)'),
        [theme.breakpoints.between('xs', 'md')]: {
            position: 'absoulte',
            transform: 'translateX(-260px)',
            transition: theme.transitions.create(
                ['transform'],
                {duration: 500}
            )
        },
    },
    sideMenuIsWidth: {
        position: 'absolute',
        width: '100vw',
        height: '20vh',
        marginTop:'5rem',
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root': {
            backgroundColor: theme.palette.background.sideMenu,
            boxShadow:'0px 11px 15px -7px rgba(0,0,0,0.2)',
        },
        '& .MuiBackdrop-root':{
            backgroundColor:'transparent',
        }
    },
    headerMenu:{
        '& > .MuiPopover-paper':{
            left:0,
            backgroundColor:'hotpink',
            maxWidth:'100vw',
            width:'100%',
        }
    },
    mobileSideMenu: {
        height: '100vh',

    },

    main: {
        display: 'flex',
        flexGrow:1,
        //justifyContent: 'center'
    },
}));
const sideMenus = makeStyles((theme) => ({
    sideContainer: {
        width: '100vw'
    },
    sideHeader:{
        backgroundColor:'#D3275F',
        display:'flex',
        flex:1,
        flexDirection:'column',
        '& > div':{
            padding:'30px',
            display:'flex',
            flex:1,
            alignItems:'center',
            justifyContent:'space-between',
            [theme.breakpoints.down('xs')]: {
                padding:'20px',
            }
        },

        '& > :first-child':{
            borderBottom:'1px solid #B3174A',
        }
    },
    logoBox:{
        cursor:'pointer',
        '& > img':{
            width:'195px',
            [theme.breakpoints.down('xs')]: {
                width:'125px',
            }
        }
    },
    sideRowContainer:{
        display:'flex',
        flex:1,
        alignItems:'center',
        flexDirection:'row',
        '& > *':{
            marginRight:theme.spacing(1),
        }
    },
    sideColumnContainer:{
        display:'flex',
        flex:1,
        alignItems:'center',
        flexDirection:'column',
    },
    sideButton:{
        backgroundColor:'#D3275F',
        border:'1px solid #fff',
        boxShadow:'none',
        color:'#fff',
        padding:'12px 137px',
        fontWeight:600,
        '&:hover':{
            backgroundColor:'#fff',
            color:'#D3275F',
            fontWeight:600,
        },
        [theme.breakpoints.down('xs')]: {
            padding:'5px 50px',
            '& > span':{
                fontSize:11,

            }
        }
    },

    sideFlexContainer:{
        display:'flex',
        flex:1,
        '& > button':{ flex:1, paddingLeft:0,paddingRight:0 },
        '& > :nth-child(1)':{ marginRight:8, },
        '& > :nth-child(2)':{ marginLeft:8, },
    }
}));

function _SideMenu(props) {
    const classes = sideMenus();
    const history = useHistory();
    const {ds} = useStores();

    const {open, onClose, menuItems, openItem} = props;

    const onRouteUrl = (url) => {
        onClose();
        history.push(url);
    }

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <Box className={classes.sideContainer}>
                <Box className={classes.sideHeader}>
                    <Box>
                        <div className={classes.logoBox} onClick={ () => onRouteUrl("/")}>
                            <img src={require('../images/letter/side_logo.png').default}/>
                        </div>

                        <IconButton onClick={onClose} style={{padding:0}}>
                            <CloseIcon style={{color:'#fff'}} fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box>
                        {
                            ds.isLogin
                            ? (
                                <React.Fragment>
                                    <Box className={classes.sideFlexContainer}>
                                        <SButton text={'마이페이지'} type={'outlined'} clazz={classes.sideButton} onClick={() => onRouteUrl("/mypage")} />
                                        <SButton text={'로그아웃'} type={'outlined'} clazz={classes.sideButton} onClick={logoutHandler} />
                                    </Box>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <Box className={classes.sideRowContainer}>
                                        <AccountCircleOutlinedIcon style={{color:'#fff'}} fontSize="large" />
                                        <Typography variant={'subtitle1'} style={{color:'#fff'}} >로그인 하세요.</Typography>
                                    </Box>
                                    <SButton text={'로그인'} type={'outlined'} clazz={classes.sideButton} onClick={() => onRouteUrl("/login")} />
                                </React.Fragment>
                            )
                        }

                    </Box>
                </Box>
                <Box className={classes.sideBody}>
                    <SideMenu item={menuItems} openItem={openItem}/>
                </Box>
            </Box>
        </Drawer>
    )

}

export default function Layout(props) {
    const {ds} = useStores();
    const {t} = useTranslation();

    Validator.useLang('ko');

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [open, setOpen] = React.useState(false);

    const handleClick = (e, props) => {
        setOpen(props.open);
    };

    const [select, setSelect] = useState( null);

    const handlerSelect = (event, newValue) => {
        setSelect(newValue);
    }


    const menuItems = adminMenuFunc(ds.user);

    const openItem = {open: !open, isMobile: isMobile, handleClick, select:select, handlerSelect};

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        ds.alertFunc = (variant, message) => {
            enqueueSnackbar(message, {variant});
        }

        /** URL AUTH CHECK **/
        const connectionUrl = props.location.pathname;

        let urlCheck = true;   // url 확인

        menuItems.forEach((data, index) => {
            data.items.forEach((item, index) => {
                if (_.isArray(item.url)) {
                    if (_.includes(item.url, connectionUrl)) {
                        urlCheck = false;
                    }
                } else {
                    if (item.url === connectionUrl) {
                        urlCheck = false;
                    }
                }
            })
        });

        if (urlCheck) {
            ds.showErrorAlert(t('Error.WRONG_APPROACH'));
            props.history.push('/');
        }
        /** URL AUTH CHECK **/
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.root2}>
                <TopBar openItem={openItem} className={classes.topBar} />
                {
                    isMobile
                    ? <_SideMenu open={open}
                                 onClose={(event) => handleClick(event, {open: false})}
                                 menuItems={menuItems}
                                 openItem={openItem}
                        />
                    :

                        <Popover
                            open={open}
                            className={classes.headerMenu}
                            onClose={(event) => handleClick(event, {open: false})}
                            anchorReference="anchorPosition"
                            anchorPosition={{ top: 101, left: '-16px' }}
                        >
                            <SideMenu item={menuItems} openItem={openItem}/>
                        </Popover>
                }

                <main className={classes.main}>
                    {menuItems.map(item => (
                        item.items.map((innerItem, index) => (
                            <Route exact key={index.toString()} path={innerItem.url}
                                   render={props => (<Page {...props} {...innerItem} />)}/>
                        ))
                    ))}
                </main>
                <Footer />
            </div>
        </div>
    );
}

function Transition(props) {
    return <Slide direction="top" {...props} />;
}

