import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useObserver} from "mobx-react-lite";
import {Button} from "@material-ui/core";


const dialogStyles = makeStyles((theme) => ({
    dialogContainer:{
        zIndex:'1400 !important',
        '& > .MuiDialog-container > .MuiPaper-root':{
            flex:1,
        }
    },
    dialog_title: {
        marginBottom: theme.spacing(1),
        minWidth: 400,
        maxWidth: 720,
        "& > *": {
            ...theme.typography.h5
        }
    },
}));

export default function ConfirmDialog({title, content, open, onClose, onOk}) {
    const dialogStyle = dialogStyles();
    //const {t} = useTranslation();
    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='xs'
            className={dialogStyle.dialogContainer}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color="primary" autoFocus>
                    {'Yes'}
                </Button>
                <Button onClick={() => onClose(false)}>
                    {'No'}
                </Button>
            </DialogActions>
        </Dialog>
    ));
}
