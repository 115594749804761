import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {useHistory, useLocation} from 'react-router';
import {useTranslation} from "react-i18next";
import {useStores} from "../common/store";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: '100%',
        backgroundColor: theme.palette.background.sideMenu,
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.down('md')]: {
            justifyContent:'initial',
        }
    },
    menuContainer:{
        display: 'flex',
        marginTop:34,
        marginBottom:34,
        [theme.breakpoints.between('xs', 'md')]: {
            marginTop:0,
            flex:1,
            flexDirection:'column',
            '& > nav':{
                padding:0
            }
        }
    },
    subHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 23,
        height: '3rem',
        backgroundColor:'#F5F5F5',
        borderBottom:'1px solid #DCDCDC'
    },
    subIcon: {
        minWidth: 22,
        marginRight: 10,
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        height: '2.5rem',
        color: theme.palette.text.secondary,
        width:160,
        marginLeft:10,
        marginRight:10,
        [theme.breakpoints.down('md')]: {
            width:'initial',
            margin:0,
            borderBottom:'1px solid #DCDCDC',
            height: '3rem',
            paddingLeft: 23,
        }
    },
    dot: {
        display: 'none',
        width: 3,
        height: 3,
        borderRadius: 1.5,
        backgroundColor: theme.palette.text.disabled,
    },
    menuText: {
        //marginLeft: 9,
        textAlign:'center',
        "& > span": {
            fontFamily: 'Noto Sans CJK KR',
            fontSize:'1.143rem',
            lineHeight:'2.286rem',
        },
        [theme.breakpoints.down('md')]: {
            textAlign:'initial',
            fontSize:'1rem',
        }
    },
    selectedHeader: {
        color: theme.palette.text.primary
    },
    selectedMenu: {
        //backgroundColor: theme.palette.primary.red,
        color: theme.palette.primary.red,
        "& span": {
            fontWeight: 500,
        },
        '&.MuiListItem-button:hover':{
            color: (theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary),
        }
    },
    title: {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        fontSize:'1.143rem',
        fontWeight:500,
    }
}));

function HeaderItem({icon, label, selected}) {
    const classes = useStyles();

    return (
        <ListSubheader disableSticky component="div" className={`${classes.subHeader} ${selected ? classes.selectedHeader : null}`}>
            <div className={classes.title}>
                {label}
            </div>
        </ListSubheader>
    )
}

function GroupItem({group, openItem, menuSelect}) {
    const {ds} = useStores();
    const classes = useStyles();
    const {pathname} = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    let selectedIdx = -1;
    group.items.forEach((item, idx) => {
        if (_.isArray(item.url)) {
            if (_.includes(item.url, pathname)) {
                selectedIdx = idx;
            }
        }

        if (pathname === item.url) {
            if (item.hidden === true) {
                selectedIdx = item.menuIndex;
            } else {
                selectedIdx = idx;
            }
        }
    })

    const header = (group.menuAuth?<HeaderItem label={group.label} selected={selectedIdx !== -1}/>:null);
    return (

        group.menuAuth &&
        <React.Fragment>
            <List component="nav"  subheader={ isMobile && (header)} >
                {
                    group.items.map((item, idx) =>
                        !item.hidden
                            ? (item.menuAuth
                                ?<MenuItem key={idx.toString()}
                                           item={item}
                                           selected={idx === selectedIdx}
                                           openItem={openItem}
                                           menuSelect={menuSelect}/>
                                :null)
                            : null
                    )
                }
            </List>
        </React.Fragment>
    );

}


function MenuItem({item, selected, openItem, menuSelect}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {handleClick, handlerSelect} = openItem;

    const onClick = (e) => {
        if (handleClick) {
            handleClick(e, openItem);
        }


        if (Array.isArray(item.url)) {
            history.push(item.url[0]);
        } else {
            history.push(item.url);
        }
    };


    return (
        <ListItem button
                  disableGutters
                  className={`${classes.menuItem} ${selected ? classes.selectedMenu : null}`}
                  onClick={onClick}>
            <ListItemText className={classes.menuText}
                          primary={`${item.label}`}
                          onMouseOver={(event) => handlerSelect(event, menuSelect)} />
        </ListItem>
    );
}

export default function SideMenu({item, openItem}) {
    const classes = useStyles();

    // 2021-05-06 #59 [미래편지] 메뉴 닫힘 처리 수정 / by.jjm
    const {handleClick} = openItem;

    return (
        <div className={classes.root} onMouseLeave={(event) => handleClick(event, {open: false})}  >
            <div className={classes.menuContainer}>
            {
                item.map((group, idx) =>
                    <React.Fragment key={idx.toString()}>
                        <GroupItem group={group} openItem={openItem} menuSelect={(idx-1)}/>
                    </React.Fragment>
                )
            }
            </div>
        </div>
    )
}
