import React from 'react';
import Box from '@material-ui/core/Box';
import {useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export function PersonalInformation(props) {

    const html = "<p className=\"ql-align-center\"><strong style=\"color: rgb(0, 0, 255);\">(주)OOO社 </strong><strong>개인정보\n" +
        "                처리방침</strong></p><p>&nbsp; </p><p><span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> (주)OOO社(이하 ‘회사’라 한다)</span>는<span style=\"color: rgb(0, 0, 255);\"> </span>개인정보\n" +
        "            보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다. </p>\n" +
        "            <p>&nbsp; </p><p><strong>제1조(개인정보의 처리목적)</strong><span style=\"color: rgb(0, 0, 255);\"> 회사</span>는 다음의 목적을\n" +
        "            위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한\n" +
        "            조치를 이행할 예정입니다. </p><p>&nbsp; </p><p> 1. 홈페이지 회원 가입 및 관리 </p><p> 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격\n" +
        "            유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지․통지, 고충처리 등을 목적으로\n" +
        "            개인정보를 처리합니다. </p><p> 2. 재화 또는 서비스 제공 </p><p> 물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증,\n" +
        "            요금결제․정산, 채권추심 등을 목적으로 개인정보를 처리합니다. </p><p> 3. 고충처리 </p><p> 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과 통보 등의\n" +
        "            목적으로 개인정보를 처리합니다. </p><p>&nbsp; </p><p><strong> 제2조(개인정보의 처리 및 보유기간) </strong>①<strong> </strong><span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유․이용기간 내에서\n" +
        "            개인정보를 처리․보유합니다. </p><p>&nbsp; </p><p> ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. </p><p>&nbsp; </p><p> 1. 홈페이지 회원 가입 및\n" +
        "            관리 : 사업자/단체 홈페이지 탈퇴시까지 </p><p> 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 </p><p> 1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는\n" +
        "            해당 수사․조사 종료시까지 </p><p> 2) 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지 </p><p>&nbsp; </p><p> 2. 재화 또는 서비스 제공 :\n" +
        "            재화․서비스 공급완료 및 요금결제․정산 완료시까지</p><p> 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지 </p><p> 1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에\n" +
        "            따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록 </p><p> - 표시․광고에 관한 기록 : 6개월 </p><p> - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 :\n" +
        "            5년 </p><p> - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 </p><p> 2)「통신비밀보호법」제41조에 따른 통신사실확인자료 보관</p><p> - 가입자 전기통신일시,\n" +
        "            개시․종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 </p><p> - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p><p>&nbsp; </p><p>\n" +
        "            <strong> 제3조(개인정보의 제3자 제공)</strong><span style=\"color: rgb(0, 0, 255);\"> </span>①<span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> 회사</span>는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의\n" +
        "            특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. </p><p> ② <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p><p> - 개인정보를 제공받는 자 : (주) OOO\n" +
        "            카드</p><p> - 제공받는 자의 개인정보 이용목적 : 이벤트 공동개최 등 업무제휴 및 제휴 신용카드 발급 </p><p> - 제공하는 개인정보 항목 : 성명, 주소, 전화번호, 이메일주소,\n" +
        "            카드결제계좌정보, 신용도정보 </p><p> - 제공받는 자의 보유․이용기간 : 신용카드 발급계약에 따른 거래기간동안</p><p>&nbsp; </p><p><strong> 제4조(개인정보처리의\n" +
        "            위탁)</strong><span style=\"color: rgb(0, 0, 255);\"> </span>①<span style=\"color: rgb(0, 0, 255);\"> 회사</span>는\n" +
        "            원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. </p><p>&nbsp; </p><p> 1. 전화 상담센터 운영 </p><p> - 위탁받는 자 (수탁자) :\n" +
        "            OOO 컨택센터 </p><p> - 위탁하는 업무의 내용 : 전화상담 응대, 부서 및 직원 안내 등 </p><p>&nbsp; </p><p> 2. A/S 센터 운영 </p><p> - 위탁받는 자\n" +
        "            (수탁자) : OOO 전자 </p><p> - 위탁하는 업무의 내용 : 고객 대상 제품 A/S 제공 </p><p>&nbsp; </p><p> ② <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치,\n" +
        "            재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. </p><p> ③ 위탁업무의\n" +
        "            내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. </p><p>&nbsp; </p><p><strong> 제5조(정보주체의 권리․의무 및\n" +
        "            행사방법)</strong><span style=\"color: rgb(0, 0, 255);\"> </span>① 정보주체는<span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> 회사</span>에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. </p><p> 1. 개인정보\n" +
        "            열람요구</p><p> 2. 오류 등이 있을 경우 정정 요구</p><p> 3. 삭제요구 </p><p> 4. 처리정지 요구 </p><p> ② 제1항에 따른 권리 행사는 <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>는 이에 대해 지체없이 조치하겠습니다. </p><p> ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한\n" +
        "            경우에는 <span style=\"color: rgb(0, 0, 255);\">회사</span>는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. </p><p> ④\n" +
        "            제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야\n" +
        "            합니다. </p><p> ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 <span style=\"color: rgb(0, 0, 255);\">회사</span>가 처리하고 있는 정보주체 본인이나\n" +
        "            타인의 개인정보 및 사생활을 침해하여서는 아니됩니다. </p><p>&nbsp; </p><p><strong> 제6조(처리하는 개인정보 항목)</strong><span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> 회사</span>는 다음의 개인정보 항목을 처리하고 있습니다. </p><p> 1. 홈페이지 회원 가입 및 관리 </p><p> ․필수항목\n" +
        "            : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, 아이핀번호 </p><p> ․선택항목 : 결혼여부, 관심분야 </p><p>&nbsp; </p><p> 2. 재화 또는\n" +
        "            서비스 제공 </p><p> ․필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보 등 결제정보 </p><p> ․선택항목 :\n" +
        "            관심분야, 과거 구매내역 </p><p>&nbsp; </p><p> 3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. </p><p> ․IP주소, 쿠키,\n" +
        "            MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등 </p><p>&nbsp; </p><p><strong> 제7조(개인정보의 파기) </strong>①<span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> 회사</span>는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.\n" +
        "        </p><p> ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의\n" +
        "            데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. </p><p> ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. </p><p> 1. 파기절차 </p><p><span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>는 파기 사유가 발생한 개인정보를 선정하고, <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. </p><p> 2. 파기방법 </p><p><span\n" +
        "            style=\"color: rgb(0, 0, 255);\"> 회사</span>는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의\n" +
        "            방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. </p><p>&nbsp; </p><p><strong> 제8조(개인정보의 안전성\n" +
        "            확보조치) </strong><span style=\"color: rgb(0, 0, 255);\">회사</span>는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. </p>\n" +
        "            <p> 1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 </p><p> 2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의\n" +
        "            암호화, 보안프로그램 설치 </p><p> 3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 </p><p>&nbsp; </p><p><strong\n" +
        "            style=\"color: rgb(255, 0, 0);\"> </strong><strong>제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항) </strong>① 회사는 이용자에게\n" +
        "            개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p><p> ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의\n" +
        "            컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p><p> 가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및\n" +
        "            이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p><p> 나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷\n" +
        "            옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</p><p> 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>\n" +
        "            <p>&nbsp; </p><p><strong> 제10조(개인정보 보호책임자) </strong>① <span style=\"color: rgb(0, 0, 255);\">회사</span>는 개인정보\n" +
        "            처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. </p><p>&nbsp; </p>\n" +
        "            <p> ▶ 개인정보 보호책임자 </p><p> 성명 : OOO </p><p> 직책 : OOO 이사 </p><p> 연락처\n" +
        "            : &lt;전화번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt; </p><p> ※ 개인정보 보호 담당부서로 연결됩니다. </p><p></p><p> ▶ 개인정보 보호 담당부서 </p>\n" +
        "            <p> 부서명 : OOO 팀 </p><p> 담당자 : OOO </p><p> 연락처 : &lt;전화번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt; </p><p>&nbsp; </p>\n" +
        "            <p> ② 정보주체께서는 <span style=\"color: rgb(0, 0, 255);\">회사</span>의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리,\n" +
        "                피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <span style=\"color: rgb(0, 0, 255);\">회사</span>는 정보주체의 문의에\n" +
        "                대해 지체없이 답변 및 처리해드릴 것입니다. </p><p>&nbsp; </p><p><strong> 제11조(개인정보 열람청구) </strong>정보주체는 개인정보 보호법 제35조에 따른\n" +
        "            개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. <span style=\"color: rgb(0, 0, 255);\">회사</span>는 정보주체의 개인정보 열람청구가 신속하게 처리되도록\n" +
        "            노력하겠습니다. </p><p>&nbsp; </p><p> ▶ 개인정보 열람청구 접수․처리 부서 </p><p> 부서명 : OOO</p><p> 담당자 : OOO </p><p> 연락처\n" +
        "            : &lt;전화번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt; </p><p>&nbsp; </p><p><strong> 제12조(권익침해 구제방법) </strong>정보주체는 아래의\n" +
        "            기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. </p><p>&nbsp; </p><p> &lt;아래의 기관은 <span\n" +
        "            style=\"color: rgb(0, 0, 255);\">회사</span>와는 별개의 기관으로서, <span style=\"color: rgb(0, 0, 255);\">회사</span>의 자체적인\n" +
        "            개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다&gt;</p><p></p><p> ▶ 개인정보 침해신고센터 (한국인터넷진흥원\n" +
        "            운영) </p><p> - 소관업무 : 개인정보 침해사실 신고, 상담 신청 </p><p> - 홈페이지 : privacy.kisa.or.kr </p><p> - 전화 : (국번없이) 118 </p>\n" +
        "            <p> - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</p><p>&nbsp; </p><p> ▶ 개인정보 분쟁조정위원회</p><p> - 소관업무\n" +
        "            : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결) </p><p> - 홈페이지 : www.kopico.go.kr </p><p> - 전화 : (국번없이) 1833-6972</p><p> - 주소\n" +
        "            : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</p><p>&nbsp; </p><p> ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (<a\n" +
        "            href=\"http://www.spo.go.kr\" rel=\"noopener noreferrer\" target=\"_blank\"\n" +
        "            style=\"color: rgb(0, 0, 255);\"><u>www.spo.go.kr</u></a>)</p><p>&nbsp; </p><p> ▶ 경찰청 사이버안전국 : 182\n" +
        "            (http://cyberbureau.police.go.kr)</p><p>&nbsp; </p><p><strong> 제13조(영상정보처리기기\n" +
        "            설치․운영) </strong>① &lt;사업자/단체명&gt; 은(는) 아래와 같이 영상정보처리기기를 설치․운영하고 있습니다. </p><p>&nbsp; </p><p> 1. 영상정보처리기기\n" +
        "            설치근거․목적 : <span style=\"color: rgb(0, 0, 255);\">&lt;사업자/단체명&gt; </span>의 시설안전․화재예방</p><p> 2. 설치 대수, 설치 위치, 촬영\n" +
        "            범위 : 사옥 로비․전시실 등 주요시설물에 00대 설치, 촬영범위는 주요시설물의 전 공간을 촬영 </p><p> 3. 관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : OOO 팀 OOO\n" +
        "            과장 </p><p> 4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법 </p><p> - 촬영시간 : 24시간 촬영</p><p> - 보관기간 : 촬영시부터 30일 </p><p> - 보관장소\n" +
        "            및 처리방법 : OOO팀 영상정보처리기기 통제실에 보관․처리</p><p> 5. 영상정보 확인 방법 및 장소 : 관리책임자에 요구 (OOO팀) </p><p> 6. 정보주체의 영상정보 열람 등\n" +
        "            요구에 대한 조치 : 개인영상정보 열람․존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명․신체․재산 이익을 위해 필요한 경우에 한해 열람을\n" +
        "            허용함 </p><p> 7. 영상정보 보호를 위한 기술적․관리적․물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장․전송기술 적용, 처리기록 보관 및 위․변조\n" +
        "            방지조치, 보관시설 마련 및 잠금장치 설치 등</p><p>&nbsp; </p><p><strong> 제14조(개인정보 처리방침 변경) </strong>①<strong> </strong>이 개인정보\n" +
        "            처리방침은 20XX. X. X부터 적용됩니다.</p><p> ② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </p><p> - 20XX. X. X ~ 20XX. X. X 적용\n" +
        "            (클릭) </p><p> - 20XX. X. X ~ 20XX. X. X 적용 (클릭) </p>";
    return (
        <Typography noWrap
                    color='textPrimary'
                    dangerouslySetInnerHTML={{__html:html}}
        />
    );
}
