import React, {useRef} from "react";
import ReactQuill from "react-quill";
import {useObserver} from "mobx-react-lite";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, useMediaQuery} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import 'react-quill/dist/quill.snow.css';
import {Scrollbars} from "react-custom-scrollbars";
import {useStores} from "../common/store";
import * as Quill from "quill";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container:{
        position:'relative',
        overflow:'hidden',
    },
    editor: {
        '& .ql-toolbar.ql-snow': {
            borderColor: theme.palette.border.primary,
        },
        '& .ql-container.ql-snow': {
            borderColor: theme.palette.border.primary,
        },
        '& .ql-stroke': {
            stroke: theme.palette.text.gray,
        },
        '& .ql-fill': {
            fill: theme.palette.text.gray,
        },
        '& .ql-picker': {
            color: theme.palette.text.gray
        },
        '& .ql-editor.ql-blank::before': {
            color: theme.palette.text.hint
        },
    },
    editorError: {
        '& .ql-toolbar.ql-snow': {
            borderColor: '#f44336',
        },
        '& .ql-container.ql-snow': {
            borderColor: '#f44336',
        },
    },
    helpText: {
        color: '#f44336'    ,
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

}));

export default function TextEditor(props) {
    const {field,height, template} = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    
    const modules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{size: ["small", false, "large", "huge"]}, {color: []}],
                [
                    {list: "ordered"},
                    {list: "bullet"},
                    {indent: "-1"},
                    {indent: "+1"},
                    {align: []}
                ],
                ["clean"]
            ]
        },
        clipboard: {matchVisual: false}
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];

    let contentHeight = undefined;
    // if( height !== undefined ){
    //     contentHeight = {
    //         height:height
    //     }
    // }

    // 모바일일 경우 사이즈 조정
    if( isMobile ){
        contentHeight = {
            height:250
        }
    } else {
        contentHeight = {
            height:500
        }
    }

    let contentBackground = undefined;
    if( template?.value != undefined ){
        contentBackground = {
            position:'absolute',
            top:42,
            //width:'100%',
            minHeight:(height? height :500),
            background:`url(${template.value})`
        }
    }
/*

    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
*/


    /*
    Content 내용을 작성하고 지웠을떄
    화면은 빈 화면으로 보이지만
    <p><br></p> 태그가 존재하여
    필수 체크가 허용되어 제어가는 메소드 추가
    */
    const handleBlur = () => {
        if( field !== undefined) {
            if( field.value === "<p><br></p>") {
                field.value = "";
            }
        }
    }


    return useObserver(() =>
        <Box width='600px' height='100%' onBlur={handleBlur} className={classes.container}>
            { contentBackground &&
                <Box style={contentBackground}/>
            }
            <ReactQuill className={`${classes.editor} ` + (field.error ? ` ${classes.editorError}` : '') }
                        theme='snow'
                        modules={modules}
                        formats={formats}
                        placeholder={props.placeholder}
                        style={contentHeight}
                        {...field?.bind()}
            />

            {field ? <FormHelperText className={classes.helpText}>{" "}</FormHelperText> : null}
        </Box>
    );
}
