import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue} from "../common/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabels from "./InputLabels";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
        }
    },
    labelField:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    },
    dateContainer:{},
    /*dateUseContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },*/
    timeContainer:{
        display:'flex',
        alignItems:'center',
        marginBottom:23,
        '& > div':{
            flex:1,
        },
        '& > :nth-child(2)':{
            marginRight:theme.spacing(1),
            marginLeft:theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginRight:0,
            },
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection:'column',
            alignItems: 'initial',
            '& > :nth-child(2)': {
                marginRight: 0,
                marginLeft: 0,
                marginTop:8
            }
        }



    },
    dateField:{
        '& > .MuiInput-underline:before':{
            padding:'18.5px 14px',
            boxSizing:'content-box',
            border:'rgba(0, 0, 0, 0.23)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: '4px',

        },
        ' & > .MuiInput-underline:hover:not(.Mui-disabled):before':{

        }
    },
    timeField:{
        border:'rgba(0, 0, 0, 0.23)'
    },
}));

export default function DateTimeFields(props) {
    const classes = useStyles();
    const {className, value, onChangeComplete} = props;

    let _date = new Date();
    const [selectedDate, setSelectedDate] = React.useState(new Date(_date.getFullYear()+1, _date.getMonth(), _date.getDate()));
    const [selectedTime, setSelectedTime] = React.useState(new Date());

    const [reservationYn, setReservationYn] = useState(true);

    const onHandlerCheck = ({target}) => {
        setReservationYn(target.checked);
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        onChangeComplete('Date',date);
    };
    const handleTimeChange = (date) => {
        setSelectedTime(date);
        onChangeComplete('Time',date);
    };


    onChangeComplete('Date',selectedDate);
    onChangeComplete('Time',selectedTime);

    useEffect( () => {
        if( value ) {
            const _utcDate = new Date(moment.utc(value).format("yyyy-MM-DD HH:mm:ss"));
            setSelectedDate(_utcDate);
            setSelectedTime(_utcDate);
        }
    },[value])


    return useObserver( () => (
        <React.Fragment>
            <InputLabels className={classes.labelField} label={'예약일'}/>
            <Box className={classes.dateContainer}>
                <Box className={classes.timeContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant={'inline'}
                            inputVariant="outlined"
                            format="yyyy-MM-dd"
                            disabled={!reservationYn}
                            value={selectedDate}
                            onChange={handleDateChange}
                            // #61 [미래편지] 편지 기능 수정 :  날짜, 시간 선택 시 클릭 후 팝업 닫힘으로 변경
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            label={''}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            variant={'inline'}
                            inputVariant="outlined"
                            mask="__:__ _M"
                            value={selectedTime}
                            disabled={!reservationYn}
                            onChange={date => handleTimeChange(date)}
                            // #61 [미래편지] 편지 기능 수정 :  날짜, 시간 선택 시 클릭 후 팝업 닫힘으로 변경
                            autoOk={true}
                            label={''}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
        </React.Fragment>
    ));
}
