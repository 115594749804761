import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CSlider from "../components/CSlider";
import Clock from "../components/Clock";
import {Typography, useMediaQuery} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    topBar: {
        flexGrow: 0,
        height: '5rem',
    },
    main: {
        position: 'relative',
        flexGrow: 1,
        background: theme.palette.background.default
    },
    footer: {
        height: '5rem',
        alignItems: 'flex-end',
    },
    boardContainer: {
        padding: '10px 150px',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '10px 50px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10px 20px',
        }
    },
    boardBox: {

        background: theme.palette.background.paper,
    },
    boardItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > div': {
            width: 320,
            padding: '37px 0 90px 0',
            borderTop: '5px solid #cd235a',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                padding: '20px 0 50px 0',
            },
            '& > h6': {
                fontFamily: 'Noto Sans CJK KR',
                fontStyle: 'normal',
                fontWeight: 350,
                fontSize: '16px',
                lineHeight: '25px',
                letterSpacing: '-0.05rem',
                color: '#777777',
                wordBreak: 'keep-all',
                [theme.breakpoints.between('xs', 'md')]: {
                    fontSize: '20px',
                    lineHeight: '150%',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '15px',
                }
            }
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
            '& > div': {
                width: 'auto',
            }
        }
    },
    boardTitle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > h4': {
            fontSize: '1.786rem',
            fontFamily: 'Noto Sans CJK KR',
            [theme.breakpoints.between('xs', 'md')]: {
                fontSize: '2.200rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.600rem',
            }
        }
    },
    boardMoreBtn: {
        width: '2.800rem',
        height: '2.800rem',
        color: '#cd235a',
    },
    noticeContainer: {
        padding: '0 40px 40px 40px',
        background: theme.palette.background.paper,
    },
    noticeBox: {
        display: 'flex',
        borderTop: '1px solid #dedede',
        margin: '0 12px',
        '& > div:nth-child(1)': {paddingRight: theme.spacing(4),},
        '& > div:nth-child(3)': {paddingLeft: theme.spacing(4),},
    },
    noticeItems: {
        flex: 1,
        padding: '0 16px',
    },
    noticeTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    noticeDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        borderLeft: '1px solid #dedede',
    },
    noticeContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '& > h6:nth-child(1)': {
            flex: 2,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    noticeDate: {
        flex: 1,
        color: theme.palette.text.secondary,
        textAlign: 'right',
    }


}));


const backgroundStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems:'center'
    }
}));

const MainClockSlider = () => {
    const backgrounds = backgroundStyles();
    return (
        <div className={backgrounds.root}>
            <CSlider/>
            <Clock/>
        </div>
    )
}

const mainBoardsStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        padding: '65px 150px',
        background: theme.palette.background.paper,
        marginTop: -100,
        [theme.breakpoints.between('sm', 'md')]: {
            background: 'transparent',
            padding: '0 0 65px ',
            marginTop: -150,
        },
        [theme.breakpoints.down('xs')]: {
            background: 'transparent',
            padding: '0 0 30px ',
            marginTop: -75,
        }


    },
    boardContainer: {
        flex: 1,
    },
    sliderContainer: {
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '0 50px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        }

    },
    slider: {
        '& .slick-arrow': {
            background: '#fff',
        },
        '& .slick-prev:before, .slick-next:before': {
            background: '#000',
            fontSize: 60,
        },

        '& .slick-slide.slick-active > div:focus, & .slick-slide.slick-active > div > div:focus': {
            outline: 'none',
        },
        '& .slick-slide.slick-active:hover': {
            '& > div > div > div': {
                backgroundColor: '#cd235a',
                color: '#fff'
            }
        },
        '& > .slick-dots li': {
            display: 'none',
        }
    },
    sliderItem: {
        display: 'flex',
        flex: 1,
        width: 396,
        height: 220,
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#f7f7f7',
        backgroundPosition: 'right bottom',
        alignItems: 'flex-start',
        cursor: 'pointer',
        [theme.breakpoints.between('sm', 'md')]: {
            width: 'auto',
            height: '240px',
            padding: `${theme.spacing(2)}px 120px`,
            backgroundColor: '#cd235a',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            padding: `${theme.spacing(2)}px 40px`,
            backgroundColor: '#cd235a',
            backgroundSize: 'cover',
            height: 150
        }


    },
    sliderTitle: {
        fontFamily: 'Noto Sans CJK KR',
        '&>h4': {
            fontSize: '2rem',
            fontWeight: 700,
            [theme.breakpoints.between('xs', 'md')]: {
                color: '#fff',
                fontSize: '34px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.45rem',
            }

        },
        '&>h5': {
            fontSize: '2rem',
            [theme.breakpoints.between('xs', 'md')]: {
                color: '#fff',
                fontSize: '36px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.45rem',
            }
        },
    },
    sliderButton: {
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        border: '1px solid #dedede',
        fontSize: '17px',
        [theme.breakpoints.between('xs', 'md')]: {
            color: '#fff',
            fontSize: '12px',
            padding: '8px 25px',
        }
    },
    bannerItem01: {
        backgroundImage: `url(${require('../images/letter/banner_off_01.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_01.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_01.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_01.png')})`},
        }
    },
    bannerItem02: {
        backgroundImage: `url(${require('../images/letter/banner_off_02.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_02.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_02.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_02.png')})`},
        }
    },
    bannerItem03: {
        backgroundImage: `url(${require('../images/letter/banner_off_03.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_03.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_03.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_03.png')})`},
        }
    },
    bannerItem04: {
        backgroundImage: `url(${require('../images/letter/banner_off_04.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_04.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_04.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_04.png')})`},
        }
    },
    bannerItem05: {
        backgroundImage: `url(${require('../images/letter/banner_off_05.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_05.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_05.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_05.png')})`},
        }
    },
    bannerItem06: {
        backgroundImage: `url(${require('../images/letter/banner_off_06.png')})`,
        '&:hover': {backgroundImage: `url(${require('../images/letter/banner_on_06.png')})`},
        [theme.breakpoints.between('xs', 'md')]: {
            backgroundImage: `url(${require('../images/letter/banner_mob_06.png')})`,
            '&:hover': {backgroundImage: `url(${require('../images/letter/banner_mob_06.png')})`},
        }

    },

}));

const sliderButtonStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid #d4d4d4',
        borderRadius: '50%',
        width: 50,
        height: 50,
        position: "absolute",
        top: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            borderColor: '#555',
            '& >span': {
                borderColor: '#555',
            }
        },
        [theme.breakpoints.between('xs', 'md')]: {
            border: 'none',
            backgroundColor: '#fff'
        },
        [theme.breakpoints.down('xs')]: {
            width: 30,
            height: 30,
        }
    },
    leftCircle: {
        transform: 'translate(-190%,-50%)',
        left: 0,
        [theme.breakpoints.between('sm', 'md')]: {
            transform: 'translate(40%,-50%)',
            zIndex: 1
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(20%,-50%)',
            zIndex: 1
        }
    },
    rightCircle: {
        transform: 'translate(190%,-50%)',
        right: 0,
        [theme.breakpoints.between('sm', 'md')]: {
            transform: 'translate(-40%,-50%)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(-20%,-50%)',
        }

    },
    arrowContainer: {
        border: 'solid #d4d4d4',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '6px',
        [theme.breakpoints.between('xs', 'md')]: {
            borderColor: '#D72963',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3px',
        }

    },
    rightArrowContainer: {
        transform: 'rotate(-45deg)',
        margin: '0 6px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: '0 3px 0 0',
        }
    },
    leftArrowContainer: {
        transform: 'rotate(135deg)',
        margin: '0 0 0 6px',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 0 3px',
        }
    }
}));


const SliderLeftButton = (props) => {
    const sliderButtonStyle = sliderButtonStyles();
    const {className, style, onClick} = props;
    return (
        <span className={`${sliderButtonStyle.container} ${sliderButtonStyle.leftCircle}`} onClick={onClick}>
            <span className={`${sliderButtonStyle.arrowContainer} ${sliderButtonStyle.leftArrowContainer}`}>
            </span>
        </span>
    );
}

const SliderRightButton = (props) => {
    const sliderButtonStyle = sliderButtonStyles();
    const {className, style, onClick} = props;
    return (
        <span className={`${sliderButtonStyle.container} ${sliderButtonStyle.rightCircle}`} onClick={onClick}>
            <span className={`${sliderButtonStyle.arrowContainer} ${sliderButtonStyle.rightArrowContainer}`}>
            </span>
        </span>
    );
}

const MainSlider = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const mainBoards = mainBoardsStyles();

    const history = useHistory();

    const onRouteUrl = (url) => {
        history.push(url);
    }
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: (isMobile ? 1 : 3),
        slidesToScroll: 1,
        focusOnSelect: true,
        prevArrow: <SliderLeftButton/>,
        nextArrow: <SliderRightButton/>
    };

    return (
        <Grid container className={mainBoards.root}>
            <Grid item xl={12} xs={12} className={mainBoards.boardContainer}>
                <div className={mainBoards.sliderContainer}>
                    <Slider className={mainBoards.slider} {...settings}>
                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem01}`}
                                 onClick={() => onRouteUrl('/letter/family')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>사랑하는 가족에게</Typography>
                                    <Typography variant='h5'>사랑을 표현하세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem02}`}
                                 onClick={() => onRouteUrl('/letter/birthday')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>당신의 특별한 날</Typography>
                                    <Typography variant='h5'>마음을 선물하세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem03}`}
                                 onClick={() => onRouteUrl('/letter/will')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>아름다운 마침표</Typography>
                                    <Typography variant='h5'>유언장을 작성하세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem04}`}
                                 onClick={() => onRouteUrl('/letter/friend')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>사랑하는 친구에게</Typography>
                                    <Typography variant='h5'>우정을 표현하세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem05}`}
                                 onClick={() => onRouteUrl('/letter/me')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>내가 나에게</Typography>
                                    <Typography variant='h5'>편지를 적어보세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={`${mainBoards.sliderItem} ${mainBoards.bannerItem06}`}
                                 onClick={() => onRouteUrl('/letter/person')}>
                                <div className={mainBoards.sliderTitle}>
                                    <Typography variant='h4'>만나서 할 수 없는 말</Typography>
                                    <Typography variant='h5'>편지로 용기내세요.</Typography>
                                </div>
                                <div>
                                    <span className={mainBoards.sliderButton}>작성하러 가기</span>
                                </div>
                            </div>
                        </div>


                    </Slider>
                </div>
            </Grid>
        </Grid>
    )
}

export default function MainPage(props) {
    const classes = useStyles();
    const history = useHistory();

    const onRouteUrl = (url) => {
        history.push(url);
    }

    return (
        <React.Fragment>

            <MainClockSlider/>

            <MainSlider/>

            <Grid container className={classes.boardContainer}>
                <Grid item xl={12} xs={12} className={classes.boardBox}>
                    <div className={classes.boardItems}>
                        <div onClick={() => onRouteUrl('/intro')}>
                            <div className={classes.boardTitle}>
                                <Typography variant='h4'>미래편지 소개</Typography>
                                <AddIcon className={classes.boardMoreBtn}/>
                            </div>
                            <Typography variant='h6'>소중한 사람과 나에게 전하고 싶은 마음을 담아</Typography>
                            <Typography variant='h6'>미래의 어느 날로 편지,영상,음성으로 보내보세요.</Typography>
                        </div>

                        <div onClick={() => onRouteUrl('/board/faqs')}>
                            <div className={classes.boardTitle}>
                                <Typography variant='h4'>자주하는 질문</Typography>
                                <AddIcon className={classes.boardMoreBtn}/>
                            </div>
                            <Typography variant='h6'>미래편지 이용 중 궁금하신 사항이 생기시면</Typography>
                            <Typography variant='h6'>자주하는 질문에 먼저 검색해 보새요.</Typography>
                        </div>

                        <div onClick={() => onRouteUrl('/board/notices')}>
                            <div className={classes.boardTitle}>
                                <Typography variant='h4'>공지사항</Typography>
                                <AddIcon className={classes.boardMoreBtn}/>
                            </div>
                            <Typography variant='h6'>미래편지에 관한 소식들이 준비되어 있으니</Typography>
                            <Typography variant='h6'>공지사항에서 확인하세요.</Typography>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

