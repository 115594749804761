import React from 'react';
import '../css/clock.css';

export default function Clock(props) {
    return (
        <div className={"clock_box"}>
            <div className={"clock"}></div>
            <div className={"clock_center"}></div>
            <span className="vis_title">너에게 쓰는 미래 편지</span>
        </div>
    );
}
