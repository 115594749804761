import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import _ from "lodash";
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {useTheme} from "@material-ui/styles";
import {useMediaQuery} from "@material-ui/core";
import {useHistory, useLocation} from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 1500,
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: '100vw',
            margin:'0',
        }
    },
    subRoot: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 1200,
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: '100vw',
            margin:'0',
        }
    },
    navBox:{
        width: '100%',
        maxWidth: 1200,
        margin: '0 auto',
    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'flex-start',
        paddingBottom:theme.spacing(2),
        borderBottom:'2px solid #222',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'calc(100vw - 2.5rem)',
            margin:'0 auto',
        },
    },
    login:{
        alignItems:'center',
        borderBottom:'1px solid #dedede'
    },
    root3: {
        backgroundColor: (theme.palette.type === 'dark' ? '#212832' : '#ffffff'),
        borderRadius: 10,
        boxShadow:'none',
        paddingTop:30,
        paddingBottom:30,
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: 'calc(100vw - 2.5rem)',
            margin:'0 auto',
        },
    },
    homeIcon: {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.text.secondary
    },
    navArrow:{
        border: 'solid #d4d4d4',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(45deg)',

    },
    mainContainer:{
        flex:1,
    },
    navContainer:{
        marginBottom:65
    },
    breadc: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'md')]: {
            alignSelf: 'flex-start',
        },
    },
    navHome:{
        borderLeft:'1px solid #e0e0e0',
        borderRight:'1px solid #e0e0e0',
        padding:'19px 22px',
        width:60,
        height:60,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    navItem:{
        cursor:'pointer',
        alignItems:'center',
        paddingLeft:theme.spacing(3),
        paddingRight:theme.spacing(3),
        borderRight:'1px solid #e0e0e0',
        width:200,
        height:60,
        display:'flex',
        justifyContent:'space-between',
        '& > h6': {
            fontFamily: 'Noto Sans CJK KR',
            fontSize:'1rem',
            lineHeight:'1.786rem',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft:theme.spacing(1),
            paddingRight:theme.spacing(1),
            width:130,
            height:40,
            '& > h6': {
                fontFamily: 'Noto Sans CJK KR',
                fontSize:'1rem',
                lineHeight:'1.786rem',
            },
        }
    },

    //rgb(205, 35, 90)
    currentNavItem:{
        cursor:'pointer',
        alignItems:'center',
        paddingLeft:theme.spacing(3),
        paddingRight:theme.spacing(3),
        borderRight:'1px solid #e0e0e0',
        width:200,
        height:60,
        display:'flex',
        justifyContent:'space-between',
        '& > h6': {
            fontFamily: 'Noto Sans CJK KR',
            fontSize:'1rem',
            lineHeight:'1.786rem',
            color: '#D82B64'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft:theme.spacing(1),
            paddingRight:theme.spacing(1),
            width:130,
            height:40,
            '& > h6': {
                fontFamily: 'Noto Sans CJK KR',
                fontSize:'1rem',
                lineHeight:'1.786rem'
            },
        }
    },
    splitter: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    }
}));

export default function Page(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const {label, location, labelAlign} = props;
    const history = useHistory();


    const styled = labelAlign ? {textAlign:labelAlign}:undefined;

    const menuNames = (label) => {
        if (_.includes(label, ":")) {
            let title = "";
            const querySearch = queryString.parse(location.search);
            const queryState = queryString.parse(location.state);
            const param = label.replace(":", "");
            if (querySearch[param] !== undefined) {
                title = querySearch[param];
            } else if (queryState[param] !== undefined) {
                title = queryState[param];
            } else {
                title = label;
            }
            return title;
        } else {
            return label;
        }
    }

    //#60 [미래편지] Navigator 시각 효과 수정
    const onRouteUrl = ( props, idx ) => {
        // mypage일 경우, 메뉴 계층이 3계층으로 이루어져 있으므로, 각 기능마다 url 스플릿을 제공한다. 
        let sUrl = props.url || "/";

        console.log(props, idx);
        if ( props.url && props.url.indexOf('mypage') > -1 ){
            let urls = props.url.split('/');
            sUrl = "";
            for ( let i=1; i<urls.length; i++) {
                if (  i  <= ( idx + 1) ){
                    sUrl += "/" + urls[i];
                }
            }
        } else if ( props.url && props.url.indexOf('board') > -1 && props.url.split('/').length > 2 && idx == 1 ) {
            let urls = props.url.split('/');
            sUrl = "";
            for ( let i=1; i<urls.length; i++) {
                if (  i  <= ( idx + 1) ){
                    sUrl += "/" + urls[i];
                }
            }
        }
        console.log(sUrl);
        history.push(sUrl);
    };

    return (
        <React.Fragment>
            {
                label == 'main' ?
                    <div className={classes.root}>
                        <div className={`${label === 'main'? '' : classes.root2}`}>
                            <div >
                                {props.page(props)}
                            </div>
                        </div>
                    </div>
                    :
                    <div className={classes.mainContainer}>
                        <div className={classes.navContainer}>
                            <div className={classes.navBox}>
                                <div className={classes.breadc}>
                                    { !isMobile &&
                                        <div className={classes.navHome} onClick={ () => onRouteUrl("/")} >
                                            <HomeIcon className={classes.homeIcon}/>
                                        </div>
                                    }
                                    {
                                        // #60 [미래편지] Navigator 시각 효과 수정
                                        props.navs.map((nav, idx) => (
                                            <div key={idx.toString()} className={(props.navs.length-1 == idx )?classes.currentNavItem : classes.navItem} onClick={ () => onRouteUrl(props,idx)} >
                                                <Typography variant='subtitle1' color='textSecondary'>{menuNames(nav)}</Typography>
                                                {/*<Box> <span className={classes.navArrow}></span> </Box>*/}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <Divider/>
                        </div>
                        <div className={`${label === 'main'? classes.root : classes.subRoot}`}>
                            <div className={`${classes.root2} ${((label.indexOf('로그인') !== -1 ) || (label.indexOf('login') !== -1))? classes.login : ''}` } style={{...styled}}>
                                <Typography variant='h1' color='textPrimary'>{menuNames(label)}</Typography>
                            </div>

                            <div className={classes.root3}>
                                {props.page(props)}
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>

    );
}
