import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    label:{
        fontSize:'1.143rem',
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    }
}));

export default function InputLabels(props) {
    const classes = useStyles();
    const {id, label, clazz, errors} = props;
    return useObserver( () => (
        <React.Fragment>
            <InputLabel id={id} className={`${classes.label} ${clazz}`} {...errors}>{label}</InputLabel>
        </React.Fragment>
    ));

}
