import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextFields from "../../components/TextFields";
import AddressText from "../../components/AddressText";
import TextEditor from "../../components/TextEditor";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";

import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import FileFields from "../../components/FileFields";
import api from "../../common/api";
import moment from "moment";
import IconSelectFields from "../../components/IconSelectFields";
import StampSelectFields from "../../components/StampSelectFields";
import {useObserver} from "mobx-react-lite";
import DateTimeFields from "../../components/DateTimeFields";
import {Typography} from "@material-ui/core";
import LetterEditor from "../../components/LetterEditor";
import {RadiusButton} from "../../components/Buttons";
import {LetterType} from "../../common/types";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    columnContainer:{
        display:'flex',
        flex:1,
        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1,
        },
        '& > div:nth-child(1)':{
            marginRight:theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginRight:0
            },
        },
        '& > div:nth-child(2)':{
            marginLeft:theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginLeft:0
            },
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection:'column',
            alignItems: 'initial',
            '& > div:nth-child(2)': {
                marginRight: 0,
                marginLeft: 0,
                marginTop:8
            }
        }

    },
    editorContainer:{
        '& label':{
            paddingBottom:theme.spacing(1)
        }
    },
    buttonContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        margin:`${theme.spacing(4)}px 0`,
        [theme.breakpoints.between('xs', 'md')]: {
            '& > button':{
                flex:1,
            }
        },

    },
    labelField:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    },
    repeatContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    templateContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    highlight:{
        color: '#D82B64',
        ...theme.typography.h6
    },
    rowContainer:{
        display:'flex',
        flex:1,
        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1
        },
        '& > div:nth-child(1)':{
            [theme.breakpoints.down('md')]: {
                marginRight:0
            },
        },
        '& > div:nth-child(2)':{
            [theme.breakpoints.down('md')]: {
                marginLeft:0
            },
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
        },

    }

}));



export default function LetterCreatePage(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {letterType} = props;

    const isReadOnly = LetterType.Me === letterType;

    const history = useHistory();

    const [response, setResponse] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [template, setTemplate] = useState(null);
    const [stamp, setStamp] = useState(null);     // 2021-05-14 #65 [미래편지] 우표 선택 기능 개발

    const templateArray = [];
    const stampArray = [];

    const values = useRef({
        type:letterType,
        title:null,
        content:null,
        repeatYn:'N',
        name:null,
        email:null,
        zipCode:null,
        address:null,
        addressDetail:null,
        phoneNumber:null,
        cellPhoneNumber:null,
        reservationStr:null,
        reservationDate:null,
        reservationTime:null,
        templateSeq:null,
        templateImage:null,
        stampSeq:null,
        stampImage:null
    });


    const fileValues = useRef( {
        letterSeq: null,
        inputFile: null
    });

    const handlerContent = (event) => {
        console.log("handlerContent");
        console.log("event ==> ",event);
    }

    const valueForm = new MobxReactForm({
        fields: [
            {name: 'type',           value: values.current.type,           rules: 'required'},
            {name: 'title',          value: values.current.title,          rules: 'required', label: '제목'},
            {name: 'content',        value: values.current.content,        rules: 'required', label: '내용'},
            {name: 'repeatYn',       value: values.current.repeatYn,       label: '반복여부'},
            {name: 'name',           value: values.current.name,           rules: 'required', label: '이름'},
            {name: 'email',          value: values.current.email,          rules: 'required', label: '이메일'},
            {name: 'zipCode',        value: values.current.zipCode,        rules: 'required', label: '우편번호'},
            {name: 'address',        value: values.current.address,        rules: 'required', label: '주소'},
            {name: 'addressDetail',  value: values.current.addressDetail,  rules: 'required', label: '상세주소'},
            {name: 'phoneNumber',    value: values.current.phoneNumber,    label: '일반전화'},
            {name: 'cellPhoneNumber',value: values.current.cellPhoneNumber,rules: 'required', label: '휴대전화'},
            {name: 'reservationStr', value: values.current.reservationStr, label: '예약일'},
            {name: 'reservationDate',value: values.current.reservationDate,label: '예약일'},
            {name: 'reservationTime',value: values.current.reservationTime,label: '예약시간'},
            {name: 'templateSeq',    value: values.current.templateSeq,    label: '편지지 선택'},
            {name: 'templateImage',  value: values.current.templateImage,   label: '편지지',},
            {name: 'stampSeq',      value: values.current.stampSeq,        label: '우표 선택'},
            {name: 'stampImage',    value: values.current.stampImage,      label: '우표'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();

                values.current.reservationStr = values.current.reservationDate +' '+values.current.reservationTime ;
                fileValues.current.inputFile = fileForm.$('inputFile').value;

                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const handlerDateTime = (type, data) => {
        if( type === 'Date') {
            values.current.reservationDate = moment(data).format('yyyy-MM-DD');
        } else if( type === 'Time') {
            values.current.reservationTime = moment(data).format('HH:mm:ss');
        }
    }

    const fileForm = new MobxReactForm({
        fields: [
            {name: 'letterSeq', value: fileValues.current.letterSeq, label: '편지 SEQ'},
            {name: 'inputFile', value: fileValues.current.inputFile, label: '첨부파일'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            update(form){
                console.log( "update ");
            },
            onSuccess(form) {
                if( fileValues.current.inputFile !== null && fileValues.current.inputFile !== "") {
                    api.setAttach(fileValues.current)
                        .then(data => {
                            if (data.rtCode === 0) {
                                ds.showSuccessAlert('저장되었습니다.');
                                history.push('/mypage/send');
                            } else {
                                ds.showErrorAlert("저장에 실패하였습니다.");
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            ds.showErrorAlert("저장에 실패하였습니다.");
                            //return err;
                        });
                }
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const uploadFile = () => {
        fileForm.submit();
    }

    const onConfirmCloseBtn = () =>{
        setOpenConfirm(false);
    }

    const onConfirmOkBtn = () => {

        onConfirmCloseBtn();

        console.log("1 values.current ",values.current);
        if( (values.current.reservationDate === undefined || values.current.reservationDate === '') ||
            (values.current.reservationTime === undefined || values.current.reservationTime === '')
        ){
            values.current.reservationStr = null;
        } else if( (values.current.reservationDate !== undefined && values.current.reservationDate !== '') ||
                (values.current.reservationTime !== undefined && values.current.reservationTime !== '')
        ){
            values.current.reservationStr = values.current.reservationDate + " " + values.current.reservationTime;
            values.current.reservationStr = moment(values.current.reservationStr).format('yyyy-MM-DD HH:mm:ss')
        }

        if( values.current.templateSeq === '' ){
            values.current.templateSeq = null;
        }
        if( values.current.stampSeq === '' ){
            values.current.stampSeq = null;
        }

        console.log("values.current ",values.current);
        api.setLetter(values.current)
            .then(data => {
                if(data.rtCode === 0) {
                    fileValues.current.letterSeq = data.data.letterSeq;
                    if( fileValues.current.inputFile !== null && fileValues.current.inputFile !== "") {
                        uploadFile();
                    } else {
                        if (data.rtCode === 0) {
                            ds.showSuccessAlert('저장되었습니다.');
                            history.push('/mypage/send');
                        } else {
                            ds.showErrorAlert("저장에 실패하였습니다.");
                        }
                    }

                }
            })
            .catch(err => {
                console.log(err);
                //return err;
            });
    };

    useEffect( ()=>{

        if( LetterType.Me === letterType ) {
            api.getMe()
                .then(res => {
                    console.log("getMe( res =>" , res);
                    if( res.rtCode === 0 ){
                        const rData = res.data;
                        values.current.name = rData.name;
                        values.current.email = rData.email;
                        values.current.zipCode = rData.zipCode;
                        values.current.address = rData.address;
                        values.current.addressDetail = rData.addressDetail;
                        values.current.phoneNumber = rData.phoneNumber;
                        values.current.cellPhoneNumber = rData.cellPhoneNumber;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

        api.getLetterTemplate()
            .then(res => {
                console.log("res => ",res);
                if(res.rtCode === 0) {
                    setTemplate(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                //return err;
            });

        api.getLetterStamp()
            .then(res => {
                console.log("res => ",res);
                if(res.rtCode === 0) {
                    setStamp(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            });

    },[]);

    template?.forEach( (item, index) => {
        templateArray[item.templateSeq] = {
            key : item,
            value: item.templateSeq,
            text:item.title,
        }
    });

    const onTemplateChange = (data) => {
        if( templateArray.length > 0 ) {
            valueForm.$('templateSeq').value = data;
            const _content = valueForm.$('content').value;
            valueForm.$('content').value = _content + " ";
            valueForm.$('templateImage').value = templateArray[data].key.templateEncoding;
        }
    }

    if( template !== null){
        onTemplateChange( template[0].templateSeq );
    }

    stamp?.forEach( (item, index) => {
        stampArray[item.stampSeq] = {
            key : item,
            value: item.stampSeq,
            text:item.title,
        }
    });

    const onStampChange = (data) => {
        if( stampArray.length > 0 ) {
            valueForm.$('stampSeq').value = data;
            const _content = valueForm.$('content').value;
            valueForm.$('content').value = _content + " ";
            valueForm.$('stampImage').value = stampArray[data].key.stampEncoding;
        }
    }

    if( stamp !== null){
        onStampChange( stamp[0].stampSeq );
    }

    return useObserver(() => (
        <Grid container>
            <Grid item xl={12} xs={12}>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('name')} variant={'outlined'} InputProps={{readOnly: isReadOnly}} />
                        <TextFields field={valueForm.$('email')} variant={'outlined'} InputProps={{readOnly: isReadOnly}}/>
                    </div>
                    <div>
                        <AddressText label={'주소'}
                                     variant={'outlined'}
                                     f_zipCode={valueForm.$('zipCode')}
                                     f_address={valueForm.$('address')}
                                     f_addressDetail={valueForm.$('addressDetail')}
                                     isReadOnly={isReadOnly}
                        />
                    </div>
                </div>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('phoneNumber')} variant={'outlined'} InputProps={{readOnly: isReadOnly}} />
                    </div>
                    <div>
                        <TextFields field={valueForm.$('cellPhoneNumber')} variant={'outlined'} InputProps={{readOnly: isReadOnly}} />
                    </div>
                </div>

                <div className={classes.columnContainer}>
                    <div>
                        <DateTimeFields onChangeComplete={handlerDateTime} />
                    </div>
                    <div>
                        <div className={classes.rowContainer}>
                            <div className={classes.columnContainer}>
                                <div>
                                    <IconSelectFields field={valueForm.$('templateSeq')} contents={templateArray} onChange={onTemplateChange} />
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div>
                                    <StampSelectFields field={valueForm.$('stampSeq')} contents={stampArray} onChange={onStampChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('title')} variant={'outlined'}/>
                    </div>
                    <div className={classes.columnContainer}>
                        <div>
                            <FileFields field={fileForm.$('inputFile')}/>
                        </div>
                        <div> </div>
                    </div>
                </div>
                <div className={classes.editorContainer}>
                    <div>
                        {/*<InputLabel id={valueForm.$('content').name}>{valueForm.$('content').label}</InputLabel>*/}
                        <LetterEditor placeholder={'내용'}
                                    field={valueForm.$('content')}
                                    template={valueForm.$('templateImage')}
                                    stamp={valueForm.$('stampImage')}
                        />
                        <Typography variant={"h6"}>※ 예약서비스 신청시 <span className={classes.highlight}>전화번호(일반전화 및 휴대전화)는 필수로 입력</span>하셔야 합니다.</Typography>
                        <Typography variant={"h6"}>※ 전화번호가 변경되신 분은 <span className={classes.highlight}>마이페이지를 통해 수정</span>하실 수 있습니다.</Typography>
                    </div>
                </div>
            </Grid>



            <Grid item xl={12} xs={12}>
                <div className={classes.buttonContainer}>
                    <RadiusButton size={'large'} onClick={valueForm.onSubmit} text={'편지 발송하기'}/>
                </div>

            </Grid>

            <ConfirmDialog
                open={openConfirm}
                onOk={onConfirmOkBtn}
                onClose={onConfirmCloseBtn}
                title={'Confirm'}
                content={'저장하시겠습니까?'}/>


        </Grid>
    ));
}
