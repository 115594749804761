import React, {useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import {useStores} from '../common/store';
import TextFields from "../components/TextFields";
import IconTextFields from "../components/IconTextFields";
import SearchIcon from "@material-ui/icons/Search";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {useObserver} from "mobx-react-lite";

import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {Redirect} from 'react-router-dom';
import api from "../common/api";
import storage, {StorageKey as SK} from "../common/storage";
import {useHistory} from 'react-router';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {SButton} from "../components/Buttons";



const useStyles = makeStyles((theme) => ({
    loginContainer:{
        display:'flex',
        padding:'20px 0 80px 0',
        flexDirection:'column',
        flex:1,
        '& > *':{
            margin: `${theme.spacing(1)}px 0`
        }
    },
    loginTitleBox:{
        textAlign:'center',
        '& > :nth-child(1)':{
            marginBottom: theme.spacing(4)
        }
    },
    loginInfoBox:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        '& > *':{
            marginBottom: theme.spacing(1)
        }
    },
    loginButtonBox:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        textAlign:'center',
        '& > div':{
            display:'flex',
            flex:1,
            marginTop:theme.spacing(2),
            alignSelf:'center',
            '& > h6' :{
                padding:`0 ${theme.spacing(1)}px`,
                cursor: 'pointer'
            },
            '& > *':{ borderRight:'1px solid #dedede', },
            '& > :last-child':{ borderRight:'initial', }
        }
    },
}));


const dialogStyles = makeStyles((theme) => ({
    container:{
        //minWidth:500
    },
    titleContainer:{
        marginBottom:theme.spacing(2)
    },
    formContainer:{
        '& > div':{
            display:'flex',
            flexDirection:'column',
        }
    },
}));

function FindFormUserKey({open, onClose}) {
    const dialogStyle = dialogStyles();
    const {ds} = useStores();
    const [response, setResponse] = useState(null);

    const values = useRef({
        userKey:null,
        name:null,
        email:'',
        cellPhoneNumber:'',
    });


    const valueForm = new MobxReactForm({
        fields: [
            {name: 'email',          value: values.current.email,          rules: 'required|email', label: '이메일'},
            {name: 'cellPhoneNumber',value: values.current.cellPhoneNumber,rules: 'required', label: '휴대전화'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();

                findByUserKey();

            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const findByUserKey = () => {
        values.current.userKey = null;
        values.current.name = null;

        api.requestFindUserKey(values.current)
            .then(res => {
                if (res.rtCode === 0) {
                    setResponse(res.data);
                } else {
                    ds.showErrorAlert("입력한 정보의 계정이 존자하지 않습니다.");
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("조회에 실패하였습니다.");
                //return err;
            });
    }


    const onDialogClose = () => {
        values.current.email = '';
        values.current.cellPhoneNumber = '';
        setResponse(null);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'아이디 찾기'}</DialogTitle>
            <DialogContent>
                { response === null ?
                    <React.Fragment>
                        <DialogContentText id="alert-dialog-description" className={dialogStyle.titleContainer}>
                            아이디를 찾기 위해 가입하였던 정보를 입력해주시기 바랍니다.
                        </DialogContentText>
                        <Box className={dialogStyle.formContainer}>
                            <Box>
                                <TextFields field={valueForm.$('email')} variant={'outlined'}/>
                            </Box>
                            <Box>
                                <TextFields field={valueForm.$('cellPhoneNumber')} variant={'outlined'}/>
                            </Box>
                        </Box>
                    </React.Fragment>
                    :
                    <DialogContentText id="alert-dialog-description" className={dialogStyle.titleContainer}>
                        <Typography variant={"subtitle1"}>입력해주신 정보와 일치하는 계정입니다.</Typography>
                        <Typography variant={"subtitle2"}>아이디 : {response}</Typography>
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                {response === null ?
                    <React.Fragment>
                        <SButton onClick={valueForm.onSubmit} color="primary" text={'확인'} />
                        <SButton onClick={onDialogClose} text={'취소'} />
                    </React.Fragment>
                    :
                    <SButton onClick={onDialogClose} color="primary" text={'확인'}/>
                }
            </DialogActions>
        </Dialog>
    )
}



function ResetFormPW({open, onClose}) {
    const dialogStyle = dialogStyles();
    const {ds} = useStores();
    const [step, setStep] = useState(0);

    const values = useRef({
        userKey:'',
        name:'',
        email:'',
        cellPhoneNumber:'',
    });


    const valueForm = new MobxReactForm({
        fields: [
            {name: 'userKey',        value: values.current.userKey,        rules: 'required', label: '아이디'},
            {name: 'name',           value: values.current.name,           rules: 'required', label: '이름'},
            {name: 'email',          value: values.current.email,          rules: 'required|email', label: '이메일'},
            {name: 'cellPhoneNumber',value: values.current.cellPhoneNumber,rules: 'required', label: '휴대전화'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                findByUserKey();
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const passwordValues = useRef({
        userKey:'',
        name:'',
        email:'',
        cellPhoneNumber:'',
        password:'',
        passwordConfirm:'',
    });


    const passwordValueForm = new MobxReactForm({
        fields: [
            {name: 'password', value: passwordValues.current.password, rules: 'required|string|between:4,15', label: '패스워드', type:'password'},
            {name: 'passwordConfirm',   value: passwordValues.current.passwordConfirm, rules: 'required|string|same:password', label: '패스워드 확인', type:'password'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                passwordValues.current = form.values();
                passwordValues.current.userKey = values.current.userKey;
                passwordValues.current.name = values.current.name;
                passwordValues.current.email = values.current.email;
                passwordValues.current.cellPhoneNumber = values.current.cellPhoneNumber;

                initPw();
                ds.showSuccessAlert("변경된 비밀번호로 로그인 하여주시기 바랍니다.");

            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const findByUserKey = () => {
        api.requestFindUserKey(values.current)
            .then(res => {
                if (res.rtCode === 0) {
                    setStep(1);
                } else {
                    ds.showErrorAlert("입력한 정보의 계정이 존자하지 않습니다.");
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("조회에 실패하였습니다.");
                //return err;
            });
    }


    const initPw = () => {

        console.log("passwordValues.current => ",passwordValues.current);

        api.requestInitPw(passwordValues.current)
            .then(res => {
                if (res.rtCode === 0) {
                    setStep(1);
                    onDialogClose();
                } else {
                    ds.showErrorAlert("입력한 정보의 계정이 존자하지 않습니다.");
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("조회에 실패하였습니다.");
                //return err;
            });
    }


    const onDialogClose = () => {
        values.current.userKey = '';
        values.current.name = '';
        values.current.email = '';
        values.current.cellPhoneNumber = '';

        passwordValues.current.password = '';
        passwordValues.current.passwordConfirm = '';
        passwordValues.current.userKey  = '';
        passwordValues.current.name = '';
        passwordValues.current.email = '';
        passwordValues.current.cellPhoneNumber = '';


        setStep(0);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'비밀번호 초기화'}</DialogTitle>
            <DialogContent>
                { step === 0 &&
                    <React.Fragment>
                        <DialogContentText id="alert-dialog-description" className={dialogStyle.titleContainer}>
                            패스워드를 초기화 하기 위해 계정 정보를 입력해 주시기 바랍니다.
                        </DialogContentText>
                        <Box className={dialogStyle.formContainer}>
                            <Box>
                                <TextFields field={valueForm.$('userKey')} variant={'outlined'}/>
                            </Box>
                            <Box>
                                <TextFields field={valueForm.$('name')} variant={'outlined'}/>
                            </Box>
                            <Box>
                                <TextFields field={valueForm.$('email')} variant={'outlined'}/>
                            </Box>
                            <Box>
                                <TextFields field={valueForm.$('cellPhoneNumber')} variant={'outlined'}/>
                            </Box>
                        </Box>
                    </React.Fragment>
                }
                {step === 1 &&
                    <React.Fragment>
                        <DialogContentText id="alert-dialog-description" className={dialogStyle.titleContainer}>
                            변경할 패스워드를 입력하여 주시기 바랍니다.
                        </DialogContentText>
                        <Box className={dialogStyle.formContainer}>
                            <Box>
                                <TextFields field={passwordValueForm.$('password')} variant={'outlined'}/>
                            </Box>
                            <Box>
                                <TextFields field={passwordValueForm.$('passwordConfirm')} variant={'outlined'}/>
                            </Box>
                        </Box>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                {step === 0 &&
                    <React.Fragment>
                        <SButton onClick={valueForm.onSubmit} color="primary" text={'확인'} />
                        <SButton onClick={onDialogClose} text={'취소'} />
                    </React.Fragment>
                }
                {step === 1 &&
                    <React.Fragment>
                        <SButton onClick={passwordValueForm.onSubmit} color="primary" text={'저장'} />
                        <SButton onClick={onDialogClose} text={'취소'} />
                    </React.Fragment>
                }

            </DialogActions>
        </Dialog>
    )
}



function LoginForm(props) {
    const {ds} = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const history = useHistory();

    const [popOpen, setPopOpen] = useState({
        userKeyOpen:false,
        passwordOpen:false
    });

    const [userKey, setUserKey] = React.useState('');
    const [errMsg, setErrMsg] = React.useState(null);

    const loginForm = useRef({
        userKey: '',
        password: '',
    });


    const onChangePopOpen = (type) => {
        if( "userKey" === type ) {
            setPopOpen({...popOpen, userKeyOpen: true});
        } else if( "password" === type){
            setPopOpen({...popOpen, passwordOpen: true});
        }
    }


    const form = new MobxReactForm({
        fields: [
            {name: 'userKey', value: loginForm.current.userKey,  rules: 'required', label:'아이디', placeholder:'아이디' },
            {name: 'password', value: loginForm.current.password,rules: 'required', label:'비밀번호', placeholder:'비밀번호' },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                loginForm.current = form.values();

                api.requestSignIn(loginForm.current)
                    .then((data) => {
                        //ds.showSuccessAlert( data );
                        console.log("data => ",data);
                        if( data.rtCode === 0) {
                            api.setToken(data.data);
                            api.getMe()
                                .then(data => {
                                    ds.setLogin(true);
                                    storage.setSession(SK.LOGIN_USER_INFO, {data});
                                })
                                .catch(err => {
                                    setErrMsg(err.rtMsg);
                                });
                        }

                    })
                    .catch((err) => {
                        ds.showErrorAlert( err.rtMsg );
                        console.log("err => ",err);
                    });

            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const onKeyPress = (evt) => {
        if (evt.key === 'Enter') {
            form.submit();
            evt.preventDefault();
        }
    }

    const onRouteUrl = (url) => {
        history.push(url);
    }

    return (
        <Container className={classes.loginContainer} component="main" maxWidth="xs">
            <Box className={classes.loginTitleBox}>
                <Typography variant={'h1'}>환영합니다.</Typography>
                <Typography variant={'h6'}>고객님의 아이디와 비밀번호를 입력해 주세요.</Typography>
            </Box>

            <Box className={classes.loginInfoBox}>

                <IconTextFields icon={(<PermIdentityIcon />)}
                                field={form.$('userKey')}
                                onKeyPress={onKeyPress}
                />

                <IconTextFields icon={(<LockOutlinedIcon />)}
                                field={form.$('password')}
                                type={'password'}
                                onKeyPress={onKeyPress}
                />


            </Box>

            <Box className={classes.loginButtonBox}>
                <SButton text={'로그인'} color={'primaryButton'} size={'middle'} onClick={form.onSubmit}/>
                <SButton text={'회원가입'} type={'outlined'} color={'primaryButton'} size={'middle'} onClick={() => onRouteUrl('/signup')} style={{marginTop:8}}/>

                <div>
                    <Typography variant={'subtitle2'} onClick={()=>onChangePopOpen('userKey')}>아이디 찾기</Typography>
                    <Typography variant={'subtitle2'} onClick={()=>onChangePopOpen('password')}>비밀번호 초기화</Typography>
                    <Typography variant={'subtitle2'} onClick={() => onRouteUrl('/gccs/login')}>Guardian Login</Typography>
                </div>
            </Box>

            <FindFormUserKey open={popOpen.userKeyOpen}
                             onClose={()=>setPopOpen({...popOpen, userKeyOpen: false})} />

            <ResetFormPW open={popOpen.passwordOpen}
                             onClose={()=>setPopOpen({...popOpen, passwordOpen: false})} />

        </Container>

    );
}



export default function LoginPage(props) {
    const {ds} = useStores();
    console.log("ds.isLogin ==> ",ds.isLogin);

    return useObserver(() => {
        if (ds.isLogin) {
            const {location} = props;
            const {from} = location.state || {from: {pathname: '/'}};
            return <Redirect to={from}/>;
        } else {
            return <LoginForm {...props}/>;
        }
    });
}
