import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        flex:1,
        justifyContent:'center'
    },
    slider: {
        width:'90vw',
        overflow:'hidden',
        height:750,
        [theme.breakpoints.between('sm', 'md')]: {
            width:'calc(100vw - 40px)',
            height:720,
        },
        [theme.breakpoints.down('xs')]: {
            width:'100vw',
            height:420,
        }
    },
    item:{
        height:750,
        backgroundPosition:'50% 0',
        backgroundRepeat:'no-repeat',
        [theme.breakpoints.down('xs')]: {
            height:500
        }
    }
}));


export default function CSlider(props) {
    const classes = useStyles();
    const settings = {
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed:2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className={classes.root}>
            <Slider className={classes.slider} {...settings}>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis0.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis01.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis02.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis03.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis05.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis06.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis07.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis08.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis09.jpg').default})`}}> </div> </div>
                <div> <div className={classes.item} style={{backgroundImage:`url(${require('../images/letter/vis10.jpg').default})`}}> </div> </div>
            </Slider>
        </div>
    );
}
