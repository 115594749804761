import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../common/store";
import api from "../../common/api";
import {useTranslation} from "react-i18next";
import {AuthResult, BoardCategory, BoardType, CommonCodeValue, LetterType} from "../../common/types";
import _ from "lodash";
import queryString from 'query-string';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../../components/SearchBox";
import DataTable from "../../components/DataTable";
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Redirect} from "react-router-dom";

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SmsIcon from '@material-ui/icons/Sms';
import {useMediaQuery, useTheme} from "@material-ui/core";
import {SButton} from "../../components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.between('xs', 'md')]: {
            padding: '20px',
        }
    },
    searchContainer:{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
        alignItems:'center',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginRight:0,
            alignItems:'initial',
            '& > *':{
                marginBottom:theme.spacing(1),
            }

        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',

        [theme.breakpoints.between('sm', 'md')]: {

        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectDiv: {
        display: 'inline-flex',
        flex: 1,
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectBox: {
        minWidth: 200,
        '& .MuiOutlinedInput-input':{
            padding:'15px 14px',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0,
        }
    },
    searchBoxDiv: {
        display: 'flex',
    },
    form_right: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-end',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'initial',
            marginTop:theme.spacing(1),
            marginLeft:0,
            '& > button':{
                display:'flex',
                flex:1
            }
        },

    },
    search_btn: {
        minWidth: 120,
        paddingTop: 12,
        paddingBottom: 12,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1,
        }
    },
    searchText: {
        width: '60%',
    },
    button: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
    },
}));

export default function SendLetter(props) {
    const classes = useStyles();
    const {ds} = useStores();

    const pageSize = 5;

    const {boardCategory} = props;

    const [response, setResponse] = useState(null);
    const [boardType, setBoardType] = useState('ALL');
    const [searchKeyWord, setSearchKeyWord] = useState( null);
    const [clickEvent, setClickEvent] = useState(null);

    /* =============================== USE STATE EVENTS : S ================================== */
    const onChangeSelect = ({target}) => {
        setBoardType(target.value);
    }

    const changedSearchKeywordEvent = ({target}) => {
        setSearchKeyWord(target.value);
    }
    /* =============================== USE STATE EVENTS : E ================================== */

    const rowClickEvent = (row) => (event) => {
        console.log("row =>",row);

        if( boardCategory === BoardCategory.Qna ){
            if( row[5] !== undefined ) {
                setClickEvent({event: 'detail', boardSeq: row[5]?.content, title :row[1]?.content});
            }
        } else {

            if( row[4] !== undefined ) {
                setClickEvent({event: 'detail', boardSeq: row[4]?.content, title :row[1]?.content});
            }
        }
    }

    const writeClickEvent = () => {
        console.log("writeClickEvent => ",boardCategory);
        setClickEvent({event: 'write', boardCategory: boardCategory});
    }

    const search = (event, page = 1) => {
        const boardTypeStr = boardType !== 'ALL' ? boardType : null;
        api.getBoardList(boardCategory, boardTypeStr, searchKeyWord, page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        search(null, 1);
    }, [boardCategory, boardType, searchKeyWord]);

    if (clickEvent !== null) {
        const eventType = clickEvent.event;
        let url = '/';
         console.log("eventType =>>> ",eventType);
        if (eventType === 'detail') {
            if( boardCategory === BoardCategory.Notice ){
                url = '/board/notices/detail';
            } else if( boardCategory === BoardCategory.Faq ){
                url = '/board/faqs/detail';
            } else if( boardCategory === BoardCategory.Qna ){
                url = '/board/qnas/detail';
            } else if( boardCategory === BoardCategory.News ){
                url = '/board/news/detail';
            }
            console.log("clickEvent.title =-> ",clickEvent.title);
            return (
                <Redirect push to={
                    {
                        pathname: url,
                        search: "?" + api.encodeParams({boardSeq: clickEvent.boardSeq}),
                        state: api.encodeParams({title:clickEvent.title, boardCategory: boardCategory})
                    }
                }/>
            );
        } else if( eventType === 'write'){
            if( boardCategory === BoardCategory.Notice ){
                url = '/board/notices/write';
            } else if( boardCategory === BoardCategory.Faq ){
                url = '/board/faqs/write';
            } else if( boardCategory === BoardCategory.Qna ){
                url = '/board/qnas/write';
            } else if( boardCategory === BoardCategory.News ){
                url = '/board/news/write';
            }
            console.log("clickEvent.title =-> ",clickEvent.title);
            return (
                <Redirect push to={
                    {
                        pathname: url,
                        state: api.encodeParams({boardCategory: clickEvent.boardCategory})
                    }
                }/>
            );
        }
    } else {
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <ConditionPanel boardCategory={boardCategory}
                                    boardType={boardType}
                                    searchKeyWord={searchKeyWord}
                                    onChangeSelect={onChangeSelect}
                                    changedSearchKeywordEvent={changedSearchKeywordEvent}
                                    writeClickEvent={writeClickEvent}
                    />
                    <ResultTable boardCategory={boardCategory}
                                 response={response}
                                 search={search}
                                 currentPage={response !== null ? response.page.currentPage + 1 : 1}
                                 totalPage={response !== null ? response.page.totalPages : 0}
                                 pageSize={pageSize}
                                 rowClickEvent={rowClickEvent}
                    />
                </div>
            </React.Fragment>
        );
    }
}


const dataTableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
        borderTop: '1px solid #aaa'
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(2)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(3)': {
            minWidth: 150,
            width: '60%'
        },
        '& > :nth-child(4)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(5)': {
            minWidth: 60,
            width: '10%'
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: '#000',
        backgroundColor: '#efefef',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            '& > :nth-child(1)': {
                minWidth: 60,
                width: '10%'
            },
            '& > :nth-child(2)': {
                minWidth: 200,
                width: '50%'
            },
            '& > :nth-child(3)': {
                minWidth: 200,
                width: '20%'
            },
            '& > :nth-child(4)': {
                minWidth: 200,
                width: '20%'
            },
        },
        [theme.breakpoints.down('md')]: {
            height:'auto',
            display:'flex',
            flex:1,
            flexWrap:'wrap',
            borderBottom:'1px solid #dfdfdf',
            '& > td':{ border:'none', padding:'10px 0'},
            '& > td:nth-child(1)':{ display:'none' },
            '& > td:nth-child(2)':{ width:'100%' },
            '& > td:nth-child(3)':{ width:'auto', minWidth:'initial', marginRight:theme.spacing(1)},
            '& > td:nth-child(4)':{ flex:1, textAlign:'left' },
        }
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

const dataTableStyle2 = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
        borderTop: '1px solid #999'
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(2)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(3)': {
            minWidth: 150,
            width: '60%'
        },
        '& > :nth-child(4)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(5)': {
            minWidth: 60,
            width: '10%'
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: '#000000',
        backgroundColor: '#efefef',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            '& > :nth-child(1)': {
                minWidth: 60,
                width: '10%'
            },
            '& > :nth-child(2)': {
                minWidth: 150,
                width: '10%'
            },
            '& > :nth-child(3)': {
                minWidth: 200,
                width: '50%'
            },
            '& > :nth-child(4)': {
                minWidth: 200,
                width: '10%'
            },
            '& > :nth-child(5)': {
                minWidth: 200,
                width: '20%'
            },
        },
        [theme.breakpoints.down('md')]: {
            height:'auto',
            display:'flex',
            flex:1,
            flexWrap:'wrap',
            borderBottom:'1px solid #dfdfdf',
            margin:'10px 0',
            '& > td':{ border:'none', padding:'10px 0'},
            '& > td:nth-child(1)':{ width:'auto', marginRight:theme.spacing(1)},
            '& > td:nth-child(2)':{ flex:1, textAlign:'left' },
            '& > td:nth-child(3)':{ width:'100%' },
            '& > td:nth-child(4)':{ width:'auto', minWidth:'initial', marginRight:theme.spacing(1)},
            '& > td:nth-child(5)':{ flex:1, textAlign:'left' },
        }
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

function ResultTable({boardCategory, response, search, currentPage, totalPage, pageSize, rowClickEvent}) {
    let classes = dataTableStyle();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const {t} = useTranslation();
    const body = [];
    const head = [];
    /*const head = [
        [
            {content: '순번'},
            {content: '제목'},
            {content: '등록자'},
            {content: '등록일'},
        ]
    ];*/

    let heads = [];

    if( boardCategory === BoardCategory.Qna ){
        heads = [
            {content: '답변'},
            {content: '유형'},
            {content: '제목'},
            {content: '등록자'},
            {content: '등록일'},
        ]
        head.push(heads);
        classes = dataTableStyle2();
    } else {
        heads = [
            {content: '순번'},
            {content: '제목'},
            {content: '등록자'},
            {content: '등록일'},
        ]
        head.push(heads);
    }


    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        if (response.data !== undefined) {
            response.data.map((data, idx) => {

                let regDate = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD') : '-';
                let title = data.title ? data.title : '-';
                let answerYn = data.answerYn ? data.answerYn : '-';
                let regUserName = data.regUserName ? data.regUserName : '-';
                let boardSeq = data.boardSeq ? data.boardSeq : '-';
                let boardType = data.type ? t(CommonCodeValue.translateKey + '.' + data.type) : '-';

                if( boardCategory === BoardCategory.Qna ){
                    body[idx] = [
                        {id: 'answerYn', content: <_answerIcon value={answerYn}/>},
                        {id: 'boardType', content: boardType},
                        {id: 'title', content: title, align:'left' },
                        {id: 'regUserName', content: regUserName},
                        {id: 'date', content: regDate},
                        {id: 'boardSeq', content: boardSeq},
                    ];
                } else {
                    body[idx] = [
                        {id: 'index', content: num--},
                        {id: 'title', content: title, align:'left' },
                        {id: 'regUserName', content: regUserName},
                        {id: 'date', content: regDate},
                        {id: 'boardSeq', content: boardSeq},
                    ];
                }



            });
        }
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };


    return (
        <DataTable style={classes}
                   disableHead={isMobile}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   minHeight={300}
                   emptyText={'검색 결과가 없습니다.'}
        />
    );
}


function ConditionPanel({boardCategory, boardType, searchKeyWord, onChangeSelect, changedSearchKeywordEvent, writeClickEvent}) {

    const boardTypeArray = [];
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();

    if (boardTypeArray.length === 0) {
        Object.keys(BoardType).map((key, index) => {
            boardTypeArray[index] = {
                key: key,
                value: BoardType[key],
                text: t(CommonCodeValue.translateKey + '.' + BoardType[key])
            };
        })
    }

    return (
        <Box className={classes.searchContainer}>
            <Box className={classes.selectDiv}>
                {
                    boardCategory === BoardCategory.Qna &&
                        <SelectBox className={classes.selectBox}
                                   title={'종류'}
                                   value={boardType}
                                   contents={boardTypeArray}
                                   onChange={onChangeSelect}
                                   addAll={true}
                        />
                }
            </Box>

            <Box className={classes.conditionDiv}>
                <SearchBox value={searchKeyWord}
                           onChange={changedSearchKeywordEvent}
                           doQuery={searchKeyWord}
                           iconAlign={'right'}
                           placeholder={'검색어'}
                />

                { (ds.user?.isManager() || boardCategory === BoardCategory.Qna) &&
                    <div className={classes.form_right}>
                        <SButton color='primary' className={classes.search_btn} onClick={writeClickEvent} text={'글쓰기'} />
                    </div>
                }
            </Box>
        </Box>
    );
}

function SelectBox({className, title, contents, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={value}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}


const answerIconStyles = makeStyles((theme) => ({
    on : {
        padding:'11px 15px',
        borderRadius:60,
        background:'#D3275F',
        fontWeight:'bold',
        color:'#fff',
    },
    off: {
        padding:'10px 14px',
        borderRadius:60,
        background:'#ffffff',
        border:'1px solid #777777',
        fontWeight:'bold',
        color:'#777777',
    }
}));
function _answerIcon({value}) {
    const classes = answerIconStyles();

    let text = '';
    if( value === 'Y') {
        text = 'A';
    } else {
        text = 'Q';
    }


    return (
        <span className={ (value === 'Y' ? classes.on : classes.off)}>{text}</span>
    )
}
