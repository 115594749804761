import React, {useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import {useStores} from '../common/store';
import TextFields from "../components/TextFields";
import IconTextFields from "../components/IconTextFields";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {useObserver} from "mobx-react-lite";

import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {Redirect} from 'react-router-dom';
import api from "../common/api";
import storage, {StorageKey as SK} from "../common/storage";
import {useHistory} from 'react-router';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {AuthStatus} from "../common/types";
import {SButton} from "../components/Buttons";

import Guardian from "../common/guardian";
import {Alert} from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
    loginContainer:{
        display:'flex',
        padding:'20px 0 80px 0',
        flexDirection:'column',
        flex:1,
        '& > *':{
            margin: `${theme.spacing(1)}px 0`
        }
    },
    loginTitleBox:{
        textAlign:'center',
        '& > :nth-child(1)':{
            marginBottom: theme.spacing(4)
        }
    },
    loginInfoBox:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        '& > *':{
            marginBottom: theme.spacing(1)
        }
    },
    loginButtonBox:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        textAlign:'center',
        '& > div':{
            display:'flex',
            flex:1,
            marginTop:theme.spacing(2),
            //alignSelf:'center',
            alignItems:'center',
            '& > h6' :{
                padding:`0 ${theme.spacing(1)}px`,
                cursor: 'pointer'
            },
        }
    },
    authCancelContainer:{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        '& > div':{
            marginRight:theme.spacing(1),
        }
    },
    downloadContainer:{
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > div ': {
                marginBottom: 6,
                '& > *': {
                    height: 40,
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                },

            }
        }
    },
    downloadBox:{
        display: 'flex',
    },
    downLoad: {
        display: 'flex',
        alignItems: 'center',
        border: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255, 0.23)' : '1px solid rgba(0, 0, 0, 0.23)'),
        padding: '3px 9px',
        boxSizing: 'border-box',
        lineHeight: '1.75',
        borderRadius: '4px',
    },
}));

function LoginForm(props) {
    const {ds} = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const history = useHistory();

    const guardian = new Guardian('26b81f05234c493ab9a6650fd25a0f26');

    const [popOpen, setPopOpen] = useState({
        userKeyOpen:false,
        passwordOpen:false
    });

    const [userKey, setUserKey] = React.useState('');
    const [errMsg, setErrMsg] = React.useState(null);

    const loginForm = useRef({
        userKey: '',
        password: '',
    });

    const [guardianTimer, setGuardianTimer] = React.useState(null);
    const [stepMessage, setStepMessage] = React.useState(null);


    const onSuccess = (clientKey, userKey, channelKey) => {
        api.guardianAuth( {clientKey:clientKey, userKey:userKey, channelKey:channelKey} )
            .then(res => {
                if( res.rtCode === 0) {
                    api.setToken(res.data);
                    api.getMe()
                        .then(data => {
                            ds.setLogin(true);
                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                        })
                        .catch(err => {
                            setErrMsg(err.rtMsg);
                        });
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("조회에 실패하였습니다.");
            });


    }

    const onError = (rtMsg) => {
        console.log("rtMsg => ", rtMsg);
        setErrMsg(rtMsg);
    }

    const onCancel = () => {
        //console.log("onCancel => ", loginForm.current.userKey);
        guardian.onCancel(loginForm.current.userKey, onError);
    }

    const onTimer = (count) => {
        setGuardianTimer(count);
    }

    const onMessage = (message) => {
        console.log("onMessage => ",message);
        setStepMessage(message);
    }


    const form = new MobxReactForm({
        fields: [
            {name: 'userKey', value: loginForm.current.userKey,  rules: 'required', label:'아이디', placeholder:'아이디' },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                    loginForm.current = form.values();

                    guardian.setLanguage('en');
                    guardian.setAuthTimer( onTimer );
                    guardian.setAuthMessage( onMessage );

                    guardian.requestAuthCallback(loginForm.current.userKey, onSuccess, onError);

            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const onKeyPress = (evt) => {
        if (evt.key === 'Enter') {
            form.submit();
            evt.preventDefault();
        }
    }

    const onRouteUrl = (url) => {
        history.push(url);
    }

    return (
        <Container className={classes.loginContainer} component="main" maxWidth="xs">
            <Box className={classes.loginTitleBox}>
                <Typography variant={'h1'}>환영합니다.</Typography>
                <Typography variant={'h6'}>고객님의 아이디를 입력해 주세요.</Typography>
            </Box>

            <Box className={classes.loginInfoBox}>

                <IconTextFields icon={(<PermIdentityIcon />)}
                                field={form.$('userKey')}
                                onKeyPress={onKeyPress}
                />

            </Box>
            <Box>
                <span id={"timer"}></span>
            </Box>

            <Box className={classes.loginButtonBox}>
                <SButton text={'로그인'} color={'primary'} size={'middle'} onClick={form.onSubmit}/>
                <SButton text={'회원가입'} type={'outlined'} color={'primaryButton'} size={'middle'} onClick={() => onRouteUrl('/signup')} style={{marginTop:8}}/>
            </Box>
            { guardianTimer &&
                <React.Fragment>
                    <Box className={classes.authCancelContainer}>
                        <Box> 남은 시간 : {guardianTimer ? guardianTimer : '30'} </Box>
                        <SButton
                            text={'인증 취소'}
                            size='small'
                            variant="contained"
                            color="primary"
                            onClick={onCancel}
                            style={{paddingTop:'8px',paddingBottom:'8px'}}

                        />
                    </Box>
                </React.Fragment>
            }

            {stepMessage &&
                <Box>
                    <Alert severity="info">{stepMessage}</Alert>
                </Box>
            }
            {errMsg &&
                <Box className={classes.loginButtonBox}>
                    <Alert severity="error">{errMsg}</Alert>
                </Box>
            }

            <Box className={classes.loginButtonBox}>
                <Box className={classes.downloadContainer}>
                    <ButtonGroup size='small' className={classes.downloadBox}>
                        <a href={'https://labtest.fnsvalue.co.kr/app/GuardianCCSv3.apk'} target={'_blank'} className={classes.downLoad} download>
                            <img alt={'icon'} src={require('../images/letter/android.png').default}/>
                        </a>
                        <a href={'itms-services://?action=download-manifest&amp;url=https://labtest.fnsvalue.co.kr/app/guardian.plist'} target={'_blank'} className={classes.downLoad} download>
                            <img alt={'icon'} src={require('../images/letter/ios.png').default}/>
                        </a>
                    </ButtonGroup>
                </Box>
            </Box>
        </Container>

    );
}



export default function GccsLoginPage(props) {
    const {ds} = useStores();

    return useObserver(() => {
        if (ds.isLogin) {
            const {location} = props;
            const {from} = location.state || {from: {pathname: '/'}};
            return <Redirect to={from}/>;
        } else {
            return <LoginForm {...props}/>;
        }
    });
}
