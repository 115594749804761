import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";

import DaumPostcode from 'react-daum-postcode';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabels from "./InputLabels";
import {SButton} from "./Buttons";

const useStyles = makeStyles((theme) => ({
    addressContainer:{
        display: 'flex',
        flexDirection:'column',
        flex: 1,
    },
    addressBox:{
        //flex: 1,
        display: 'flex',
        '& > div':{
            flex:4
        },
        '& > button':{
            flex:1
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            '& > button' :{

            }
        }
    },
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,

        }
    },
    label:{
        fontSize:'1.143rem',
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    },
    disable:{
        '& .MuiInputBase-input': {
            background: '#eee'
        }
    }
}));


const dialogStyles = makeStyles((theme) => ({
    dialogContainer:{
        '& > .MuiDialog-container > .MuiPaper-root':{
            flex:1,
        }
    },
    dialog_title: {
        marginBottom: theme.spacing(1),
        minWidth: 400,
        maxWidth: 720,
        "& > *": {
            ...theme.typography.h5
        }
    },
}));

const AddressDialog = (props) => {
    const {open, onClose, onAddressComplete} = props;

    const dialogStyle = dialogStyles();

    const Postcode = () => {
        const handleComplete = (data) => {

            let fullAddress = data.address;
            let extraAddress = '';
            let postcode = data.zonecode;

            if (data.addressType === 'R') {
                if (data.bname !== '') {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                    extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                }

                fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
            }

            console.log("postcode =>  ", postcode);
            console.log("fullAddress =>  ", fullAddress);

            onAddressComplete(postcode, fullAddress);
        }

        return (
            <DaumPostcode
                onComplete={handleComplete}
                // height={'auto'}
                {...props}
            />
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`dialog`}
            maxWidth='lg'
            className={dialogStyle.dialogContainer}
            //scroll={'paper'}
        >
            <DialogTitle id={`dialog_id`} className={dialogStyle.dialog_title}>주소 검색</DialogTitle>
            <Postcode />
        </Dialog>
    )
}

export default function AddressText({label, f_zipCode, f_address, f_addressDetail, isReadOnly, props}) {
    const classes = useStyles();

    const address = useRef({
        zipCode:'',
        address:'',
        detail:''
    });

    const [open, setOpen] = useState(false);

    const onChangeOpen = (preOpen) => {
        console.log("onChangeOpen => ",preOpen);
        setOpen(preOpen);
    }

    const onChangeEvent = ({target}) => {
        address.current.detail = target.value;
    }

    const onAddressComplete = (zipCode, fullAddress) => {
        console.log("zipCode => ",zipCode);
        f_zipCode.value = zipCode;
        f_address.value = fullAddress

        onChangeOpen(false);
    }

    return useObserver( () => (
        <div className={classes.addressContainer}>
            <InputLabels id={name} className={classes.label} label={label} />
            <div className={classes.addressBox}>
                <TextField {...f_zipCode?.bind()}
                           label={''}
                           variant={'outlined'}
                           className={classes.disable}
                           InputProps={{readOnly: true}}
                />

                <SButton color={'primary'} onClick={() => onChangeOpen(true)} text={'주소검색'}/>
            </div>
            <TextField {...f_address?.bind()}
                       label={''}
                       variant={'outlined'}
                       className={classes.disable}
                       InputProps={{readOnly: true}}
            />
            <TextField {...f_addressDetail?.bind()}
                       label={''}
                       variant={'outlined'}
                       placeholder={'상세주소를 입력하세요.'}
                       InputProps={{readOnly: isReadOnly}}
                       {...(f_addressDetail?.error ? {error: true, helperText: f_addressDetail.error} : {helperText: " "})}
            />


            <AddressDialog
                open={open}
                onClose={() => onChangeOpen(false)}
                onAddressComplete={onAddressComplete}
                />
        </div>
    ));

}
