import axios from 'axios';
import SockJS from 'sockjs-client';
import i18next from 'i18next';
const FormData = require('form-data')
import storage, {StorageKey as SK} from './storage';
import store from './store';
import {ErrCode, User} from './types';
import React from "react";

class FutureLetterAPI {
    HOST = 'https://www.futuremail.co.kr';
    //HOST = 'http://192.168.0.51:18080';
    api = null;
    token = null;

    constructor() {
        this.token = storage.getSession(SK.ACCESS_TOKEN);

        this.api = axios.create({
            baseURL: this.HOST + '/api/v1/ftrltr',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;
        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            if (this.token != null && this.token.length > 0) {
                config.headers['Authorization'] = this.token;
            }
            const method = config.method.toUpperCase();
            console.log(method, '-', config.baseURL + config.url, '[' + this.token + ']', '[' + config.headers['Content-Type'] + ']', config.params);
            return config;
        });
        this.api.interceptors.response.use(response => {
            const rtime = new Date() - response.config.metadata.startTime;
            console.log('ResponseTime (', rtime, 'ms) :', response.data);
            const {rtCode} = response.data;
            if (rtCode !== ErrCode.RT_SUCCESS) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }
            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);
    };

    setToken = (token) => {
        this.token = token;
        storage.setSession(SK.ACCESS_TOKEN, token);
    };

    hasToken = () => {
        return this.token !== null;
    };

    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                    if (!nonNull || data[key] !== null) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                    }
                    return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getMe = () => {
        return new Promise((resolve, reject) => {
                this.api.get('/me')
                .then(res => {
                    store.setUser(new User(res.data.data));
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    duplicateCheck = (checkItem, checkValue) => {
        return new Promise( (resolve, reject) => {
            this.api.get(`/signup/check/${checkItem}?${this.encodeParams({checkValue: checkValue}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    requestFindUserKey = ( findData ) => {
        return new Promise( (resolve, reject) => {
            this.api.get(`/findId?${this.encodeParams({userKey: findData.userKey, name: findData.name, email: findData.email, cellPhoneNumber: findData.cellPhoneNumber}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    requestInitPw = ( initData ) => {
        return new Promise( (resolve, reject) => {
            this.api.put(`/initPw`, initData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    requestSignUp = (userData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/signup', userData  )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

     requestSignIn = (loginData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/signin', loginData  )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    putMe = (userData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/me', userData  )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteMe = () => {
        return new Promise((resolve, reject) => {
            this.api.delete('/me')
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    setLetter = (letterData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/letter', letterData  )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setAttach = (attach) => {
        const form = new FormData()
        form.append("letterSeq",attach.letterSeq);
        form.append("inputFile", attach.inputFile);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return new Promise((resolve, reject) => {
            this.api.post('/letter/attach', form , config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    putLetter = (letterData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/letter', letterData  )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    //2021-05-13. #62 [미래편지] 받은 편지 수정 - listType 파라메터 추가
    getReceiveLetters = ( searchType, searchKeyWord, listType, page = 0, size = 5 ) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/letters?${this.encodeParams({searchType: searchType, searchKeyWord: searchKeyWord, listType: listType, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getLetterDetail = ( letterSeq ) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/letter/${letterSeq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteLetter = (letterSeq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/letter/${letterSeq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    getLetterTemplate = () => {
        return new Promise((resolve, reject) => {
            this.api.get(`/letter/templates`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /**
     * 2021-05-14
     * #65 [미래편지] 우표 선택 기능 개발
     */
    getLetterStamp = () => {
        return new Promise((resolve, reject) => {
            this.api.get(`/letter/stamps`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }


    getLetterAttachDownLoad = ( letterSeq, attachSeq, attachName ) => {
        const params = this.encodeParams({attachSeq: attachSeq, attachName:attachName}, true);
        return new Promise((resolve, reject) => {
            axios({
                url: this.api.defaults.baseURL+`/letter/${letterSeq}/download?${params}`,
                method: 'GET',
                responseType: 'blob', // important
                headers:{
                    Authorization:this.token
                },
                withCredentials:true,
            }).then((response) => {

                const disposition = response.headers["content-disposition"];

                const res = {
                    rtCode:undefined,
                    contentType:undefined,
                    fileName:undefined,
                };

                const splitDisposition = disposition.split(";");
                splitDisposition.map((data) => {
                    let splits = null;
                    if(data.indexOf("rtCode") != -1) {
                        splits = data.split(":");
                        res.rtCode = splits[1];
                    } else if(data.indexOf("contentType") != -1) {
                        splits = data.split(":");
                        res.contentType = splits[1];
                    } else if(data.indexOf("fileName") != -1) {
                        splits = data.split(":");
                        res.fileName = splits[1];
                    }
                })

                if(res.rtCode === "0") {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: res.contentType}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        });
    }

    deleteAttach = (attachSeq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/letter/attach/${attachSeq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    getBoardList = (category, type, title, page, size) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/board?${this.encodeParams({category: category, type:type, title: title, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getBoardDetail = (boardSeq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/board/${boardSeq}`)
                .then(ret => {
                    resolve(ret.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    postBoard = (boardData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/board', boardData )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    putBoard = (boardData) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/board/${boardData.boardSeq}`, boardData )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteBoard = (boardSeq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/board/${boardSeq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    postBoardAnswer = (boardAnswerData) =>{
        return new Promise((resolve, reject) => {
            this.api.post(`/board/${boardAnswerData.boardSeq}/answer`, boardAnswerData )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    putBoardAnswer = (boardAnswerData) =>{
        return new Promise((resolve, reject) => {
            this.api.put(`/board/answer/${boardAnswerData.answerSeq}`, boardAnswerData )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteBoardAnswer = (boardAnswerData) =>{
        return new Promise((resolve, reject) => {
            this.api.delete(`/board/${boardAnswerData.boardSeq}/answer/${boardAnswerData.answerSeq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getTest = () => {
        return new Promise((resolve, reject) => {
            this.api.get(`/encoding`)
                .then(ret => {
                    resolve(ret.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    /* ============================================= GCCS 연동 API ============================================= */

    guardianAuth = (gccsInfo) => {
        return new Promise((resolve, reject) => {
            this.api.post('/gccs/auth', gccsInfo )
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });

    }


    getWebSocket = (userKey) => {
        const query = this.encodeParams({userKey});
        const ws = new SockJS(this.HOST + '/ws/v1/ftrltr/auth?' + query);
        return ws;
    };


};

export default new FutureLetterAPI();
