import {useTranslation , withTranslation} from 'react-i18next';

export class ErrCode {
    static RT_SUCCESS = 0;
}

export class UserType {
    static Manager = 'CMMPRM001';
    static User = 'CMMPRM002';

    static isManager = (userType) => {
        return (UserType.Manager === userType);
    };

    static isUser = (userType) => {
        return (UserType.User === userType);
    };
}

export class User {
    userSeq = null;
    userKey = null;
    userType = null;
    clientKey = null;
    clientName = null;
    status=null;
    phoneNum = null;
    name = null;
    email = null;
    empNo = null;
    regDt = null;

    constructor(userData) {
        Object.keys(userData)
            .filter(key => key in this)
            .forEach(key => {
                this[key] = userData[key];
            });
    }

    isManager = () => {
        return UserType.isManager(this.userType);
    };

    isUser = () => {
        return UserType.isUser(this.userType);
    };

}


export class AuthStatus {
    static RequestAuth = 'RequestAuth';
    static CreateChannel = 'CreateChannel';
    static SelectNodes = 'SelectNodes';
    static StartAuth = 'StartAuth';
    static StartVerificationOfNodes = 'StartVerificationOfNodes';
    static CompleteVerificationOfNodes = 'CompleteVerificationOfNodes';
    static RequestCancel = 'RequestCancel';
    static AuthCanceled = 'AuthCanceled';
    static AuthFailed = 'AuthFailed';
    static AuthCompleted = 'AuthCompleted';
    static ResponseToken = 'ResponseToken';


    static isFinish = (status) => {
        return (status === AuthStatus.AuthFailed) || (status === AuthStatus.AuthCanceled) || (status === AuthStatus.ResponseToken);
    };

    static isProgress = (status) => {
        return !AuthStatus.isFinish(status);
    };

    static isSuccess = (status) => {
        return (status === AuthStatus.ResponseToken);
    };

}

export class CommonCodeValue {
    static translateKey = 'CommonCode';

    static getText = (key) => {
        if(key === undefined || key === null || key==="") {
            return null;
        }

        const {t} = useTranslation();
        return t(this.translateKey + '.' + key);
    };
}

export class LetterType extends CommonCodeValue {
    static Me = 'CMMCTG001';
    static Will = 'CMMCTG002';
    static Person = 'CMMCTG003';
    static Family = 'CMMCTG004';
    static Lover = 'CMMCTG005';
    static Friend = 'CMMCTG006';
    static Patient = 'CMMCTG007';
    static Birthday = 'CMMCTG008';
    static Letter = 'CMMCTG009';
}

export class BoardCategory extends CommonCodeValue {
    static Notice = 'CMMBLT001';    // 공지사항
    static Faq = 'CMMBLT002';       // 자주 묻는 질문
    static Qna = 'CMMBLT003';       // 문의사항
    static News = 'CMMBLT004';       // 뉴스
}

export class BoardType extends CommonCodeValue {
    static Mail = 'CMMTYP001';          //메일
    static Additional = 'CMMTYP002';    //부가기능
    static Member = 'CMMTYP003';        //회원정보
    static Payment = 'CMMTYP004';       //요금 및 결제
    static Etc = 'CMMTYP005';           //기타


}

/**
 * 2021-05-13
 * #62 [미래편지] 받은 편지 수정
 */
export class ListType extends CommonCodeValue {
    static SEND = 'CMMLST001';          //보낸 편지
    static RECEIVE = 'CMMLST002';       //받은 편지
}