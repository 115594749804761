import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useStores} from '../common/store';
import {useObserver} from 'mobx-react-lite';

export default function AuthRoute({component: Component, ...rest}) {
    const {ds} = useStores();

    return useObserver(() =>
        <Route
            {...rest}
            render={(props) => {
                if (ds.isLogin) {
                    return <Component {...props}/>;
                } else {
                    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
                }
            }}
        />
    );
}
