import React, {useRef, useState} from "react";
import ReactQuill from "react-quill";
import {useObserver} from "mobx-react-lite";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, useMediaQuery} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import 'react-quill/dist/quill.snow.css';
import {Scrollbars} from "react-custom-scrollbars";
import {useStores} from "../common/store";
import * as Quill from "quill";
import {useTheme} from "@material-ui/core/styles";
import TextEditor from "../components/TextEditor";


const useStyles = makeStyles((theme) => ({
    container:{
        position:'relative',
        overflow:'hidden',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        minHeight:850,
        [theme.breakpoints.down('md')]: {
            // minHeight:400,
            minHeight:650,
        }

    },
    editorContainer:{
        position:'absolute',
        background:'#fff',
        padding:'20px 30px 60px 30px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        borderRadius:10,
        opacity: 0.7,   //#63 [미래편지] 편지 상세 수정
        [theme.breakpoints.between('xs', 'md')]: {
            padding:'0px 0px 60px 0px'
        }
    },
    editor: {
        width:600,
        height:500,
        [theme.breakpoints.between('xs', 'md')]: {
            width:'calc(100vw - 80px)',
            height:300,
        },
        '& .ql-toolbar.ql-snow': {
            borderColor: theme.palette.border.primary,
        },
        '& .ql-container.ql-snow': {
            borderColor: theme.palette.border.primary,
        },
        '& .ql-stroke': {
            stroke: theme.palette.text.gray,
        },
        '& .ql-fill': {
            fill: theme.palette.text.gray,
        },
        '& .ql-picker': {
            color: theme.palette.text.gray
        },
        '& .ql-editor.ql-blank::before': {
            color: theme.palette.text.hint
        },
    },
    editorError: {
        '& .ql-toolbar.ql-snow': {
            borderColor: '#f44336',
        },
        '& .ql-container.ql-snow': {
            borderColor: '#f44336',
        },
    },
    helpText: {
        color: '#f44336',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    stamp:{
        width:50, height:50, background:"hotpink", position:'absolute', top:50, right:50
    }

}));

export default function LetterEditor(props) {
    const {field,height, template, stamp} = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    let contentBackground = undefined;
    if( template?.value != undefined ){
        contentBackground = {
            position:'absolute',
            width:'100%',
            minHeight:(height? height : 500),
            backgroundImage:`url('${template.value}')`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            height:'100%'
        }
    }

    let postStamp = undefined;
    if( stamp?.value != undefined ){
        postStamp = {
            position:'absolute',
            width:215,
            height:130,
            backgroundImage:`url('${stamp.value}')`,
            backgroundRepeat:'no-repeat',
            top:20, 
            right:20,
            backgroundSize:'contain'
        }
    }

    let mobileBg = {

    }

    
    /*
    Content 내용을 작성하고 지웠을떄
    화면은 빈 화면으로 보이지만
    <p><br></p> 태그가 존재하여
    필수 체크가 허용되어 제어가는 메소드 추가
    */
    const handleBlur = () => {
        if( field !== undefined) {
            if( field.value === "<p><br></p>") {
                field.value = "";
            }
        }
    }

    const modules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{size: ["small", false, "large", "huge"]}, {color: []}],
                [
                    {list: "ordered"},
                    {list: "bullet"},
                    {indent: "-1"},
                    {indent: "+1"},
                    {align: []}
                ],
                ["clean"]
            ]
        },
        clipboard: {matchVisual: false}
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];


    return useObserver(() =>
        <Box width='100%' height='100%' onBlur={handleBlur} className={classes.container}>
            { contentBackground &&
                <Box style={contentBackground}>
                    {/*<img width={'100%'} height={'100%'} src={`${template.value}`}/>*/}
                </Box>
            }
            { postStamp &&
                <Box style={postStamp}>
                    {/*<img width={'100%'} height={'100%'} src={`${template.value}`}/>*/}
                </Box>
            }
            <Box className={classes.editorContainer}>
                <ReactQuill className={`${classes.editor} ` + (field.error ? ` ${classes.editorError}` : '') }
                            theme='snow'
                            modules={modules}
                            formats={formats}
                            placeholder={props.placeholder}
                            {...field?.bind()}
                />
                {/* #61 [미래편지] 편지 기능 수정
                    - 편지 Editor 추가(무료 멀티에디터 ckeditor 같은거...)
                */}
                {/* <TextEditor
                    placeholder={props.placeholder}
                    field={field}
                    height={400}
                /> */}

                {field ? <FormHelperText className={classes.helpText}>{field.error ? field.error : ""}</FormHelperText> : null}
            </Box>
        </Box>
    );
}
