import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../common/store";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import api from "../../common/api";
import {Typography} from "@material-ui/core";

import moment from "moment";
import _ from "lodash";
import PasswordDialog from "../../components/PasswordDialog";
import TextFields from "../../components/TextFields";
import DialogActions from "@material-ui/core/DialogActions";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {BoardType, CommonCodeValue, UserType} from "../../common/types";
import {useTranslation} from "react-i18next";
import AddressText from "../../components/AddressText";
import ConfirmDialog from "../../components/ConfirmDialog";
import storage, {StorageKey as SK} from "../../common/storage";
import {useHistory} from 'react-router';
import {SButton} from "../../components/Buttons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {RadiusButton, RectangleButton} from "../../components/Buttons";

const useStyles = makeStyles((theme) => ({
    container: {
        display:'flex',
        flex:1
    },

    itemContainer:{
        position:'relative',
        display:'flex',
        flex:1,
        flexDirection:'column',
    },
    fieldContainer:{
        display:'flex',
        flex:1,
        '& > div ':{
            display:'flex',
            flexDirection:'column',
            flex:1,
            marginLeft:theme.spacing(1),
            marginRight:theme.spacing(1),
            '& > label':{
                paddingTop:theme.spacing(1),
                paddingBottom:theme.spacing(1),
            },
            '& > span':{
                border:'1px solid #0000003b',
                borderRadius:theme.spacing(1),
                padding:'18.5px 14px',
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    verifyContainer:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        zIndex:1,
        display:'flex',
        justifyContent:'center',
        '& > div':{
            display:'flex',
            flexDirection:'column',
            //alignItems:'center',
            alignSelf:'center',
            justifyContent:'center',
            backgroundColor:'#fff',
            borderRadius:theme.spacing(1),
            padding:theme.spacing(4),
            '& > div:nth-child(1)':{
                display:'flex',
                flex:1,
                flexDirection:'column',
                width:400,
                [theme.breakpoints.down('md')]: {
                    width: 'auto',
                },
            },
            '& > div:nth-child(2)':{
                display:'flex',
                flexDirection:'row',
                flex:1,
                '& > button':{
                    flex:1,
                }
            },
        },
    },
    verifyBackground:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        backgroundColor:'#333',
        opacity:'0.8'
    },
    disableFields:{
        backgroundColor:'#efefef'
    },
    buttonContainer:{
        display:'flex',
        flex:1,
        flexDirection:'row',
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(1),
        justifyContent:'center',
        '& > button ':{
            marginLeft:theme.spacing(1),
            marginRight:theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& > *':{
                marginBottom:theme.spacing(1),
            }
        },
    },
    letterContainer:{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    boxContainer:{
        display:'flex',
        flexGrow:1,
        padding:'35px 0',
        backgroundColor:'#F7F7F7',
        borderRadius:theme.spacing(1),
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        cursor:'pointer',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'right bottom',
        flexDirection:'column',
        '&:hover':{
            backgroundColor:'#D72963',
            '& > div > *':{color:'#fff',},
            '& > div > span:nth-child(1)':{
                color:'#fff',
            },
            '& > div > span:nth-child(2)':{
                backgroundColor:'#fff',
                '& > span': {
                    borderColor: '#d3275f',
                }
            }
        }
    },
    receiveBox:{
        backgroundImage:`url(${require('../../images/letter/receive_off_01.png')})`,
        '&:hover':{backgroundImage:`url(${require('../../images/letter/receive_on_01.png')})`}
    },
    sendBox:{
        backgroundImage:`url(${require('../../images/letter/send_off_01.png')})`,
        '&:hover':{backgroundImage:`url(${require('../../images/letter/send_on_01.png')})`}
    },
    textContainer:{
        '& *':{
            fontFamily: 'Noto Sans CJK KR',
            color:'#555',
            marginBottom:5,
            marginLeft:30,
        },
        '& > h4':{
            fontSize:'1.786rem',
            lienHeight:'2.643rem',
            letterSpacing: '-0.04rem',
        },
        '& > h6':{
            fontSize:'1.071rem',
            lienHeight:'1.571rem',
            letterSpacing: '-0.04rem',
        },

    },
    letterButton:{
        display:'flex',
        alignItems: 'flex-end',
        flexDirection:'row',
        flex:1,
        marginLeft:30,
        '& > span:nth-child(1)':{
            fontSize:'1.071rem',
            lienHeight:'1.571rem',
            letterSpacing: '-0.04rem',
            marginRight:4
        },
    },
    goButton:{
        backgroundColor:'#d3275f',
        borderRadius:'50%',
        width:20,
        height:20,
        textAlign:'center',
        '& > span':{
            border: 'solid #fff',
            borderWidth: '0 2px 2px 0',
            display: 'inline-block',
            padding: '2px',
            transform: 'rotate(-45deg)',
            margin:'0 0 3px -1px',
        },
    }
}));

export default function MyInfo(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();
    const [response, setResponse] = useState();
    const [event, setEvent] = useState({modify:false, delete:false, modifyConfirm:false});
    const [verify, setVerify] = useState({open:true});

    const userType = useRef(null);
    const values = useRef({
        userKey:'',
        password:''
    });

    const userValues = useRef({
        userKey: '',
        name: '',
        email: '',
        phoneNumber: '',
        cellPhoneNumber: '',
        zipCode: '',
        address: '',
        addressDetail: '',
    });

    const userValueForm = new MobxReactForm({
        fields: [
            {name: 'userKey',           value: userValues.current.userKey,          label: 'userKey'},
            {name: 'name',              value: userValues.current.name,             label: '이름',    rules: 'required'},
            {name: 'email',             value: userValues.current.email,            label: '이메일',  rules: 'required'},
            {name: 'phoneNumber',       value: userValues.current.phoneNumber,      label: '일반전화',},
            {name: 'cellPhoneNumber',   value: userValues.current.cellPhoneNumber,  label: '휴대전화', rules: 'required'},
            {name: 'zipCode',           value: userValues.current.zipCode,          label: '우편번호',},
            {name: 'address',           value: userValues.current.address,          label: '주소',},
            {name: 'addressDetail',     value: userValues.current.addressDetail,    label: '상세주소',},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                userValues.current = form.values();
                userValues.current.userKey = ds.user.userKey;
                console.log("userValues.current ====>", userValues.current);
                onModifyConfirm();
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });


    const valueForm = new MobxReactForm({
        fields: [
            {name: 'userKey', value: values.current.userKey, label: 'userKey'},
            {name: 'password', value: values.current.password, label: '비밀번호', type:'password'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                values.current.userKey = ds.user.userKey;
                api.requestSignIn(values.current)
                    .then(data => {
                        if (data.rtCode === 0) {
                            // onComplete();
                            onVerifyClose();
                            onVerify()
                        } else {
                            ds.showErrorAlert("회원 정보를 조회에 실패 하였습니다.");
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        ds.showErrorAlert("회원 정보를 조회에 실패 하였습니다.");
                        //return err;
                    });
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });


    const onClose = () => {
        setEvent( {...event, delete:false});
    }

    const onComplete = () => {
        console.log("event => ",event);

        api.deleteMe()
            .then(data => {
                if (data.rtCode === 0) {
                    ds.showSuccessAlert('정상처리되었습니다.');
                    setTimeout( () => {
                        storage.removeSession(SK.ACCESS_TOKEN);
                        window.location.reload();
                    },1000);

                } else {
                    ds.showErrorAlert("계정 탈퇴에 실패하였습니다.");
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("계정 탈퇴에 실패하였습니다.");
            });

        onClose();
    }

    const onModify = () => {
        setEvent( {...event, modify: true});
    }
    const onDelete = () => {
        setEvent( {...event, delete: true});
    }

    const onVerifyClose = () => {
        setVerify( {...verify, open: false});
    }

    const onVerify = () => {
        api.getMe()
            .then(res => {
                if (res.rtCode === 0) {
                    Object.keys(UserType).map( (key,index) => {
                        if( res.data.userType === UserType[key] ){
                            userType.current = t(CommonCodeValue.translateKey + '.' + UserType[key]);
                        }
                    });

                    userValues.current = res.data;
                    setResponse(res.data);

                } else {
                    ds.showErrorAlert("조회에 실패하였습니다.");
                }
            })
            .catch(err => {
                console.log("err ",err);
                ds.showErrorAlert("조회에 실패하였습니다.");
            });
    }

    const onModifyConfirm = () => {
        setEvent({...event, modifyConfirm: true});
    }

    const onModifyConfirmCancel = () => {
        setEvent({...event, modifyConfirm: false});
    }

    const onModifyCancel = () => {
        setEvent({...event, modify: false});
    }

    const onModifyConfirmComplete = () => {

        console.log("userValues.current => ",userValues.current);

        api.putMe( userValues.current )
            .then(data => {
                if (data.rtCode === 0) {
                    //onModifyCancel();
                    setEvent({...event, modify: false, modifyConfirm: false});
                    onVerify();
                    ds.showSuccessAlert('수정에 성공하였습니다.');
                } else {
                    ds.showErrorAlert("수정에 실패하였습니다.");
                }
            })
            .catch(err => {
                console.log(err);
                ds.showErrorAlert("수정에 실패하였습니다.");
                //return err;
            });
    }


    return (
        <React.Fragment>
            <Grid container className={classes.container} spacing={3}>

                <Grid item xl={12} xs={12}>

                    <Box className={classes.itemContainer}>
                        <Box className={classes.fieldContainer}>
                            <Box>
                                <InputLabel>{'아이디'}</InputLabel>
                                <span className={classes.disableFields}>{response?.userKey}</span>
                            </Box>

                            <Box>
                                <InputLabel>{'권한'}</InputLabel>
                                <span className={classes.disableFields}>{userType.current}</span>
                            </Box>
                        </Box>

                        {
                            event.modify ?
                                <React.Fragment>
                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <TextFields field={userValueForm?.$('name')} variant={'outlined'}/>
                                        </Box>
                                        <Box>
                                            <TextFields field={userValueForm?.$('email')} variant={'outlined'}/>
                                        </Box>
                                    </Box>

                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <TextFields field={userValueForm?.$('phoneNumber')} variant={'outlined'}/>
                                        </Box>
                                        <Box>
                                            <TextFields field={userValueForm?.$('cellPhoneNumber')} variant={'outlined'}/>
                                        </Box>
                                    </Box>
                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <AddressText label={'주소'}
                                                         variant={'outlined'}
                                                         f_zipCode={userValueForm.$('zipCode')}
                                                         f_address={userValueForm.$('address')}
                                                         f_addressDetail={userValueForm.$('addressDetail')}

                                            />
                                        </Box>
                                    </Box>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <InputLabel>{'이름'}</InputLabel>
                                            <span className={classes.disableFields}>{response?.name}</span>
                                        </Box>
                                        <Box>
                                            <InputLabel>{'이메일'}</InputLabel>
                                            <span className={classes.disableFields}>{response?.email}</span>
                                        </Box>
                                    </Box>

                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <InputLabel>{'일반전화'}</InputLabel>
                                            <span className={classes.disableFields}>{response?.phoneNumber? response?.phoneNumber :'-'}</span>
                                        </Box>
                                        <Box>
                                            <InputLabel>{'휴대전화'}</InputLabel>
                                            <span className={classes.disableFields}>{response?.cellPhoneNumber}</span>
                                        </Box>
                                    </Box>
                                    <Box className={classes.fieldContainer}>
                                        <Box>
                                            <InputLabel>{'주소'}</InputLabel>
                                            <span className={classes.disableFields}>[{response?.zipCode}] {response?.address} {response?.addressDetail}</span>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                        }

                        <Box className={classes.fieldContainer}>
                            <Box>
                                <InputLabel>{'등록일'}</InputLabel>
                                <span className={classes.disableFields}>{_dataFormat(response?.regDt, 'date','YYYY-MM-DD HH:mm:SS')}</span>
                            </Box>
                            <Box>
                                <InputLabel>{'수정일'}</InputLabel>
                                <span className={classes.disableFields}>{_dataFormat(response?.uptDt, 'date','YYYY-MM-DD HH:mm:SS')}</span>
                            </Box>
                        </Box>

                        <Box className={classes.buttonContainer}>
                            <SButton color={'secondary'} text={'탈퇴'} onClick={onDelete} />
                            {
                                event.modify ?
                                    <React.Fragment>
                                        <SButton color={'primary'} text={'수정'} onClick={userValueForm?.onSubmit} />
                                        <SButton type={'outlined'} color={'secondary'} text={'취소'} onClick={onModifyCancel} />
                                    </React.Fragment>
                                :
                                    <SButton color={'primary'} text={'수정'} onClick={onModify} />
                            }

                        </Box>

                        {
                            verify.open &&
                            <React.Fragment>
                                <Box className={classes.verifyContainer} >
                                    <Box>
                                        <Box>
                                            <TextFields field={valueForm?.$('password')} variant={'outlined'}/>
                                        </Box>
                                        <Box>
                                            <SButton color={'primary'} text={'확인'} onClick={valueForm?.onSubmit} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.verifyBackground}></Box>
                            </React.Fragment>
                        }
                    </Box>
                </Grid>

                <PasswordDialog title={'회원 탈퇴'}
                                open={event.delete}
                                onComplete={onComplete}
                                onClose={onClose}
                />

                <ConfirmDialog
                    open={event.modifyConfirm}
                    onOk={onModifyConfirmComplete}
                    onClose={onModifyConfirmCancel}
                    title={'확인'}
                    content={'수정하시겠습니까?' }/>
            </Grid>

        </React.Fragment>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
