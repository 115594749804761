import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextFields from "../../components/TextFields";
import AddressText from "../../components/AddressText";
import TextEditor from "../../components/TextEditor";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";

import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import FileFields from "../../components/FileFields";
import api from "../../common/api";
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import queryString from 'query-string';
import {AuthResult, BoardCategory, BoardType, CommonCodeValue} from "../../common/types";
import SelectFields from "../../components/SelectFields";
import {useTranslation} from "react-i18next";
import {SButton} from "../../components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    columnContainer:{
        display:'flex',
        flex:1,

        '& > div':{
            display:'flex',
            flexDirection:'column',
            flex:1,
        },
        '& > div:nth-child(1)':{ marginRight:theme.spacing(1),},
        '& > div:nth-child(2)':{ marginLeft:theme.spacing(1), }
    },
    editorContainer:{
        '& label':{
            paddingBottom:theme.spacing(1)
        }
    },
    buttonContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        [theme.breakpoints.between('xs', 'md')]: {
            '& > button':{
                flex:1,
            }
        },

    },
    timeContainer:{
        display:'flex',
        flex:1,
        '& > *':{
            flex:1,
        },
        '& > div:nth-child(1)':{ marginRight:theme.spacing(1),},
        '& > div:nth-child(2)':{ marginLeft:theme.spacing(1), }
    }
}));

export default function BoardWritePage(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const {location} = props;
    const queryState = queryString.parse(location.state);
    const boardCategory = queryState.boardCategory;
    const boardSeq = queryState.boardSeq;
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [response, setResponse] = useState(null);


    const values = useRef({
        boardSeq: boardSeq,
        category: boardCategory,
        type: null,
        title: null,
        content: null,
        openYn: 'Y',
        boardPassword: null,
    });

    useEffect( () => {

        if( ds.isLogin === false ){
            history.push("/login");
            return ;
        }


        if( boardSeq !== undefined ){
            console.log("boardSeq !== undefined");

            api.getBoardDetail(boardSeq)
                .then(res => {
                    console.log(res);
                    values.current = res.data;
                    setResponse(res.data);
                })
                .catch(err => {
                    return err;
                });
        }
    }, [boardSeq]);

    const valueForm = new MobxReactForm({
        fields: [
            {name: 'boardSeq',      value: values.current.boardSeq},
            {name: 'category',      value: values.current.category,     rules: 'required'},
            {name: 'type',          value: values.current.type,         label: '유형', rules: (boardCategory===BoardCategory.Qna?'required':'')},
            {name: 'title',         value: values.current.title,        rules: 'required', label: '제목'},
            {name: 'content',       value: values.current.content,      rules: 'required', label: '내용'},
            {name: 'openYn',        value: values.current.openYn,       label: '공개여부'},
            {name: 'boardPassword', value: values.current.boardPassword,label: '비밀번호'},
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();

                if (!form.isDirty) {
                    ds.showWarningAlert('변경된 항목이 없습니다.');
                    return;
                }

                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const onConfirmCloseBtn = () =>{
        setOpenConfirm(false);
    }

    const onConfirmOkBtn = () => {
        onConfirmCloseBtn();
        console.log(" values.current ==> ",values.current);
        if( boardSeq === undefined ) {
            postBoard();
        } else {
            putBoard();
        }

    };

    const postBoard = () => {
        console.log("postBoard ");
        values.current.boardSeq = null;
        api.postBoard( values.current )
            .then(res => {
                console.log(res);
                if (res.rtCode === 0) {
                    ds.showSuccessAlert('저장되었습니다.');
                    history.goBack();
                } else {
                    ds.showErrorAlert("저장에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert("저장에 실패하였습니다.");
            });
    }

    const putBoard = () => {
        console.log("putBoard ");
        api.putBoard( values.current )
            .then(res => {
                console.log(res);
                if (res.rtCode === 0) {
                    ds.showSuccessAlert('수정되었습니다.');
                    history.goBack();
                } else {
                    ds.showErrorAlert("수정에 실패하였습니다.");
                }
            })
            .catch(err => {
                ds.showErrorAlert("수정에 실패하였습니다.");
            });
    }


    const boardTypeArray = [];
    if (boardTypeArray.length === 0) {
        Object.keys(BoardType).map( (key,index) => {
           boardTypeArray[index] = {key: key, value: BoardType[key], text: t(CommonCodeValue.translateKey + '.' + BoardType[key])};
        });
    }


    return (
        <Grid container>
            <Grid item xl={12} xs={12}>
                {boardCategory === BoardCategory.Qna ?
                    <div className={classes.columnContainer}>
                        <div>
                            <SelectFields field={valueForm.$('type')}
                                          contents={boardTypeArray}
                            />
                        </div>
                    </div>
                    : null
                }
            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.columnContainer}>
                    <div>
                        <TextFields field={valueForm.$('title')} variant={'outlined'}/>
                    </div>
                </div>
                <div className={classes.editorContainer}>
                    <div>
                        <InputLabel id={valueForm.$('content').name}>{valueForm.$('content').label}</InputLabel>
                        <TextEditor placeholder={'내용'}
                                    field={valueForm.$('content')}
                                    height={500}
                        />
                    </div>
                </div>

            </Grid>

            <Grid item xl={12} xs={12}>
                <div className={classes.buttonContainer}>
                    <SButton text={boardSeq === undefined?'작성':'수정'} color={'primary'} onClick={valueForm.onSubmit} size={'middle'}/>
                </div>

            </Grid>

            <ConfirmDialog
                open={openConfirm}
                onOk={onConfirmOkBtn}
                onClose={onConfirmCloseBtn}
                title={'Confirm'}
                content={boardSeq === undefined ? '저장하시겠습니까?' : '수정하시겠습니까?' }/>
        </Grid>
    );
}
