import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {useObserver} from "mobx-react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue} from "../common/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InputLabels from "./InputLabels";

const selectStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
        },
        '& > .MuiInputBase-root': {
            '& > .MuiSelect-root.MuiSelect-select':{
                display:'flex',
                alignItems:'center',
            }
        },
        [theme.breakpoints.down('md')]: {
            marginBottom:23,
        },
    },
    label:{
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1)
    }
}));

const dialogStyles = makeStyles((theme) => ({
    dialogContainer:{
        zIndex:'1400 !important',
        '& > .MuiDialog-container > .MuiPaper-root':{
            flex:1,
        }
    },
    dialog_title: {
        marginBottom: theme.spacing(1),
        minWidth: 400,
        maxWidth: 720,
        "& > *": {
            ...theme.typography.h5
        }
    },
    dialogContentContainer:{
        alignSelf:'center',
    }
}));

export default function stampSelectFields(props) {
    const selectStyle = selectStyles();
    const {contents, addAll, field, onChange} = props;

    const onHandlerSelect = (event) => {
        onChange(event.target.value);
    }

    const [labelId] = useState(() => _.uniqueId('label-'));

    return useObserver( () => (
        <React.Fragment>

            <InputLabels id={labelId} className={selectStyle.label} label={field?.label} errors={{...(field?.error ? {error: true} : null)}} />

            <FormControl variant="outlined" className={selectStyle.inputText}  {...(field?.error ? {error: true} : null)}>
                <Select labelId={labelId}
                        {...field?.bind()}
                        displayEmpty={true}
                        label={undefined}
                        onChange={onHandlerSelect}
                >
                    {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                    {
                        contents.map((content, idx) => (
                            <MenuItem key={idx} value={content.value}>
                                { (content?.key?.stampEncoding) &&
                                    <ListItemIcon style={{paddingRight:16}}>
                                        <img src={content?.key?.stampEncoding} height={40} />
                                    </ListItemIcon>
                                }
                                <ListItemText> {content.text}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Select>
                {field ? <FormHelperText>{field.error ? field.error : ""}</FormHelperText> : null}
            </FormControl>

        </React.Fragment>
    ));
}
