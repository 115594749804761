import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../common/store";
import api from "../../common/api";
import {useTranslation} from "react-i18next";
import {AuthResult, CommonCodeValue, LetterType} from "../../common/types";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../../components/SearchBox";
import DataTable from "../../components/DataTable";
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Redirect} from "react-router-dom";
import {ListType} from "../../common/types";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.between('xs', 'md')]: {
            padding: '20px',
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        marginBottom: theme.spacing(4),
        "& > :last-child": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    selectDiv: {
        display: 'inline-flex',
        flex: 1,
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectBox: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0,
        }
    },
    searchBoxDiv: {
        display: 'flex',
    },

    searchText: {
        width: '60%',
    },
    button: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
    },
}));

export default function ReceiveLetterPage(props) {
    const classes = useStyles();
    const {ds} = useStores();

    const pageSize = 5;

    const {location} = props;

    const [response, setResponse] = useState(null);
    const [searchType, setSearchType] = useState('ALL');
    const [searchKeyWord, setSearchKeyWord] = useState( null);
    const [clickEvent, setClickEvent] = useState(location.state ? location.state.clickEvent : null);

    /* =============================== USE STATE EVENTS : S ================================== */
    const onChangeSelect = ({target}) => {
        setSearchType(target.value);
    }

    const changedSearchKeywordEvent = ({target}) => {
        setSearchKeyWord(target.value);
    }
    /* =============================== USE STATE EVENTS : E ================================== */

    const rowClickEvent = (row) => (event) => {
    //console.log("row =>",row);
        if( row[4] !== undefined ) {
            setClickEvent({event: 'detail', letterSeq: row[4]?.content, clientName: row[1]?.content});
        }
    }

    const search = (event, page = 1) => {
        const searchTypeStr = searchType !== 'ALL' ? searchType : null;
        api.getReceiveLetters(searchTypeStr, searchKeyWord, ListType.RECEIVE, page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        search(null, 1);
    }, [searchType, searchKeyWord]);

    if (clickEvent !== null) {
        const eventType = clickEvent.event;
        if (eventType === 'detail') {
            return (
                <Redirect push to={
                    {
                        pathname: '/mypage/receive/detail',
                        search: "?" + api.encodeParams({letterSeq: clickEvent.letterSeq}),
                    }
                }/>
            );
        }
    } else {
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <ConditionPanel searchType={searchType}
                                    searchKeyWord={searchKeyWord}
                                    onChangeSelect={onChangeSelect}
                                    changedSearchKeywordEvent={changedSearchKeywordEvent}
                    />
                    <ResultTable response={response}
                                search={search}
                                currentPage={response !== null ? response.page.currentPage + 1 : 1}
                                totalPage={response !== null ? response.page.totalPages : 0}
                                pageSize={pageSize}
                                rowClickEvent={rowClickEvent}
                    />
                </div>
            </React.Fragment>
        );
    }
}


const dataTableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
        borderTop: '1px solid #999'
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(2)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(3)': {
            minWidth: 150,
            width: '60%'
        },
        '& > :nth-child(4)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(5)': {
            minWidth: 60,
            width: '10%'
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: 'white',
        backgroundColor: '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            '& > :nth-child(1)': {
                minWidth: 60,
                width: '10%'
            },
            '& > :nth-child(2)': {
                minWidth: 200,
                width: '20%'
            },
            '& > :nth-child(3)': {
                minWidth: 200,
                width: '*'
            },
            '& > :nth-child(4)': {
                minWidth: 60,
                width: '20%'
            },
        },
        [theme.breakpoints.down('md')]: {
            display:'flex',
            height:'auto',
            flex:1,
            flexDirection: 'column',
            borderBottom:'1px solid #DFDFDF',
            '& > td':{
                border:'none',
                padding:5,
                textAlign:'left',
                flex:1,
                width:'initial',
                minWidth: 'initial'
            },
            '& > :nth-child(1)':{ display:'none' },
            '& > :nth-child(2)':{ color:'#d3275f' },
            '& > :nth-child(4)':{ color:'#888' },
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

function ResultTable({response, search, currentPage, totalPage, pageSize, rowClickEvent}) {
    const classes = dataTableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: '순번'},
            {content: '종류'},
            {content: '제목'},
            //{content: '반복상태'},
            // {content: '발송상태'},
            {content: '발송시간'}
        ]
    ];


    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        if (response.data !== undefined) {
            response.data.map((data, idx) => {

                let regDate = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm:ss') : '-';
                let letterType = data.type ? t(CommonCodeValue.translateKey + '.' + data.type) : '-';
                let title = data.title ? data.title : '-';
                let repeatYn = data.repeatYn ? data.repeatYn : '-';
                let sendYn = data.sendYn ? data.sendYn : '-';
                let letterSeq = data.letterSeq ? data.letterSeq : '-';

                body[idx] = [
                    {id: 'index', content: num--},
                    {id: 'letterType', content: letterType},
                    {id: 'title', content: title, align:'left' },
                    // {id: 'repeatYn', content: repeatYn},
                    // {id: 'sendYn', content: sendYn},
                    {id: 'date', content: regDate},
                    {id: 'letterSeq', content: letterSeq},
                ];

            });
        }
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={classes}
                   disableHead={true}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   minHeight={300}
                   emptyText={'검색 결과가 없습니다.'}
        />
    );
}


function ConditionPanel({searchType, searchKeyWord, onChangeSelect, changedSearchKeywordEvent}) {

    const letterArray = [];
    const classes = useStyles();
    const {t} = useTranslation();


    if (letterArray.length === 0) {
        Object.keys(LetterType).map((key, index) => {
            letterArray[index] = {
                key: key,
                value: LetterType[key],
                text: t(CommonCodeValue.translateKey + '.' + LetterType[key])
            };
        })
    }

    return (
        <Box className={classes.conditionDiv}>
            <div className={classes.selectDiv}>
                <SelectBox className={classes.selectBox}
                           title={'종류'}
                           value={searchType}
                           contents={letterArray}
                           onChange={onChangeSelect}
                           addAll={true}
                />
            </div>
            <SearchBox value={searchKeyWord}
                       onChange={changedSearchKeywordEvent}
                       doQuery={searchKeyWord}
                       iconAlign={'right'}
                       placeholder={'검색어'}

            />
        </Box>
    );
}

function SelectBox({className, title, contents, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={value}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
